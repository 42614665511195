/*<? 
$title = 'En construction...';
include_once(PAGES_PATH . 'inc/head.tpl.php'); 
include_once(PAGES_PATH . 'inc/header.tpl.php'); 
include_once(PAGES_PATH . 'inc/menu.tpl.php'); 
include_once(PAGES_PATH . 'inc/submenu.tpl.php'); 
include_once(PAGES_PATH . 'inc/featured.gallery.tpl.php'); 
?>*/

import React from "react"
import { Link } from "react-router-dom";
import Head from "../fr/inc/Head.includes.tpl";
import Header from "../fr/inc/Header.tpl";
import Menu from "../fr/inc/Menu.tpl";
import Submenu from "../fr/inc/Submenu.tpl";
 import Footer from "../fr/inc/Footer.tpl";
import FeaturedGallery from "../fr/inc/Featured.gallery.tpl";

function EnConstruction (){

    return(

      <div className="grid place-content-center w-screen">
      {/* <Head/> */}
      <Header/>
      <Menu/>
      <FeaturedGallery/>

<div id="content">
    <div id="breadcrumbs">
      {/*  <?= printInitBreadcrums() ?> */} <strong>{/*<?=$title?>*/}</strong>
    </div>
    <h1>Tecxio MD</h1>
    <p>
    Nous sommes un cabinet de conseil en soins de santé axé sur la génération et la communication de la valeur des technologies de la santé (dispositifs médicaux, diagnostics, médicaments, vaccins compris). Notre vaste expérience d'interaction avec les principales parties prenantes,
combiné à un fort esprit commercial et à une solide formation scientifique dans les affaires médicales, l'ETS, l'accès au marché, l'économie de la santé et la recherche sur les résultats, nous permet de construire et de mettre en œuvre une stratégie de valeur d'accès au marché du cycle de vie des produits.
    </p>
</div>
 </div>
)
}

export default EnConstruction;

//<? include_once(PAGES_PATH . 'inc/footer.tpl.php'); ?>
