import React from "react"
import { Link } from 'react-router-dom';
import DesarrolloIngenieria from "../../../pages/en/static/images/thumbs/desarrollo-ingenieria.jpg"
import MedicalTechnology from "../../../pages/en/static/images/thumbs/tec-medica.jpg"


function Featured (){

    return(




<div className="featured big divisiones">
        <div className="diseno box">
            <Link to="/spanish/engineering-development">
                <img src={DesarrolloIngenieria} alt="Engineering Development" />
                <h2>Desarrollo en ingeniería</h2>
            </Link>
        </div>
        <div className="produccion box">
            <Link to="/spanish/medical-technology">
            <img  src={MedicalTechnology}  alt="Medical Technology" />
                <h2>Tecnología Médica</h2>
            </Link>
        </div>

        <link rel="stylesheet" href="#" />
</div>

    )}

    export default Featured;