import React from "react"
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom'

function Index (){

	const navigate = useNavigate();
	
	useEffect(() => {
		navigate('/english/')
	},[])

	return(

<>

</>

)
}

export default Index;
