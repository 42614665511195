import React, { useState } from "react";
import { Link } from 'react-router-dom'
import Img from "../../en/static/images/photos/diseno/2.jpg"
import Imgtwo from "../../en/static/images/photos/diseno/7.jpg"
import Imgthree from "../../en/static/images/photos/diseno/5.jpg"
const Tabs = () => {
  const [openTab, setOpenTab] = useState(1);
  const color = 'blue'
  return (
    <>
      <div className="flex flex-wrap list-none bg-[#F1F1F1]">
        <div className="w-full list-none bg-[#F1F1F1]">
          <div
            className="flex mb-0 list-none flex-wrap flex-row "
            style={{borderBottom: "2px solid black"}}
            role="tablist"
          >
            <p className="text-lg list-none"
            style={{margin: "0"}}
            >
            
              <a
              style={{border: "1px solid black", padding: '6px', borderRadius: '6px 6px 0 0', marginLeft: '2px'}}
                className={
                  "list-none" +
                  (openTab === 1
                    ? "text-white bg-" + color + "-200"
                    : "text-" + color + "-600 bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                <i className="fas fa-space-shuttle text-base mr-1 list-none"></i> Mechanicals Systems
              </a>
            </p>
            <p className="" style={{margin: "0"}}>
              <a
              style={{border: "1px solid black", padding: '6px', borderRadius: '6px 6px 0 0', marginLeft: '2px'}}
                className={
                  "" +
                  (openTab === 2
                    ? "text-white bg-" + color + "-200"
                    : "text-" + color + "-600 bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                <i className="fas fa-cog text-base mr-1"></i>  Pneumatic and hydraulic systems
              </a>
            </p>
            <p className="" style={{margin: "0"}}>
              <a
              style={{border: "1px solid black", padding: '6px', borderRadius: '6px 6px 0 0', marginLeft: '2px'}}
                className={
                  "" +
                  (openTab === 3
                    ? "text-white bg-" + color + "-200"
                    : "text-" + color + "-600 bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(3);
                }}
                data-toggle="tab"
                href="#link3"
                role="tablist"
              >
                <i className="fas fa-briefcase text-base mr-1"></i>  Electronic Systems
              </a>
            </p>
          </div>
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-[#F1F1F1]">
            <div className="px-4 py-5 flex-auto">
              <div className="tab-content tab-space">
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                <div id="mecanicos">
                <h2>Mechanical Systems</h2>
                <div className="photo left" title="Descripcion de la foto 2" rel="prettyPhoto[diseno]" to="<?=IMG_PATH?>/photos/diseno/2_big.jpg">
                    <img alt="" src={Img} className="mr-2 -ml-3 " />
                </div>
                <p className="withphotoTwo ">
                Main designs include drive mechanisms, control and transmission, tooling and gages, special packaging, assemblies and pieces on demand, such as valves, laboratory equipment (micromanipulators, mass calorimeters) tailored threads (internal and external); steel or aluminum pieces with TIG welding qualified and certified.
                </p>
                <br className="clear" />
                <p>
                The following materials are used for designing : common metals such as steel, copper, bronze, aluminum, specialty metals such as stainless steel of different composition, grade 5 titanium, tungsten, Monel k500 and other complex  ferrous and non ferrous alloys, non metals such as Nylon, Teflon Grilon, acetalic resins, polymer matrix composites materials such as epoxy based resins, polyester or polyurethane, fiberglass, carbon fiber or special polyamides such as Kevlar, metal matrix composites and ceramic composites.
                </p>
                <p>
                The work is done with profiles, rods, plates, bearings, ball joints, bushings, pulleys, chains, springs, making tailored designs or adaptations when necessary.
                </p>
                <br className="clear" />
            </div>
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                <div id="neumaticos">
                <h2 className="">Pneumatic and hydraulic systems</h2>
                    <img alt="" className="left ml-3 mr-8 drop-shadow-[0_35px_35px_rgba(0,0,0,0.5)]" src={Imgtwo}/>
                {/* <div className="photo left" title="Descripcion de la foto 7" rel="prettyPhoto[diseno]" to="<?=IMG_PATH?>/photos/diseno/7_big.jpg">
                </div> */}
                <p className="ml">
                The designs use gases such as argon, nitrogen, helium, oxygen and air, among others, and fluids such as mineral, vegetable and synthetic oil, water, diverse chemical products, fuels and highly corrosive elements, among others.
               </p>

                <p className="withphotoTwo">
                Control and transmission / amplification / reduction of power systems for machines and engines are designed.
                </p>
                <br className="clear" />
            </div>
                </div>
                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                <div id="">
               <h2 className="relative left-5">Electronic Systems</h2>
                
                    <img alt="" className=" ml-8 left drop-shadow-[0_35px_35px_rgba(0,0,0,0.5)] mr-8" src={Imgthree}/>
                <p className="withphotoTwo">
                Design and programming of equipment and dedicated control devices: small computers, sensors, command and control panels, communication systems / wireless controller (GPRS/3G, WiFi, Bluetooth) or via special wired systems (CAN BUS), mobile developments in J2ME. Alarm monitoring, remote monitoring, vehicle tracking, people tracking, monitoring systems for production processes: SCADA, building automation or domotics: smart homes overseen by the Internet.
                </p>
                <ul>
                    <li>User interface adaptation and programming: touch screen, USB interface (for microcontrollers), virtual reality devices such as helmets, gloves, special devices, programmable keyboard, readers.</li>
                    <li>Programming Embedded Systems: development of file system, use of Compact Flash and SD.</li>
                </ul>
            </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default function TabsRender() {
  return (
    <>
      <Tabs color="pink" />
    </>
  );
}
