import React, { useEffect } from "react"
import { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import enFlag from "../static/images/flags/en.png"
import esFlag from "../static/images/flags/es.png"
import frFlag from "../static/images/flags/fr.png"
import Logo from "../static/images/flags/logotecxio.png"



function Header (){


    const navigate = useNavigate()
  const [isOpen, setisOpen] = useState(true);
  const toggleSubmenu = (e) => {
       console.log("submenu")
      if (isOpen) {
          setisOpen(false);
      } else if (!isOpen) {
          setisOpen(true);
      }
  } 

    // const navigate = useNavigate();

    return(

        



 
     
      
                
        <div id="" className="pt-6 pb-6 mx-auto max-w-screen">

            <div id="logo" className="mr-8"
            style={{height: '45px', width: 'auto'}}>
                <Link to="/english" title="TECXIO">
                    <img src={Logo} alt="Tecxio" title="TECXIO" height="40" style={{height: '45px', width: 'auto'}} />
                </Link>
            </div>
            
            <div className="flex flex-col justify-center h-10 ">
                <p  onClick={(e) => toggleSubmenu()}  className="flex flex-col items-start justify-end text-sm text-gray-600 hover:underline">
                    <div className="">
                        <span className="flex">
                            <img src={enFlag} alt="English" className="inline w-6" />
                            <p className="text-base font-semibold ml-2 border ">English</p>
                        </span>
                        {/* <select name="select" className="h-8 inline py-0 pl-1 pr-7 border-0 text-lg text-black">
                            <option value="value1" > <Link to='/english'>English</Link></option>
                            <option value="value2"> <Link to="/spanish">ss</Link></option>
                            <option value="value3"> Français</option>
                        </select> */}

                       
                    </div>
                    Change Language
                </p>
                {!isOpen && 
                <ul className="divide-y z-10 divide-slate-200 mx-auto absolute border-2 border-black bg-white w-28 top-[70px]">
                            <li className="flex p-1"> <img  className=" w-8 " src={esFlag}  alt="" /><Link className="text-base divide-y divide-slate-200 ml-2 text-center" to="/spanish">Spanish</Link></li>
                            <li className="flex p-1"> <img  className=" w-8 " src={frFlag}  alt="" /><Link className="text-base divide-y divide-slate-200 ml-2 text-center" to="/frances">French</Link></li>
                            <li className="flex p-1"> <img  className=" w-8 " src={enFlag}  alt="" /><Link className="text-base divide-y divide-slate-200 ml-2 text-center" to="/english">English</Link></li>
                           
                        </ul> 
}
            </div>


        </div>    
               


        )
}

export default Header ;
