/*<? 
$title = 'Design and Development Service (I+D)';
include_once(PAGES_PATH . 'inc/head.tpl.php'); 
include_once(PAGES_PATH . 'inc/header.tpl.php'); 
include_once(PAGES_PATH . 'inc/menu.tpl.php'); 
include_once(PAGES_PATH . 'inc/submenu.tpl.php'); 
include_once(PAGES_PATH . 'inc/featured.gallery.tpl.php'); 
?>*/


import React, {useState} from "react"
import { Link, Route } from "react-router-dom"
import SinopticoDiseno from "../en/static/images/photos/informatica/sinoptico-diseno-es.png"
import Footer from "./inc/Footer.tpl"
import Head from "./inc/Head.tpl";
import Header from "./inc/Header.tpl";
import Menu from "../en/inc/Menu.tpl";
import FeaturedGallery from "./inc/Featured.gallery.tpl";
import Submenu from "./inc/Submenu.tpl";
import DisenoOne from "../en/static/images/photos/diseno/2.jpg"
import DisenoTwo from "./../en/static/images/photos/diseno/3.jpg"
import DisenoThree from "../en/static/images/photos/diseno/4.jpg"
import Modal from "../en/inc/Modal";

function DiseñoyDesarrollo (){

    const [ModalImage, setModalImage] = useState(false)
    const toggleModal = (e) => {
        e.preventDefault()
        e.stopPropagation() 
        if ( ModalImage) {
             setModalImage(false);
            } else {
                setModalImage(true);
            }}
    return(

        <div className="grid place-content-center w-screen">
{/* <Head/> */}
            <Header/>
            <Submenu/>
            <FeaturedGallery/>

            <div id="content" className="mx-auto">
                <div id="breadcrumbs">
                    <Link to={'/spanish'}>TECXIO  </Link>{` > `}
                    <Link to={'/spanish/engineering-development'}>Desarrollo en ingenieria   </Link>{` > `}
                    <Link to={'/spanish/engineering-development/products-and-services'}>Productos and servicios</Link>{` > `}
                    <strong>Servicio de Diseño y Desarrollo/ Ingeniería (I+D)</strong>
                </div>
                <h1>Servicio de Diseño y Desarrollo/ Ingeniería (I+D)</h1>
                
                <div id="leftbar">
        <h4><Link className="text-xs" to="<?=REMOTE_PATH_DIV?>productos-y-servicios.html">Productos y Servicios</Link></h4>
        <ul className="flex flex-col">
            <li><Link className="bg-lime-600 border-solid border border-yellow-200 rounded-lg text-xs" to= "/spanish/engineering-development/research-and-development">Dise&ntilde;o y Desarrollo</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/production">Producci&oacute;n</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/industrial-management">Gesti&oacute;n Industrial</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/informatics">Informatica</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/repairs">Reparaciones</Link></li>
        </ul>
                </div>
                <div id="wrapper">
                    <p>
                    TECXIO®, a través de su división I+D, ofrece el servicio de diseño y desarrollo de productos de alto valor agregado y a la vez cuenta con recursos y capacidad productiva para fabricar piezas únicas altamente complejas, prototipos y pequeñas series, o gestionar la producción de grandes series en plantas productivas asociadas. De este modo, podemos ofrecer a nuestros clientes la provisión de productos, en las distintas etapas de su desarrollo, desde la concepción de una idea, hasta la elaboración y embalaje del producto terminado.                    </p>
                    <p className="text-align:center"> 
                        <img  src={SinopticoDiseno} alt="Cuadro Sinoptico" usemap="#map" />
                        <map id="map" name="map">
                            <area shape="rect" coords="179,10,529,39" href='/spanish/engineering-development/research-and-development/Devices-machinery-equipment-pieces.tpl'  title="Design and calculation of structures" />
                            <area shape="rect" coords="179,43,529,72" href='/spanish/engineering-development/research-and-development/Calculation-structures.tpl'  title="Design and calculation of structures" />
                            <area shape="rect" coords="179,77,529,106" href="/spanish/engineering-development/research-and-development/Fabrication-assembly-processes.tpl" title="Design of fabrication and assembly processes" />
                            <area shape="rect" coords="179,110,529,139" href='/spanish/engineering-development/research-and-development/Re-engineering-manufacturing-assembly-processes.tpl' title="Re-Engineering of Manufacturing and Assembly Processes" />
                            <area shape="rect" coords="179,143,529,172" href='/spanish/engineering-development/research-and-development/Production-cells.tpl' title="Design and development of production cells" />
                            <area shape="rect" coords="179,178,529,207" href='/spanish/engineering-development/research-and-development/Testing-materials-equipment.tpl ' title="Testing of materials and equipment" />
                            <area shape="rect" coords="179,213,530,242" href='/spanish/engineering-development/research-and-development/Documentation-products-processes.tpl' title="Documentation of products and processess" />
                        </map>
                    </p>
                    
                    {/*<!--
                    <LiclassName="photo left" title="Descripcion de la foto 1" rel="prettyPhoto[diseno]" to="<?=IMG_PATH?>/photos/diseno/1_big.jpg">
                        <img alt="" src="<?=IMG_PATH?>/photos/diseno/1.jpg"/>
                    </Link>
                    <p className="withphoto">
                    TECXIO<sup>&reg;</sup>, a trav&eacute;s de su divisi&oacute;n I+D, ofrece el servicio de dise&ntilde;o y desarrollo de productos de alto valor agregado y 
                    a la vez cuenta con recursos y capacidad productiva para fabricar piezas &uacute;nicas altamente complejas, prototipos  y peque&ntilde;as series, o 
                    gestionar la producci&oacute;n de grandes series en plantas productivas asociadas. De este modo, podemos ofrecer a nuestros clientes la provisi&oacute;n 
                    de productos, en las distintas etapas de su desarrollo, desde la concepci&oacute;n de una idea hasta la elaboraci&oacute;n y embalaje del producto terminado.
                </p>-->*/}
                    <Link className="photo left" title="Descripcion de la foto 1" rel="prettyPhoto[diseno]" to={DisenoOne}>
                        <img onClick={(e) => toggleModal(e) }  alt="" src={DisenoOne}/>
                        {ModalImage && <Modal img={DisenoOne} toggleModal={toggleModal} />}

 
                    </Link>
                    
                    <p className="withphototwo">
                    TECXIO® está en condiciones de dar soporte a los distintos sectores de la empresa cliente (Diseño y Desarrollo, Ingeniería de Producto, Ingeniería de Proceso, Producción, Compras, Logística) o incluso ejecutar tareas que las empresas decidan tercerizar, por razones estratégicas o asociadas con las características del negocio.                    </p>
                    <div className="photo right" title="Descripcion de la foto 1" rel="prettyPhoto[diseno]" to="<?=IMG_PATH?>/photos/diseno/3_big.jpg">
                        <img alt="" src={DisenoTwo}/>
                    </div>
                    <p className="withphototwo">
                    TECXIO® pone a disposición de sus clientes, un equipo de profesionales conformado específicamente en función de los requerimientos del trabajo o proyecto propuesto y los dirige asegurando el cumplimiento en tiempo y forma de los objetivos que se fijen.                    </p>
                    <div className="photo left" title="Descripcion de la foto 1" rel="prettyPhoto[diseno]" to="<?=IMG_PATH?>/photos/diseno/4_big.jpg">
                        <img alt="" src={DisenoThree}/>
                    </div>
                    <p className="withphototwo">
                    TECXIO® puede proveer a las empresas que así lo deseen, productos y proyectos "llave en mano".
                    </p>
                </div>
            </div>
        </div>
 )
} 
export default DiseñoyDesarrollo


//<? include_once(PAGES_PATH . 'inc/footer.tpl.php'); ?>
