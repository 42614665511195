/*<? 
$title = 'Testing of materials and equipment';
include_once(PAGES_PATH . 'inc/head.tpl.php'); 
include_once(PAGES_PATH . 'inc/header.tpl.php'); 
include_once(PAGES_PATH . 'inc/menu.tpl.php'); 
include_once(PAGES_PATH . 'inc/submenu.tpl.php'); 
include_once(PAGES_PATH . 'inc/featured.gallery.tpl.php'); 
?>*/

import React, { useState } from "react"
import { Link } from "react-router-dom";
import FeaturedGallery from "../../inc/Featured.gallery.tpl";
import Footer from "../../inc/Footer.tpl";
import Head from "../../inc/Head.tpl";
import Header from "../../inc/Header.tpl";
import Menu from "../../inc/Menu.tpl";
import Submenu from "../../inc/Submenu.tpl";
import DisenoEleven from "../../../en/static/images/photos/diseno/11.jpg"
import DisenoTwelve from "../../../en/static/images/photos/diseno/12.jpg" 
import Modal from "../../../en/inc/Modal";


function TestingMaterialsEquipment() {

    const [ModalImage, setModalImage] = useState(false)
    const toggleModal = (e) => {
        e.preventDefault()
        e.stopPropagation() 
        if ( ModalImage) {
             setModalImage(false);
            } else {
                setModalImage(true);
            }
        }

        const [ModalImageTwo, setModalImageTwo] = useState(false)
        const toggleModalTwo = (e) => {
            e.preventDefault()
            if ( ModalImageTwo) {
                setModalImageTwo(false);
                } else {
            setModalImageTwo(true);
        }} 
    return (


        <div className="grid place-content-center">
        {/* <Head/> */}
        <Header/>
        <Submenu/>
        <FeaturedGallery/>



<div id="content">
    <div id="breadcrumbs">
        {/* <?= printInitBreadcrums() ?> */}
 
        <Link to="/english/engineering-development/products-and-services">Products and Services</Link> &raquo; 
        <Link to="/english/engineering-development/research-and-development">Research and Development</Link> &raquo; 
        <strong>Ensayo de Materiales y Equipos</strong>
     </div>
    <h1>Ensayo de Materiales y Equipos</h1> 
       <div id="leftbar">
    <h4><Link className="text-xs" to="/english/engineering-development/products-and-services">Productos and Servicios</Link></h4>
    <ul className="flex flex-col">
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to= "/spanish/engineering-development/research-and-development">Dise&ntilde;o y Desarrollo</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/production">Producci&oacute;n</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/industrial-management">Gesti&oacute;n Industrial</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/informatics">Informatica</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/repairs">Reparaciones</Link></li>
        </ul>
    </div>
    <div className="-mt-96">
    <div id="wrapper">
        <div class="clear photo left " title="Descripcion de la foto 11" rel="prettyPhoto[diseno]" to="<?=IMG_PATH?>/photos/diseno/11_big.jpg">
            <img onClick={(e) => toggleModal(e) }  alt="" src={DisenoEleven}/>
            {ModalImage && <Modal img={DisenoEleven} toggleModal={toggleModal} />}
        </div>
        <p class="withphototwo">
        Diseño y desarrollo de bancos de prueba y dispositivos para ensayos especiales. Ensayos de calificación de materiales (radiográficos, análisis químicos, durometría, difracción por rayos, micrografías).    
            </p>
        <div class="clear photo left " title="Descripcion de la foto 12" rel="prettyPhoto[diseno]" to="<?=IMG_PATH?>/photos/diseno/12_big.jpg">
            <img onClick={(e) => toggleModalTwo(e)}  alt="" src={DisenoTwelve}/>
            {ModalImageTwo && <Modal img={DisenoTwelve} toggleModal={toggleModalTwo} />}

        </div>
        <p class="withphototwo">
        Se realizan ensayos radiogróficos para piezas soldadas e inspecciones, así como también ensayos destructivos y no destructivos en general.        </p>
    </div>
    </div>
</div>


</div>

)
}

export default TestingMaterialsEquipment;

//<? include_once(PAGES_PATH . 'inc/footer.tpl.php'); ?>
