/*<? 
$title = 'Celdas Productivas Llave en Mano';
include_once(PAGES_PATH . 'inc/head.tpl.php'); 
include_once(PAGES_PATH . 'inc/header.tpl.php'); 
include_once(PAGES_PATH . 'inc/menu.tpl.php'); 
include_once(PAGES_PATH . 'inc/submenu.tpl.php'); 
include_once(PAGES_PATH . 'inc/featured.gallery.tpl.php'); 
?>*/


import React from "react"
import { Link } from "react-router-dom";
import Submenu from "../../../es/inc/Submenu.tpl";
import FeaturedGallery from "../../../en/inc/Featured.gallery.tpl";
import Footer from "../../inc/Footer.tpl";
import Head from "../../../en/inc/Head.tpl";
import Header from "../../../es/inc/Header.tpl";
 
function  CeldasProductivas() {
    return(
<div  className="grid place-content-center w-screen">
 <Head/>
 <Header/>
  <Submenu/>
 <FeaturedGallery/>

<div id="content">
    <div id="breadcrumbs">
        {/*<?= printInitBreadcrums() ?>*/}
        <Link to="<?=REMOTE_PATH_DIV?>productos-y-servicios.html">Productos y Servicios</Link> &raquo; 
        <Link to="<?=REMOTE_PATH_DIV?>diseno-y-desarrollo.html">Servicio de Dise&ntilde;o y Desarrollo/ Ingenier&iacute;a (D+D/I)</Link> &raquo; 
        <strong>Servicio de Dise&ntilde;o y Desarrollo/ Ingenier&iacute;a (D+D/I)</strong>
    </div>
    <h1>Servicio de Dise&ntilde;o y Desarrollo/ Ingenier&iacute;a (D+D/I)</h1>
    <div id="leftbar">
        <h4><Link className="text-xs" to="<?=REMOTE_PATH_DIV?>productos-y-servicios.html">Productos y Servicios</Link></h4>
        <ul className="flex flex-col">
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to= "/spanish/engineering-development/research-and-development">Dise&ntilde;o y Desarrollo</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/production">Producci&oacute;n</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/industrial-management">Gesti&oacute;n Industrial</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/informatics">Informatica</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/repairs">Reparaciones</Link></li>
        </ul>
    </div>
    <div id="wrapper">
        <p>
            Dise&ntilde;o y desarrollo de los procesos internos, entradas y salidas, recursos, identificaci&oacute;n de maquinaria est&aacute;ndar, 
            herramental y matricer&iacute;a necesarios, adaptaciones y/o dise&ntilde;o de m&aacute;quinas especiales o accesorios, lay out e identificaci&oacute;n 
            y capacitaci&oacute;n del personal necesario para la operaci&oacute;n y mantenimiento de la celda.
        </p>
    </div>
</div>

</div>
)
}

export default CeldasProductivas ;

{/*<? include_once(PAGES_PATH . 'inc/footer.tpl.php'); ?>*/}
