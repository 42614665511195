import React from "react";
import { Link } from "react-router-dom";
import Submenu from "../es/inc/Submenu.tpl";
import FeaturedGallery from "../es/inc/Featured.gallery.tpl";
import Footer from "../es/inc/Footer.tpl";
import Head from "../es/inc/Head.tpl";
import Header from "../es/inc/Header.tpl";
import Menu from "../es/inc/Menu.tpl";


function QuienesSomos () {
    return (

        <div className="grid place-content-center w-screen">

<Head/>
            <Header/>
             <Submenu/>
            <FeaturedGallery/>

<div id="content">
    <div id="breadcrumbs">
         <strong>  </strong>
    </div>
    <h1>Qui&eacute;nes somos</h1>
    <p>
    TECXIO<sup>&reg;</sup> fue creada por un grupo de profesionales especializados en distintas ramas de la industria (metalmec&aacute;nica, software, electr&oacute;nica, aeronáutica, dispositivos médicos y de diagnóstico in vitro) 
    con la visi&oacute;n de amalgamar conocimientos t&eacute;cnicos muy espec&iacute;ficos mediante un abordaje generalista, dando lugar a un proceso sinerg&eacute;tico que permita la 
    creaci&oacute;n de productos y servicios de alto valor agregado para nuestros clientes.
    </p>
    <p>
    Los socios y directores de TECXIO<sup>&reg;</sup> han trabajado en grandes, medianas y peque&ntilde;as empresas, holdings, asociaciones y agencias de gobierno, conduciendo proyectos de 
    gran envergadura y ocupando puestos gerenciales en las &aacute;reas de Dise&ntilde;o y Desarrollo, Ingenier&iacute;a de Producto, Ingenier&iacute;a de Proceso, Producci&oacute;n, Calidad, 
    Mantenimiento, Compras, Log&iacute;stica, Comercio Exterior y Tecnología Médica. Esto permite abordar el trabajo con un enfoque que contempla todos los factores involucrados en el 
    dise&ntilde;o y fabricaci&oacute;n de productos/ servicios exitosos: un producto/ servicio debe cumplir con los requisitos de funcionalidad y est&eacute;tica pero a la vez debe poder “ser fabricado” / creado
     tanto desde el punto de vista t&eacute;cnico (procesos de fabricaci&oacute;n, método científico y gesti&oacute;n de la calidad) como econ&oacute;mico (sus componentes y materiales deben poder ser 
     adquiridos a precios razonables en el mercado, para que el costo de los productos/ servicios finales sea compatible con los precios que permitan comercializarlo o 
     hagan factible la realizaci&oacute;n de proyectos).
     </p>
     <p>
    TECXIO<sup>&reg;</sup> puede definirse como una empresa de la “Tercera Ola” utilizando el t&eacute;rmino acu&ntilde;ado por Alvin Toffler <sup>(1)</sup> (asesor de presidentes, primer ministros, 
    y l&iacute;deres mundiales <sup>(2)</sup> ), ya que  su estructura es muy reducida en cuanto al plantel empleado en forma directa, pero posee un gran n&uacute;mero de profesionales 
    asociados que son convocados para la ejecuci&oacute;n de proyectos espec&iacute;ficos. Los equipos de trabajo as&iacute; formados operan a distancia, comunicados mediante 
    distintas herramientas inform&aacute;ticas.  Este modo de trabajo permite convocar a los mejores especialistas (seleccionados especialmente para cada proyecto), 
    sin importar su ubicaci&oacute;n geogr&aacute;fica. De esta manera, es posible minimizar  los costos, asignando a cada proyecto s&oacute;lo las horas de dedicaci&oacute;n que el mismo 
    requiere, sin gastos indirectos asociados con el mantenimiento de la estructura de la empresa (pues los mismos, son muy reducidos).
    </p>
    <p>  
    TECXIO<sup>&reg;</sup> s&oacute;lo opera con profesionales  conocidos, es decir, que ya hayan trabajado con los socios o directores de la empresa o posean una trayectoria 
    acad&eacute;mica y laboral reconocida desde el punto de vista t&eacute;cnico y hayan participado de los programas de investigaci&oacute;n, desarrollo y creaci&oacute;n de nuevos 
    negocios promovidos por la empresa.
    </p>
    <p>
    TECXIO<sup>&reg;</sup> Desarrollo en Ingeniería es una División que se orienta al dise&ntilde;o y desarrollo de productos de alto valor agregado y a la prestaci&oacute;n de servicios en el campo de la 
    ingenier&iacute;a, y a la vez cuenta con recursos y capacidad productiva para fabricar piezas &uacute;nicas altamente complejas, prototipos  y peque&ntilde;as series, o 
    gestionar la producci&oacute;n de grandes series en plantas productivas asociadas.
    </p>
    <p>
    <small><sup>(1)</sup> Toffler Alvin. Third Wave. 2nd Edition.Plaza &amp; Jan&eacute;s, 1984.<br />
    <sup>(2)</sup> Biograf&iacute;a e informaci&oacute;n espec&iacute;fica disponible en <Link to="https://www.alvintoffler.net/?fa=bios" rel="nofollow">https://www.alvintoffler.net/?fa=bios</Link>
    </small>
    </p>
</div>


            
        </div>
    )
}

export default  QuienesSomos