/*<? 
$title = 'Testing of materials and equipment';
include_once(PAGES_PATH . 'inc/head.tpl.php'); 
include_once(PAGES_PATH . 'inc/header.tpl.php'); 
include_once(PAGES_PATH . 'inc/menu.tpl.php'); 
include_once(PAGES_PATH . 'inc/submenu.tpl.php'); 
include_once(PAGES_PATH . 'inc/featured.gallery.tpl.php'); 
?>*/

import React, { useState } from "react"
import { Link } from "react-router-dom";
import FeaturedGallery from "../../inc/Featured.gallery.tpl";
import Footer from "../../inc/Footer.tpl";
import Head from "../../inc/Head.tpl";
import Header from "../../inc/Header.tpl";
import Menu from "../../inc/Menu.tpl";
import Submenu from "../../inc/Submenu.tpl";
import DisenoEleven from "../../static/images/photos/diseno/11.jpg"
import DisenoTwelve from "../../static/images/photos/diseno/12.jpg" 
import Modal from "../../inc/Modal";


function TestingMaterialsEquipment() {

    const [ModalImage, setModalImage] = useState(false)
    const toggleModal = (e) => {
        e.preventDefault()
        e.stopPropagation() 
        if ( ModalImage) {
             setModalImage(false);
            } else {
                setModalImage(true);
            }
        }

        const [ModalImageTwo, setModalImageTwo] = useState(false)
        const toggleModalTwo = (e) => {
            e.preventDefault()
            if ( ModalImageTwo) {
                setModalImageTwo(false);
                } else {
            setModalImageTwo(true);
        }} 
    return (


        <div className="grid place-content-center">
        {/* <Head/> */}
        <Header/>
        <Submenu/>
        <FeaturedGallery/>



<div id="content">
    <div id="breadcrumbs">
        {/* <?= printInitBreadcrums() ?> */}
 
        <Link to="/english/engineering-development/products-and-services">Products and Services</Link> &raquo; 
        <Link to="/english/engineering-development/research-and-development">Research and Development</Link> &raquo; 
        <strong>{/*<?=$title?>*/}</strong>
     </div>
    <h1>Testing of materials and equipment</h1>
    <div id="leftbar">
    <h4><Link className="text-xs" to="/english/engineering-development/products-and-services">Products and Services</Link></h4>
            <ul className="flex flex-col">
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/research-and-development">Research and Development</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/production">Production</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/industrial-management">Industrial Management</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/informatics">Informatics</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/repairs">Repairs</Link></li>
        </ul>
    </div>
    <div className="-mt-96">
    <div id="wrapper">
        <div class="clear photo left " title="Descripcion de la foto 11" rel="prettyPhoto[diseno]" to="<?=IMG_PATH?>/photos/diseno/11_big.jpg">
            <img onClick={(e) => toggleModal(e) }  alt="" src={DisenoEleven}/>
            {ModalImage && <Modal img={DisenoEleven} toggleModal={toggleModal} />}
        </div>
        <p class="withphototwo">
            Design and development of test beds and devices for special tests. Qualification testing of materials (radiological, chemical analysis, durometrics, ray diffraction, electron micrographs).
        </p>
        <div class="clear photo left " title="Descripcion de la foto 12" rel="prettyPhoto[diseno]" to="<?=IMG_PATH?>/photos/diseno/12_big.jpg">
            <img onClick={(e) => toggleModalTwo(e)}  alt="" src={DisenoTwelve}/>
            {ModalImageTwo && <Modal img={DisenoTwelve} toggleModal={toggleModalTwo} />}

        </div>
        <p class="withphototwo">
            Radiographic trials are conducted for weldments and inspections, as well as destructive and nondestructive tests in general.
        </p>
    </div>
    </div>
</div>


</div>

)
}

export default TestingMaterialsEquipment;

//<? include_once(PAGES_PATH . 'inc/footer.tpl.php'); ?>
