 import React from "react" 
 import  { Link } from 'react-router-dom';
import WhatsAppButton from "../../WhatsappButton";
 
 function Footer () {
     return(

         

        <footer className="grid place-content-center w-screen pb-4">
            <div  className="footer" id="">
                <p>
                <span className="txt-footer">2010. All rights reserved. <Link to="/english/contact">Contact us</Link></span>
                <span className="txt-footer">Copyrighted works by signed confidentiality agreements with our customers are not exhibited on this website. <br />
                Tecxio<sup>®</sup> currently provides services in the field of science and technology to different government agencies.</span>
                <a href="http://qr.afip.gob.ar/?qr=dnkvbD5BJAix1A4JOAc7Xg,," target="_F960AFIPInfo"><img className="h-20 mx-auto mt-4" src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg" border="0" alt="" /></a>
                </p>
            </div>
            {/* <WhatsAppButton/> */}
        </footer>      

        
        
    
    /*  {/*style="height:60px; margin-top:15px"}
    style="display:block;margin-top:7px"
    style="display:block;margin-top:7px"   
    <!-- /container -->

    
<?=$extraResources?>
 <script type="text/javascript" src="<?=JS_PATH?>jquery.gallery.js"></script> 
<script type="text/javascript" src="<?=JS_PATH?>jquery.prettyPhoto.js"></script>
<script type="text/javascript" src="<?=JS_PATH?>jquery.tabs.js"></script> 
<script type="text/javascript" src="<?=JS_PATH?>menu.js"></script>
<script type="text/javascript" src="./featured.js"></script>
<script type="text/javascript" src="<?=JS_PATH?>search.js"></script>
<script type="text/javascript" src="<?=JS_PATH?>init.js"></script> 

<!-- /Analytics -->
<script type="text/javascript">
    var gaJsHost = (("https:" == document.location.protocol) ? "https://ssl." : "https://www.");
    document.write(unescape("%3Cscript src='" + gaJsHost + "google-analytics.com/ga.js' type='text/javascript'%3E%3C/script%3E"));
</script>
<script type="text/javascript">
    try {
        var pageTracker = _gat._getTracker("UA-12900582-1");
        pageTracker._trackPageview();
    } catch(err) {}
</script>
<!-- /Analytics --> 
*/


)
}     

export default Footer;
       