/*<? 
$title = 'Documentation of products and processes';
include_once(PAGES_PATH . 'inc/head.tpl.php'); 
include_once(PAGES_PATH . 'inc/header.tpl.php'); 
include_once(PAGES_PATH . 'inc/menu.tpl.php'); 
include_once(PAGES_PATH . 'inc/submenu.tpl.php'); 
include_once(PAGES_PATH . 'inc/featured.gallery.tpl.php'); 
?>*/

import React from "react"
import { Link } from "react-router-dom"
import FeaturedGallery from "../../inc/Featured.gallery.tpl";
import Footer from "../../inc/Footer.tpl";
import Head from "../../inc/Head.tpl";
import Header from "../../inc/Header.tpl";
import Menu from "../../inc/Menu.tpl";
import Submenu from "../../inc/Submenu.tpl";

function DocumentacionProductosProcesos () {
    return(
        <div className="grid place-content-center w-screen">
        {/* <Head/> */}
        <Header/>
        <Submenu/>
        <FeaturedGallery/>



<div id="content">
    <div id="breadcrumbs">
       {/* <?= printInitBreadcrums() ?> */}
        <Link to="/english/engineering-development/products-and-services">Productos y Servicios</Link> &raquo; 
        <Link to="/english/engineering-development/research-and-development">Servicio de Dise&ntilde;o y Desarrollo/ Ingenier&iacute;a (D+D/I)</Link> &raquo; 
        <strong>Documentación de Productos y Procesos</strong>
    </div>
    <h1>Documentación de Productos y Procesos</h1>
    <div id="leftbar">
    <h4><Link className="text-xs" to="/english/engineering-development/products-and-services">Productos y Servicios</Link></h4>      
    <ul className="flex flex-col">
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to= "/spanish/engineering-development/research-and-development">Dise&ntilde;o y Desarrollo</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/production">Producci&oacute;n</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/industrial-management">Gesti&oacute;n Industrial</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/informatics">Informatica</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/repairs">Reparaciones</Link></li>
        </ul>
    </div>
    <div id="wrapper">
    <p>
            Confecci&oacute;n de matriz de producto/insumos/proveedores, hojas de ruta 
            y diagramas de proceso, gr&aacute;ficos 3D y planos de fabricaci&oacute;n y montaje. 
            Codificaci&oacute;n de productos, procesos y maquinarias. 
        </p>
    </div>
</div>

</div>
)
}
export default DocumentacionProductosProcesos;

//<? include_once(PAGES_PATH . 'inc/footer.tpl.php'); ?>



 