/*<? 
$title = 'Cellules de production "clé en main"';
include_once(PAGES_PATH . 'inc/head.tpl.php'); 
include_once(PAGES_PATH . 'inc/header.tpl.php'); 
include_once(PAGES_PATH . 'inc/menu.tpl.php'); 
include_once(PAGES_PATH . 'inc/submenu.tpl.php'); 
include_once(PAGES_PATH . 'inc/featured.gallery.tpl.php'); 
?>*/

import React from "react"
import { Link } from "react-router-dom";
import FeaturedGallery from "../../inc/Featured.gallery.tpl";
import Footer from "../../inc/Footer.tpl";
import Head from "../../inc/Head.tpl";
import Header from "../../inc/Header.tpl";
import Menu from "../../inc/Menu.tpl";
import Submenu from "../../inc/Submenu.tpl";

function CellulesDeProduction (){
    return(

        <div className="grid place-content-center w-screen">
        {/* <Head/> */}
        <Header/>
        <Submenu/>
        <FeaturedGallery/>



<div id="content">
    <div id="breadcrumbs">
       {/* <?= printInitBreadcrums() ?> */}
        <Link to="/english/engineering-development/products-and-services">Produits et Services</Link> &raquo; 
        <Link to="/english/engineering-development/research-and-development">Research and Development</Link> &raquo; 
        <strong>Cellules de production "clé en main"</strong>
    </div>
    <h1>Cellules de production "clé en main"</h1>
    <div id="leftbar">
    <h4><Link className="text-xs" to="/english/engineering-development/products-and-services">Produits et Services</Link></h4>
    <ul className="flex flex-col">
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/research-and-development">Conception et développement</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/production">Production sur demande</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/industrial-management">Gestion Industrielle</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/informatics">Informatique</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/repairs">Réparations</Link></li>
        </ul>
    </div>
    <div id="wrapper">
        <p>
        Conception et développement de processus internes, des intrants et des extrants, des ressources, l'identification des équipements de série, des outils et matrices nécessaires, l´adaptation et/ou conception de machines spéciales ou des accessoires, le lay out et l´identification et la formation du personnel nécessaire pour le fonctionnement et l'entretien de la cellule.        </p>
    </div>
</div>

{/* <Footer/> */}

</div>
)
}

export default CellulesDeProduction;


 