import React from "react"
import { Link } from "react-router-dom"
import Footer from "./inc/Footer.tpl"
import Head from "./inc/Head.tpl";
import Header from "./inc/Header.tpl";
import Menu from "../en/inc/Menu.tpl";
import Submenu from "./inc/Submenu.tpl";
import FeaturedEngineeringDevelopment from "./inc/Featured-engineering-development.tpl";
 import Featured from "./inc/Featured.tpl";
import FeaturedGallery from "./inc/Featured.gallery.tpl";
import SinopticoRepairs from "../en/static/images/photos/informatica/sinoptico-reparaciones-fr.png"
import ReparacionOne from "../en/static/images/photos/reparacion/1.jpg"
import ReparacionTwo from "../en/static/images/photos/reparacion/2.jpg"


function Reparations (){
    return(
<div className="grid place-content-center w-screen">
{/* <Head/> */}
<Header/>
<Submenu/>
<FeaturedGallery/>
<div id="content">
    <div id="breadcrumbs">
 
        <Link to={'/frances'}>TECXIO  </Link>{` > `}
        <Link to={'/frances/engineering-development'}>Engineering development   </Link>{` > `}
        <Link to={'/frances/engineering-development/products-and-services'}>Produits et Services</Link>{` > `}
        <strong>Repair and upgrade equipment service (RAE)</strong>
     </div>
    <h1>Service de réparation et de mise à jour de l\'équipement (RAE)</h1>
    <div id="leftbar">
    <h4><Link className="text-xs" to="/frances/engineering-development/products-and-services">Produits et Services</Link></h4>
    <ul className="flex flex-col">
                        <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/research-and-development">Conception et développement</Link></li>
                        <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/production">Production sur demande</Link></li>
                        <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/industrial-management">Gestion Industrielle</Link></li>
                        <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/informatics">Informatique</Link></li>
                        <li><Link className="bg-lime-600 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/repairs">Réparations</Link></li>
                    </ul>
    </div>
    <div id="wrapper">
        <p className="text-align:center">
            <img src={SinopticoRepairs} alt="Cuadro Sinoptico" />
        </p>
        <div class="photo left" to="<?=IMG_PATH?>photos/reparacion/1_big.jpg" rel="prettyPhoto[reparacion]" title="Descripcion de la foto 1">
            <img src={ReparacionOne} alt="" />
        </div>
        <p class="withphototwo">
        TECXIO<sup>®</sup>, grâce à sa division de RAE, offre le service de réparation d'équipements mécaniques, y compris le remplacement de pièces spéciales qui doivent être étudiées et construites sur mesure (telles que des couronnes, des engrenages, de taille petite ou grande) , l'usinage de pièces de fonderie et l'adaptation des systèmes de roulements usés et de réparation des fils spéciaux.
        </p>
        <p className="pe"></p>
        <div class="photo left" to="<?=IMG_PATH?>photos/reparacion/2_big.jpg" rel="prettyPhoto[reparacion]" title="Descripcion de la foto 2">
            <img src={ReparacionTwo} alt="" />
        </div>
        <p class="withphototwo">
        TECXIO<sup>®</sup>, offre aux clients la possibilité de redessiner ou modifier le matériel endommagé, afin de  réduire la fréquence des réparations et même d'éliminer certains types d'échecs grâce à des solutions créatives.
        </p>
      
    </div>
     
      
        
        
</div>

{/* <Footer/> */}

</div>
)
} 
export default Reparations








/*<? 
$title = 'Service de réparation et de mise à jour de l\'équipement (RAE)';
include_once(PAGES_PATH . 'inc/head.tpl.php'); 
include_once(PAGES_PATH . 'inc/header.tpl.php'); 
include_once(PAGES_PATH . 'inc/menu.tpl.php'); 
include_once(PAGES_PATH . 'inc/submenu.tpl.php'); 
include_once(PAGES_PATH . 'inc/featured.gallery.tpl.php'); 
?>*/

 