 import React from "react"
import { Link } from "react-router-dom";
import Head from "../fr/inc/Head.includes.tpl";
import Header from "../fr/inc/Header.tpl";
import Menu from "../fr/inc/Menu.tpl";
import Submenu from "../fr/inc/Submenu.tpl";
 import Footer from "../fr/inc/Footer.tpl";
import FeaturedGallery from "../../pages/fr/inc/Featured.gallery.tpl";
import One from "../en/static/images/photos/diseno/2.jpg"
import Two from "../en/static/images/photos/diseno/3.jpg"
import Three from "../en/static/images/photos/diseno/4.jpg"
import SinopticoDiseno from "../en/static/images/photos/informatica/sinoptico-diseno-fr.png"


function ConceptionEtDeveloppement (){
    return(

        <div className="grid place-content-center w-screen">
        <Head/>
        <Header/>
        <Submenu/>
        <FeaturedGallery/>

<div id="content">
    <div id="breadcrumbs">
          {/* <?= printInitBreadcrums() ?>*/}<Link to="<?=REMOTE_PATH_DIV?>products-and-services.html">Produits et Services</Link> &raquo; <strong>Services de conception et développement / ingénierie (I+D)</strong>
    </div>
    <h1>Services de conception et développement / ingénierie (I+D)</h1>
    <div id="leftbar">
        <h4><Link to="<?=REMOTE_PATH_DIV?>products-and-services.html">Produits et Services</Link></h4>

        <ul className="flex flex-col">
            <li><Link className="bg-lime-600 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/research-and-development">Conception et développement</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/production">Production sur demande</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/industrial-management">Gestion Industrielle</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/informatics">Informatique</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/repairs">Réparations</Link></li>
        </ul>
    </div>
    <div id="wrapper">
        <p>
            TECXIO<sup>®</sup>, à travers de sa division I+D, offre le service de conception et de développement de produits de haute valeur ajoutée. 
            On a aussi les moyens et les capacités pour fabriquer des pièces uniques et très complexes, des prototypes et des petites séries de production, 
            ou aussi de gérer la production de grandes séries dans des usines productives associées. Ainsi, nous pouvons offrir à nos clients des produits à 
            des divers stades de développement, à partir de la conception d'une idée à la fabrication et l´emballage de produits terminés.
        </p>
        <p className="text-align:center">
            <img src={SinopticoDiseno} alt="Cuadro Sinoptico" usemap="#map" />
             <map id="map" name="map">
                            <area shape="rect" coords="179,10,529,39" href='/frances/engineering-development/research-and-development/Devices-machinery-equipment-pieces.tpl'  title="Design and calculation of structures" />
                            <area shape="rect" coords="179,43,529,72" href='/frances/engineering-development/research-and-development/Calculation-structures.tpl'  title="Design and calculation of structures" />
                            <area shape="rect" coords="179,77,529,106" href="/frances/engineering-development/research-and-development/Fabrication-assembly-processes.tpl" title="Design of fabrication and assembly processes" />
                            <area shape="rect" coords="179,110,529,139" href='/frances/engineering-development/research-and-development/Re-engineering-manufacturing-assembly-processes.tpl' title="Re-Engineering of Manufacturing and Assembly Processes" />
                            <area shape="rect" coords="179,143,529,172" href='/frances/engineering-development/research-and-development/Production-cells.tpl' title="Design and development of production cells" />
                            <area shape="rect" coords="179,178,529,207" href='/frances/engineering-development/research-and-development/Testing-materials-equipment.tpl ' title="Testing of materials and equipment" />
                            <area shape="rect" coords="179,213,530,242" href='/frances/engineering-development/research-and-development/Documentation-products-processes.tpl' title="Documentation of products and processess" />
                        </map>
        </p>
        
        <Link className="photo left" title="Descripcion de la foto 1" rel="prettyPhoto[diseno]" to={One}>
                        <img lt="" src={One}/>

 
                    </Link>
                    
                    <p className="withphototwo">
                    TECXIO® est capable de proposer du soutien aux différents secteurs de l'entreprise cliente (conception et développement, ingénierie de produits, ingénierie des processus, production, achats, logistique) ou même effectuer des tâches que les entreprises décident d'externaliser, á cause de raisons stratégiques ou caractéristiques de l'organisation.</p>
                        <div className="photo right" title="Descripcion de la foto 1" rel="prettyPhoto[diseno]" to="<?=IMG_PATH?>/photos/diseno/3_big.jpg">
                        <img alt="" src={Two}/>
                    </div>
                    <p className="withphototwo">
                    TECXIO® offre à ses clients une équipe professionnelle composée spécialement en fonction des exigences du travail ou du projet proposé, et garanti l'accomplissement des objectifs fixés. </p>
                    <div className="photo left" title="Descripcion de la foto 1" rel="prettyPhoto[diseno]" to="<?=IMG_PATH?>/photos/diseno/4_big.jpg">
                        <img alt="" src={Three}/>
                    </div>
                    <p className="withphototwo">
                    TECXIO® peut fournir aux entreprises qui le désirent, des produits et des projets “clé en main”.
                    </p>
       
    </div>
</div>
{/* <Footer/> */}
</div>
)
} 
export default ConceptionEtDeveloppement;


//<? include_once(PAGES_PATH . 'inc/footer.tpl.php'); ?>

