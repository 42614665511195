import React from "react"
import { Link } from "react-router-dom"
import Footer from "./inc/Footer.tpl"
import Head from "./inc/Head.tpl";
import Header from "./inc/Header.tpl";
import Menu from "../en/inc/Menu.tpl";
import FeaturedGallery from "./inc/Featured.gallery.tpl";
import Submenu from "./inc/Submenu.tpl";

function ProduitsEtService (){
    return(
            <div className="grid place-content-center w-screen">
            <Head/>
            <Header/>
            <Submenu/>
            <FeaturedGallery/>

            <div id="content">
                <div id="breadcrumbs">
                    <Link to={'/frances'}>TECXIO  </Link>{` > `}<Link to={'/frances/engineering-development'}>Engineering development   </Link>{` > `}<strong>Produits et Services</strong>
                </div>
                <h1>Produits et Services</h1>
                <div id="leftbar">
                    <h4><Link className="text-xs" to="/frances/engineering-development/products-and-services">Produits et Services</Link></h4>
                    <ul className="flex flex-col">
                        <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/research-and-development">Conception et développement</Link></li>
                        <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/production">Production sur demande</Link></li>
                        <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/industrial-management">Gestion Industrielle</Link></li>
                        <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/informatics">Informatique</Link></li>
                        <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/repairs">Réparations</Link></li>
                    </ul>
                </div>
                <div id="wrapper">
                    <p>
                    TECXIO<sup>&reg;</sup> a été créé par un groupe de professionnels spécialisés dans des différentes branches de l'industrie 
        (mécanique, logiciels, électronique et aéronautique) avec la vision de fusionner des connaissances techniques 
        précises grâce à une approche généraliste, débouchant sur un processus de synérgie qui permet la création de produits 
        et services à haute valeur ajoutée pour nos clients.                    </p>
                </div>    
            </div>
            
            </div>
    )
} 

export default ProduitsEtService;


 