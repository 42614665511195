 

/*<? 
$title = 'Informatics (IT)';
include_once(PAGES_PATH . 'inc/head.tpl.php'); 
include_once(PAGES_PATH . 'inc/header.tpl.php'); 
include_once(PAGES_PATH . 'inc/menu.tpl.php'); 
include_once(PAGES_PATH . 'inc/submenu.tpl.php'); 
include_once(PAGES_PATH . 'inc/featured.gallery.tpl.php'); 
?>
*/
import React from "react";
import { Link } from "react-router-dom";
import Head from "../fr/inc/Head.includes.tpl";
import Header from "./inc/Header.tpl";
import Menu from "../fr/inc/Menu.tpl";
import Submenu from "../fr/inc/Submenu.tpl";
 import Footer from "../fr/inc/Footer.tpl";
import FeaturedGallery from "../fr/inc/Featured.gallery.tpl";
import OneBig from "../en/static/images/photos/informatica/1_big.jpg"
import SecondBig from "../en/static/images/photos/informatica/2.jpg"
import ThirdBig from "../en/static/images/photos/informatica/3.jpg"
import ForthBig from "../en/static/images/photos/informatica/4.jpg"
import SinopticoInformatica from "../en/static/images/photos/informatica/sinoptico-informatica-fr.png"

function Informatique (){
    return(
        <div className="grid place-content-center w-screen">
        <Head/>
        <Header/>
        <Submenu/>
        <FeaturedGallery/>

<div id="content">
    <div id="breadcrumbs">
       {/* <?= printInitBreadcrums() ?>*/}<Link to="<?=REMOTE_PATH_DIV?>products-and-services.html">Produits et Services</Link> &raquo; <strong>Informatique (IT)</strong>
    </div>
    <h1>Informatique (IT)</h1>
    <div id="leftbar">
        <h4><Link to="<?=REMOTE_PATH_DIV?>products-and-services.html">Produits et Services</Link></h4>
         <ul className="flex flex-col">
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/research-and-development">Conception et développement</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/production">Production sur demande</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/industrial-management">Gestion Industrielle</Link></li>
            <li><Link className="bg-lime-600 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/informatics">Informatique</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/repairs">Réparations</Link></li>
        </ul>
     </div>
    <div id="wrapper">
        <p>
        TECXIO<sup>®</sup>, grâce à sa division (IT) offre le design, le développement et l’implantation de logiciels de contrôle, de calcul, de gestion et de conception dans le domaine de l'ingénierie.
        </p>
        
        <p className="text-align:center">
            <img src={SinopticoInformatica} alt="Cuadro Sinoptico" />
        </p>
        
        <p>
        TECXIO<sup>®</sup> peut concevoir, par exemple, un logiciel qui permet de calculer des formules empiriques des ressorts,  la conception et la fabrication d'une machine capable de produire certains types de ressorts, y compris la conception de la cellule de production toute entière (à travers sa division, "I + D Services “) et, en même temps, concevoir un logiciel de contrôle pour cette machine.
        </p>
        <div className="minigallery margin:10px 0 10px 110px">
            <div className="photo left" to="<?=IMG_PATH?>photos/informatica/1_big.jpg" rel="prettyPhoto[informatica]" title="Descripcion de la foto 1">
                <img src={OneBig} alt="" />
            </div>
            <div className="photo left" to="<?=IMG_PATH?>photos/informatica/2_big.jpg" rel="prettyPhoto[informatica]" title="Descripcion de la foto 2">
                <img src={SecondBig} alt="" />
            </div>
            <div className="photo left" to="<?=IMG_PATH?>photos/informatica/3_big.jpg" rel="prettyPhoto[informatica]" title="Descripcion de la foto 3">
                <img src={ThirdBig} alt="" />
            </div>
            <div className="photo left" to="<?=IMG_PATH?>photos/informatica/4_big.jpg" rel="prettyPhoto[informatica]" title="Descripcion de la foto 4">
                <img src={ForthBig} alt="" />
            </div>
        </div>
    </div>
</div>

{/* <Footer/> */}

</div>
)
}

export default Informatique;


//<? include_once(PAGES_PATH . 'inc/footer.tpl.php'); ?>

