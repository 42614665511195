
 

import React from "react"
import { Link } from "react-router-dom"
import Footer from "./inc/Footer.tpl"
import Head from "./inc/Head.tpl";
import Header from "./inc/Header.tpl";
import Menu from "./inc/Menu.tpl"; 
import FeaturedGallery from "./inc/Featured.gallery.tpl";
import Submenu from "./inc/Submenu.tpl";

function UnderConstruction (){
    return(

        <div className="grid place-content-center w-screen">

        {/* <Head/> */}
        <Header/>
        <Menu/>
        <FeaturedGallery/>

<div id="content">
<div id="breadcrumbs">
         <Link to="/english">TECXIO</Link><strong> {`>`} Tecnología Medica</strong>
    </div>
    <h1>Tecxio MD</h1>
    <p>
    We are a healthcare consultancy focused on the generation and communication of the value of health technologies (medical devices, diagnostics , medicines incl. vaccines). Our wide experience interacting with key stakeholders,
combined with a strong commercial mindset and sound scientific background in medical affairs, HTA, market access, health economics and Outcomes Research allows us to build and implement a product lifecycle market access value strategy.    </p>
</div>
</div>
)
} 
export default UnderConstruction;

