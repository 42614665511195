/*<? 
$title = 'Research and Business Plant (USINA)';
include_once(PAGES_PATH . 'inc/head.tpl.php'); 
include_once(PAGES_PATH . 'inc/header.tpl.php'); 
include_once(PAGES_PATH . 'inc/menu.tpl.php'); 
include_once(PAGES_PATH . 'inc/submenu.tpl.php'); 
include_once(PAGES_PATH . 'inc/featured.gallery.tpl.php'); 
?>*/

import React from "react"
import {Link} from "react-router-dom"

import SinopticoUsina from "./static/images/photos/informatica/sinoptico-usina-en.png"
import Footer from "./inc/Footer.tpl"
import Head from "./inc/Head.tpl";
import Header from "./inc/Header.tpl";
import Menu from "../en/inc/Menu.tpl";
import FeaturedGallery from "./inc/Featured.gallery.tpl";
import Submenu from "./inc/Submenu.tpl";
import FeaturedEngineeringDevelopment from "./inc/Featured-engineering-development.tpl";
 import Featured from "./inc/Featured.tpl";


function ResearchAndBusiness (){
    return(

<div className="grid place-content-center w-screen">

{/* <Head/> */}
<Header/>
<Submenu/>
<FeaturedGallery/>


<div id="content">
    <div id="breadcrumbs">
         {/* <?= printInitBreadcrums() ?>*/}<strong>Research and Business Plant (USINA)</strong>
    </div>
    <h1>Research and Business Plant (USINA)</h1>
    <p className="text-align:center">
        <img className="mx-auto" src={SinopticoUsina} alt="Cuadro Sinoptico" />
    </p>
    <p>
    The idea is to create groups that research and develop products and services designed to improve the quality of life of people and help preserve the environment. 
    </p>
    <p>
    The main research areas are: non-conventional energy (wind, solar, hydrogen), biofuels, new propulsion systems and transport, artificial intelligence and expert systems. 
    </p>
    <p>
    Sustainability: TECXIO<sup>®</sup> provides research forums the structure and experience necessary for the implementation of profitable projects and the possibility that its members constitute, together with TECXIO<sup>®</sup>, a commercial company that exploits the generated knowledge.   
    </p>
    <p>
    TECXIO<sup>®</sup>, in turn, makes job searches among members of the research forums that stand out by their professional skills, humanity, dedication and creativity. 
    </p>
    <p>
    TECXIO<sup>®</sup>, through its USINA division promotes: 
    </p>
    <ul>
        <li>
            The development and implementation of new projects in the field of engineering. <Link to="javascript:;" className="showmore" rel="1" attr="Read more|Read less">Read more</Link>
            <div className="more1 hidden">
                <br /><br />TECXIO<sup>®</sup> promotes the formation of multidisciplinary research forum, with appropriate specialists in different areas of engineering and various branches of science. <Link to="javascript:;" className="showmore" rel="4" attr="Read more|Read less">Read more</Link><br /><br />
                
                <div className="more4 hidden">
                    We seek that research forums have the following characteristics: 
                    <ol>
                        <li>
                            To be built in terms of a topic of interest, such as "wind turbines for electricity generation," photovoltaic paintings "," secure systems for hydrogen storage, "" expert systems to control complex equipment”.<br /> 
                            
                            TECXIO<sup>®</sup> will issue through his blog: <Link to="https://www.tecxio.com/english/under-construction.html">tecxioblog.com</Link>,a list of research topics that may be processed in the forums and in turn, encourage users to choose or propose new topics or narrow the topics suggested (addressing more specific related issues). 
                        </li>
                        <li>To be formed by a small group of members: if possible no more than 5 people. </li>
                        <li>Is intended that the forum members are professionals or have appropriate knowledge in the research topics chosen. </li>
                        <li>Each forum must appoint a coordinator and a person in charge of documentation. </li>
                        <li>
                            After formation of the forum around a specific theme and having appointed a coordinator and documentation responsible, the activity will be ordered in stages. <Link to="javascript:;" className="showmore" rel="2" attr="Read more|Read less">Read more</Link>
                            <div className="more2 hidden">
                                <ol>
                                    <li>Exchange of ideas (brain storming) </li>
                                    <li>Setting targets: 
                                        <ol>
                                            <li>Define specifically what is going to be researched and what concrete results we hope to achieve. </li>
                                            <li>Define if result of research will create pure knowledge (science) or applied knowledge (technologies). </li>
                                            <li>Define if you expect to form a company around the results of the investigation or simply produce academic material. </li>
                                        </ol>
                                    </li>
                                    <li>
                                        Development of research: <br />
                                        Definition, implementation and monitoring of the tasks of researching and documenting the results.</li>
                                    <li>Final Report </li>
                                    <li>
                                        Publication of results (internal: to be accessed only by members of the forum or by whom they authorize,or external: to be accessed by the rest of the users or the general public at the discretion of the members of the forum). 
                                    </li>
                                </ol>
                            </div>
                        </li>
                        <li>
                            TECXIO<sup>®</sup> will appoint one member of the division USINA (USINA Advisor) to monitor the activity of the various forums, taking care to provide the necessary resources for their operation and analyze the affinity of the themes developed by the various forums, with a view to promoting exchange forums. 
                        </li>
                        <li>
                            The activity of each forum in aspects of research and documentation generated as a result of this activity will be known by members of the forum and USINA Advisors appointed by TECXIO<sup>®</sup>.
                        </li>
                        <li>
                            The exchange of information between research forums sponsored by the USINA Advisors must be approved by the forum members involved. 
                        </li>
                        <li>
                            There may be several forums with the same research topic and they may act in coordination with other groups or in isolation, according to the desire of members of the forum. 
                        </li>
                        <li>
                            TECXIO<sup>®</sup> will publish the research topics in all forums, but will only publish the results of research or development thereof, if the members of each forum agree. 
                        </li>
                    </ol>
                </div>
            </div>
        </li>
        <li>
            The strategic partnership of companies, to implement new projects in the field of engineering. <Link to="javascript:;" className="showmore" rel="3" attr="Read more|Read less">Read more</Link>
            <div className="more3 hidden">
                TECXIO<sup>®</sup> seeks to achieve a thorough understanding of their customers while performing an ongoing analysis of emerging business opportunities arising from the market and research forums. From the information gathered, TECXIO<sup>®</sup> acts as a bridge between companies, promoting strategic partnerships (for entering new markets, conduct joint purchasing,  shared marketing channels), vertical or horizontal integration.
            </div>
        </li>
    </ul>
</div>
</div>
)
} 
export default ResearchAndBusiness

//<? include_once(PAGES_PATH . 'inc/footer.tpl.php'); ?>
