import React from "react"
 import Flag from "../../en/static/images/flags/es.png"
import Logo from "../../../pages/en/static/images/flags/logotecxio.png"



 import { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import enFlag from "../../../pages/en/static/images/flags/en.png"
import esFlag from "../../../pages/en/static/images/flags/es.png"
import frFlag from "../../../pages/en/static/images/flags/fr.png"
 



function Header ({ languaje, setLanguaje }){

    // const navigate = useNavigate();

    
    const navigate = useNavigate()
  const [isOpen, setisOpen] = useState(true);
  const toggleSubmenu = (e) => {
       console.log("submenu")
      if (isOpen) {
          setisOpen(false);
      } else if (!isOpen) {
          setisOpen(true);
      }
  } 

    return(

        



 
     
      
                
        <div id="" className="pt-6 ml-[300px] pb-6 mx-auto max-w-screen">

            <div id="logo" className="mr-8" style={{height: '45px', width: 'auto'}}>
                <Link to="/spanish" title="TECXIO">
                    <img src={Logo} alt="Tecxio" title="TECXIO" height="40"  style={{height: '45px', width: 'auto'}}/>
                </Link>
            </div>
            
            <div className="flex flex-col justify-center h-10">
                <p onClick={(e) => toggleSubmenu()} className="flex flex-col items-start justify-end text-sm text-gray-600 hover:underline">
                    <div>
                        <span className=" flex">
                            <img src={frFlag} alt="English" className="inline h-4" />
                            <p className="text-base font-semibold ml-2 border ">Français</p>

                    
                        </span>
                        {/* <select name="select" className="h-8 inline py-0 pl-1 pr-7 border-0 text-lg text-black">
                            <option onClick={() => navigate('/espanol')} value="english" defaultValue >Español</option>
                            <option onClick={() => navigate('/english')} value="value2"  > English</option>
                            <option onClick={() => navigate('/Frances')} value="value3"> Francais</option>
                        </select> */}
                    </div>
                    Change languaje

                    {!isOpen && 
                      <ul className="divide-y z-10 divide-slate-200 mx-auto absolute border-2 border-black bg-white w-28 top-[70px]">
                            <li className="flex p-1"> <img  className=" w-8 " src={esFlag}  alt="" /><Link className="text-base divide-y divide-slate-200 ml-2 text-center" to="/spanish">Español</Link></li>
                            <li className="flex p-1"> <img  className=" w-8 " src={frFlag}  alt="" /><Link className="text-base divide-y divide-slate-200 ml-2 text-center" to="/frances">Français</Link></li>
                            <li className="flex p-1"> <img  className=" w-8 " src={enFlag}  alt="" /><Link className="text-base divide-y divide-slate-200 ml-2 text-center" to="/english">English</Link></li>
                           
                        </ul>
} 
                </p>
            </div>

        </div>    
               


        )
}

export default Header ;
