/*<? 
$title = 'Re-Engineering of Manufacturing and Assembly Processes';
include_once(PAGES_PATH . 'inc/head.tpl.php'); 
include_once(PAGES_PATH . 'inc/header.tpl.php'); 
include_once(PAGES_PATH . 'inc/menu.tpl.php'); 
include_once(PAGES_PATH . 'inc/submenu.tpl.php'); 
include_once(PAGES_PATH . 'inc/featured.gallery.tpl.php'); 
?>*/
import React from "react"
import { Link } from "react-router-dom";
import FeaturedGallery from "../../inc/Featured.gallery.tpl";
import Footer from "../../inc/Footer.tpl";
import Head from "../../inc/Head.tpl";
import Header from "../../inc/Header.tpl";
import Menu from "../../inc/Menu.tpl";
import Submenu from "../../inc/Submenu.tpl";

function ReingenieriaProcesosFabricacionMontaje ( ) {
    return (

        <div className="grid place-content-center w-screen">
        {/* <Head/> */}
        <Header/>
        <Submenu/>
        <FeaturedGallery/>


<div id="content">
    <div id="breadcrumbs">
        {/* <?= printInitBreadcrums() ?> */}
        <Link to="/english/engineering-development/products-and-services">Products and Services</Link> &raquo; 
        <Link to="/english/engineering-development/research-and-development">Research and Development</Link> &raquo; 
        <strong>Re-Ingeniería de Procesos de Fabricación y Montaje</strong>
    </div>
    <h1>Re-Ingeniería de Procesos de Fabricación y Montaje</h1>
    <div id="leftbar">
    <h4><Link className="text-xs" to="/english/engineering-development/products-and-services">Products and Services</Link></h4>
    <ul className="flex flex-col">
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to= "/spanish/engineering-development/research-and-development">Dise&ntilde;o y Desarrollo</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/production">Producci&oacute;n</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/industrial-management">Gesti&oacute;n Industrial</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/informatics">Informatica</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/repairs">Reparaciones</Link></li>
        </ul>
    </div>
    <div className=" " id="wrapper">
        <p className="">
        Relevamiento de procesos existentes, re-ingeniería y diseño de nuevos procesos, análisis de flujos de materiales e información, codificación, documentación de incidencias, trazabilidad, identificación de procesos críticos, análisis de demora y stocks intermedios, fabricación flexible, aprovisionamiento flexible.        </p>
    </div>
</div>



</div>
)
}

export default ReingenieriaProcesosFabricacionMontaje;


//<? include_once(PAGES_PATH . 'inc/footer.tpl.php'); ?>


 