import React from "react"
import { Link } from "react-router-dom"
import Footer from "./inc/Footer.tpl"
import Head from "./inc/Head.tpl";
import Header from "./inc/Header.tpl";
import Menu from "../en/inc/Menu.tpl";
import Submenu from "./inc/Submenu.tpl";
import FeaturedEngineeringDevelopment from "./inc/Featured-engineering-development.tpl";
 import Featured from "./inc/Featured.tpl";
import FeaturedGallery from "./inc/Featured.gallery.tpl";
import SinopticoRepairs from "./static/images/photos/informatica/sinoptico-reparaciones-en.png"
import ReparacionOne from "./static/images/photos/reparacion/1.jpg"
import ReparacionTwo from "./static/images/photos/reparacion/2.jpg"


function Repairs (){
    return(
<div className="grid place-content-center w-screen">
{/* <Head/> */}
<Header/>
<Submenu/>
<FeaturedGallery/>
<div id="content">
    <div id="breadcrumbs">
 
        <Link to={'/english'}>TECXIO  </Link>{` > `}
        <Link to={'/english/engineering-development'}>Engineering development   </Link>{` > `}
        <Link to={'/english/engineering-development/products-and-services'}>Products and services</Link>{` > `}
        <strong>Repair and upgrade equipment service (RAE)</strong>
     </div>
    <h1>Repair and upgrade equipment service (RAE)</h1>
    <div id="leftbar">
    <h4><Link className="text-xs" to="/english/engineering-development/products-and-services">Products and Services</Link></h4>
        <ul className="flex flex-col">
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/research-and-development">Research and Development</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/production">Production</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/industrial-management">Industrial Management</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/informatics">Informatics</Link></li>
            <li><Link className=" bg-lime-600   border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/repairs">Repairs</Link></li>
        </ul>
    </div>
    <div id="wrapper">
        <p className="text-align:center">
            <img src={SinopticoRepairs} alt="Cuadro Sinoptico" />
        </p>
        <div class="photo left" to="<?=IMG_PATH?>photos/reparacion/1_big.jpg" rel="prettyPhoto[reparacion]" title="Descripcion de la foto 1">
            <img src={ReparacionOne} alt="" />
        </div>
        <p class="withphototwo">
        TECXIO<sup>®</sup>, through its RAE division, offers the service of mechanical equipment repair, including replacement of special parts that need to be surveyed and tailored made (such as no ends and crowns, axles, small or large size gears) , machining of worn molding and adaptation of bearings systems and repair of special threads.
        </p>
        <p className="pe"></p>
        <div class="photo left" to="<?=IMG_PATH?>photos/reparacion/2_big.jpg" rel="prettyPhoto[reparacion]" title="Descripcion de la foto 2">
            <img src={ReparacionTwo} alt="" />
        </div>
        <p class="withphototwo">
        TECXIO®, offers customers the opportunity to redesign or modify damaged equipment to reduce the frequency of repairs and even eliminate certain types of failure through creative solutions.
        </p>
      
    </div>
     
      
        
        
</div>

</div>
)
} 
export default Repairs
