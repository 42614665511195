/*<? 
include_once(PAGES_PATH . 'inc/head.tpl.php'); 
include_once(PAGES_PATH . 'inc/header.tpl.php'); 
include_once(PAGES_PATH . 'inc/menu.tpl.php'); 
include_once(PAGES_PATH . 'inc/submenu.tpl.php'); 
include_once(PAGES_PATH . 'inc/featured.tpl.php'); 
?>*/


import React from "react"
import { Link } from "react-router-dom";
import Head from "./inc/Head.tpl";
import Header from "./inc/Header.tpl";
import Menu from "../fr/inc/Menu.tpl";
import Featured from "./inc/Featured.tpl";
import Footer from "./inc/Footer.tpl";
 

function Frances (){

    return(
      
      <>
        <div id="header" className="header mx-auto max-w-screen">
        <Header/> 
      <Menu/>
      <Featured/>

  <div id="content">
      <p className="margin-top:15px">
      TECXIO&reg; est une soci&eacute;t&eacute; ax&eacute;e sur l'acc&egrave;s &agrave; l'innovation dans le domaine de l'ing&eacute;nierie industrielle  et technologique m&eacute;dicale, qui ajoute de la valeur aux clients et les patients.
    </p>
      <p>
      La Division du D&eacute;veloppement de l'ing&eacute;nierie offre des services multiples, de la R &amp; D &agrave; la production, service apr&egrave;s-vente et de r&eacute;paration, dans le domaine de la m&eacute;tallurgie, le software, l'&eacute;lectronique et l'a&eacute;ronautique. Il fournit &eacute;galement des services de la innovation in situ (open innovation) et du gestion du changement.
    </p>
      <p>
      La Division de la Technologie M&eacute;dicale prend en charge les syst&egrave;mes de soins de sant&eacute; et les entreprises de technologie m&eacute;dicale pour fournir des soins de sant&eacute; fond&eacute;e sur la valeur. Cela inclut l'int&eacute;gration des soins dans le traitement des besoins m&eacute;dicaux des patients avec le soutien des dispositifs m&eacute;dicaux et in vitro innovants, de nouveaux syst&egrave;mes de paiement et accords de distribution des risques, la mesure des r&eacute;sultats des patients et les couts des soins et la g&eacute;n&eacute;ration et l'&eacute;valuation de la preuve de la valeur ajout&eacute;e des technologies m&eacute;dicales.
    </p>
  </div>
  {/* <Footer/> */}
  </div>

      
      </>


)
}

export default Frances ;


//<? include_once(PAGES_PATH . 'inc/footer.tpl.php'); ?>
