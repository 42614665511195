/*<? 
$title = 'Production on Request (PRAP)';
include_once(PAGES_PATH . 'inc/head.tpl.php'); 
include_once(PAGES_PATH . 'inc/header.tpl.php'); 
include_once(PAGES_PATH . 'inc/menu.tpl.php'); 
include_once(PAGES_PATH . 'inc/submenu.tpl.php'); 
include_once(PAGES_PATH . 'inc/featured.gallery.tpl.php'); 
?>*/

import React, {useState} from "react"
import { Link } from "react-router-dom"
import Head from "./inc/Head.tpl";
import Header from "./inc/Header.tpl";
import Menu from "../en/inc/Menu.tpl";
import Submenu from "./inc/Submenu.tpl";
import FeaturedEngineeringDevelopment from "./inc/Featured-engineering-development.tpl";
import Footer from "./inc/Footer.tpl";
import Featured from "./inc/Featured.tpl";
import FeaturedGallery from "./inc/Featured.gallery.tpl";
import SinopticoProduccion from "./static/images/photos/informatica/sinoptico-produccion-en.png"
import ProductionOne from "./static/images/photos/produccion/1.jpg"
import ProductionTwo from "./static/images/photos/produccion/2.jpg"
import ProductionThree from "./static/images/photos/produccion/3.jpg"
import ProductionFour from "./static/images/photos/produccion/4.jpg"
import ProductionFive from "./static/images/photos/produccion/5.jpg"
import Modal from "./inc/Modal";




function Production (){
    const [ModalImage, setModalImage] = useState(false)
    const toggleModal = (e) => {
        e.preventDefault()
        e.stopPropagation() 
        if ( ModalImage) {
             setModalImage(false);
            } else {
                setModalImage(true);
            }
    }

    const [ModalImageTwo, setModalImageTwo] = useState(false)
    const toggleModalTwo = (e) => {
        e.preventDefault()
        if ( ModalImageTwo) {
            setModalImageTwo(false);
            } else {
        setModalImageTwo(true);
    }} 
    const [ModalImageThree, setModalImageThree] = useState(false)
    const toggleModalThree = (e) => {
        e.preventDefault()
        if ( ModalImageThree) {
            setModalImageThree(false);
            } else {
        setModalImageThree(true);
    }} 

    const [ModalImageFour, setModalImageFour] = useState(false)
    const toggleModalFour = (e) => {
        e.preventDefault()
        if ( ModalImageFour) {
            setModalImageFour(false);
            } else {
        setModalImageFour(true);
    }}
    const [ModalImageFive, setModalImageFive] = useState(false)
    const toggleModalFive = (e) => {
        e.preventDefault()
        if ( ModalImageFive) {
            setModalImageFive(false);
            } else {
        setModalImageFive(true);
    }}

    return(
<div className="grid place-content-center w-screen">

<Head/>
<Header/>
 
<Submenu/>
<FeaturedGallery/>


<div id="content" className="mx-auto">
    <div id="breadcrumbs">
        <Link to={'/english'}>TECXIO  </Link>{` > `}
        <Link to={'/english/engineering-development'}>Engineering development   </Link>{` > `}
        <Link to={'/english/engineering-development/products-and-services'}>Products and services</Link>{` > `}
        <strong>Production on Request (PRAP)</strong>
    </div>
    <h1>Production on Request (PRAP)</h1>
    <div id="leftbar">
    <h4><Link className="text-xs" to="/english/engineering-development/products-and-services">Products and Services</Link></h4>
        <ul className="flex flex-col">
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/research-and-development">Research and Development</Link></li>
            <li><Link className=" bg-lime-600   border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/production">Production</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/industrial-management">Industrial Management</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/informatics">Informatics</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/repairs">Repairs</Link></li>
        </ul>
    </div>
    <div id="wrapper">
        <p>
        TECXIO<sup>®</sup> has resources and productive capacity to produce highly complex unique pieces, prototypes and small series, or manage the production of large series in associated productive plants.
        </p>
        <p className="text">
            <img src={SinopticoProduccion} alt="Cuadro Sinoptico" />
        </p>
        <p>
        TECXIO<sup>®</sup> is able to manufacture mechanical, hydraulic, pneumatic, electronic or mixed systems, designed or developed by the Customer (according to drawings and specifications of the Customer), or designed or developed by TECXIO<sup>®</sup> at Customer's request.
        </p>
        <div class="minigallery" className="margin:10px 0 10px 44px">
            <Link class="photo left" to="<?=IMG_PATH?>photos/produccion/1_big.jpg" rel="prettyPhoto[produccion]" title="Descripcion de la foto 1">
                <img  onClick={(e) => toggleModal(e) }src={ProductionOne} alt="" />
                {ModalImage && <Modal img={ProductionOne} toggleModal={toggleModal} />}

            </Link> 
            <Link class="photo left" to="<?=IMG_PATH?>photos/produccion/2_big.jpg" rel="prettyPhoto[produccion]" title="Descripcion de la foto 2">
                <img onClick={(e) => toggleModalTwo(e) } src={ProductionTwo} alt="" />
                {ModalImageTwo && <Modal img={ProductionTwo} toggleModal={toggleModalTwo} />}

            </Link>
            <Link class="photo left" to="<?=IMG_PATH?>photos/produccion/3_big.jpg" rel="prettyPhoto[produccion]" title="Descripcion de la foto 3">
                <img onClick={(e) => toggleModalThree(e) } src={ProductionThree} alt="" />
                {ModalImageThree && <Modal img={ProductionThree} toggleModal={toggleModalThree} />}

            </Link>
            <Link class="photo left" to="<?=IMG_PATH?>photos/produccion/4_big.jpg" rel="prettyPhoto[produccion]" title="Descripcion de la foto 4">
                <img onClick={(e) => toggleModalFour(e) } src={ProductionFour} alt="" />
                {ModalImageFour && <Modal img={ProductionFour} toggleModal={toggleModalFour} />}

            </Link>
            <Link class="photo left" to="<?=IMG_PATH?>photos/produccion/5_big.jpg" rel="prettyPhoto[produccion]" title="Descripcion de la foto 5">
                <img onClick={(e) => toggleModalFive(e) }  src={ProductionFive} alt="" />
                {ModalImageFive && <Modal img={ProductionFive} toggleModal={toggleModalFive} />}

            </Link>
           
           
        </div>
    </div>
</div>

</div>
)
} 
export default Production

//<? include_once(PAGES_PATH . 'inc/footer.tpl.php'); ?>
