/*<? 
$title = 'Design and Development Service (I+D)';
include_once(PAGES_PATH . 'inc/head.tpl.php'); 
include_once(PAGES_PATH . 'inc/header.tpl.php'); 
include_once(PAGES_PATH . 'inc/menu.tpl.php'); 
include_once(PAGES_PATH . 'inc/submenu.tpl.php'); 
include_once(PAGES_PATH . 'inc/featured.gallery.tpl.php'); 
?>*/


import React, {useState} from "react"
import { Link, Route } from "react-router-dom"
import SinopticoDiseno from "./static/images/photos/informatica/sinoptico-diseno-en.png"
import Footer from "./inc/Footer.tpl"
import Head from "./inc/Head.tpl";
import Header from "./inc/Header.tpl";
import Menu from "../en/inc/Menu.tpl";
import FeaturedGallery from "./inc/Featured.gallery.tpl";
import Submenu from "./inc/Submenu.tpl";
import DisenoOne from "./static/images/photos/diseno/2.jpg"
import DisenoTwo from "./static/images/photos/diseno/3.jpg"
import DisenoThree from "./static/images/photos/diseno/4.jpg"
import Modal from "./inc/Modal";


function ResearchAndDevelopment (){

    const [ModalImage, setModalImage] = useState(false)
    const toggleModal = (e) => {
        e.preventDefault()
        e.stopPropagation() 
        if ( ModalImage) {
             setModalImage(false);
            } else {
                setModalImage(true);
            }}
    return(

        <div className="grid place-content-center w-screen">
{/* <Head/> */}
            <Header/>
            <Submenu/>
            <FeaturedGallery/>

            <div id="content" className="mx-auto">
                <div id="breadcrumbs">
                    <Link to={'/english'}>TECXIO  </Link>{` > `}<Link to={'/english/engineering-development'}>Engineering development   </Link>{` > `}<Link to={'/english/engineering-development/products-and-services'}>Products and services</Link>{` > `}<strong>Design and Development Service (I+D)</strong>
                </div>
                <h1>Design and Development Service (I+D)</h1>
                <div id="leftbar">
                    <h4><Link className="text-xs " to="/english/engineering-development/products-and-services">Products and Services</Link></h4>
                    <ul className="flex  flex-col">
                        <li className=""><Link className="selectedd bg-lime-600   border-solid border border-yellow-200  rounded-lg text-xs" to="/english/engineering-development/research-and-development">Research and Development</Link></li>
                        <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/production">Production</Link></li>
                        <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/industrial-management">Industrial Management</Link></li>
                        <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/informatics">Informatics</Link></li>
                        <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/repairs">Repairs</Link></li>
                    </ul>
                </div>
                <div id="wrapper">
                    <p>
                        TECXIO<sup>®</sup>, through its R & D division, offers the service of designing and developing high added value products as well as having the resources and productive capacity to produce highly complex unique pieces, prototypes and small series, or manage the production of large series in associated production facilities. Thus, we can offer our customers the supply of products in various stages of development, from conception of an idea to manufacturing and packaging of finished product.         
                    </p>
                    <p className="text-align:center"> 
                        <img  src={SinopticoDiseno} alt="Cuadro Sinoptico" usemap="#map" />
                        <map id="map" name="map">
                            <area shape="rect" coords="179,10,529,39" href='/english/engineering-development/research-and-development/Devices-machinery-equipment-pieces.tpl'  title="Design and calculation of structures" />
                            <area shape="rect" coords="179,43,529,72" href='/english/engineering-development/research-and-development/Calculation-structures.tpl'  title="Design and calculation of structures" />
                            <area shape="rect" coords="179,77,529,106" href="/english/engineering-development/research-and-development/Fabrication-assembly-processes.tpl" title="Design of fabrication and assembly processes" />
                            <area shape="rect" coords="179,110,529,139" href='/english/engineering-development/research-and-development/Re-engineering-manufacturing-assembly-processes.tpl' title="Re-Engineering of Manufacturing and Assembly Processes" />
                            <area shape="rect" coords="179,143,529,172" href='/english/engineering-development/research-and-development/Production-cells.tpl' title="Design and development of production cells" />
                            <area shape="rect" coords="179,178,529,207" href='/english/engineering-development/research-and-development/Testing-materials-equipment.tpl ' title="Testing of materials and equipment" />
                            <area shape="rect" coords="179,213,530,242" href='/english/engineering-development/research-and-development/Documentation-products-processes.tpl' title="Documentation of products and processess" />
                        </map>
                    </p>
                    
                    {/*<!--
                    <LiclassName="photo left" title="Descripcion de la foto 1" rel="prettyPhoto[diseno]" to="<?=IMG_PATH?>/photos/diseno/1_big.jpg">
                        <img alt="" src="<?=IMG_PATH?>/photos/diseno/1.jpg"/>
                    </Link>
                    <p className="withphoto">
                    TECXIO<sup>&reg;</sup>, a trav&eacute;s de su divisi&oacute;n I+D, ofrece el servicio de dise&ntilde;o y desarrollo de productos de alto valor agregado y 
                    a la vez cuenta con recursos y capacidad productiva para fabricar piezas &uacute;nicas altamente complejas, prototipos  y peque&ntilde;as series, o 
                    gestionar la producci&oacute;n de grandes series en plantas productivas asociadas. De este modo, podemos ofrecer a nuestros clientes la provisi&oacute;n 
                    de productos, en las distintas etapas de su desarrollo, desde la concepci&oacute;n de una idea hasta la elaboraci&oacute;n y embalaje del producto terminado.
                </p>-->*/}
                    <Link className="photo left" title="Descripcion de la foto 1" rel="prettyPhoto[diseno]" to={DisenoOne}>
                        <img onClick={(e) => toggleModal(e) }  alt="" src={DisenoOne}/>
                        {ModalImage && <Modal img={DisenoOne} toggleModal={toggleModal} />}

 
                    </Link>
                    
                    <p className="withphototwo">
                        TECXIO<sup>®</sup> is capable of supporting the various sectors of the client company (Design and Development, Product Engineering, Process Engineering, Production, Purchasing, Logistics) or even perform tasks that companies decide to outsource for strategic reasons or associated with the characteristics of the business. 
                    </p>
                    <div className="photo right" title="Descripcion de la foto 1" rel="prettyPhoto[diseno]" to="<?=IMG_PATH?>/photos/diseno/3_big.jpg">
                        <img alt="" src={DisenoTwo}/>
                    </div>
                    <p className="withphototwo">
                        TECXIO<sup>®</sup> offers its customers a professional team composed specifically in terms of job requirements or projects proposed, leading it and ensuring proper and timely fulfillment of set objectives.
                    </p>
                    <div className="photo left" title="Descripcion de la foto 1" rel="prettyPhoto[diseno]" to="<?=IMG_PATH?>/photos/diseno/4_big.jpg">
                        <img alt="" src={DisenoThree}/>
                    </div>
                    <p className="withphototwo">
                        TECXIO<sup>®</sup> can provide to companies wishing to do so, turn-key products and projects.
                    </p>
                </div>
            </div>
        </div>
 )
} 
export default ResearchAndDevelopment


//<? include_once(PAGES_PATH . 'inc/footer.tpl.php'); ?>
