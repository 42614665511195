import React , { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom";

function Submenu (){

const navigate = useNavigate()
  const [isOpen, setisOpen] = useState(true);
  const toggleSubmenu = (e) => {
       console.log("submenu")
      if (isOpen) {
          setisOpen(false);
      } else if (!isOpen) {
          setisOpen(true);
      }
  } 
 
  const [isOpenRight, setisOpenRight] = useState(true);
  const toggleSubmenuRight = (e) => {
       console.log("submenu")
      if (isOpenRight) {
          setisOpenRight(false);
      } else if (!isOpenRight) {
          setisOpenRight(true);
      }
  } 

    return (



<div className="grid place-content-center ">

 
  <div className="menubg   flex  flex-start  flex-col md:flex-row items-start">
    <nav className="nav-menu font-sm     flex-wrap items-start text-sm ">
      <Link   to="/english/" className="mr-5     ">Home</Link>
      <Link  to=""  className="dropdownName"  onClick={(e) => toggleSubmenu()} >Products and Services</Link>
      {!isOpen && 
       
      <div id="submenu" className=" " > 
    <div className="productos submenu  ">
        <ul className="left">
            <li>
                <p clasName="p-name" title="Research and Development"    onClick={(e) => toggleSubmenuRight(e)} > <span  to="/english/engineering-development/research-and-development"> Research and Development  </span>
                <div className="dropdowntwo">
             {!isOpenRight && 
 
        <ul className='bg-slate-200 absolute text-start -top-44 -mt-px -left-6'>
            <li><Link  to= "/english/engineering-development/research-and-development/Devices-machinery-equipment-pieces.tpl"   >Design of devices, machinery, equipment and pieces </Link></li>
            <li><Link to="/english/engineering-development/research-and-development/Calculation-structures.tpl">Design and calculation of structures</Link></li>
            <li><Link to="/english/engineering-development/research-and-development/fabrication-assembly-processes.tpl">Design of fabrication and assembly processes</Link></li>
            <li><Link to="/english/engineering-development/research-and-development/re-engineering-manufacturing-assembly-processes.tpl">Re-Engineering of Manufacturing and Assembly Processes</Link></li>
            <li><Link to="/english/engineering-development/research-and-development/production-cells.tpl">Design and development of production cells</Link></li>
            <li><Link to="/english/engineering-development/research-and-development/testing-materials-equipment.tpl">Testing of materials and equipment</Link></li>
            <li><Link to="/english/engineering-development/research-and-development/documentation-products-processes.tpl">Documentation of products and processess</Link></li>
         </ul>
        
        }
                 </div>  
                </p>
        
            </li>
         
            <li><Link className="produccion" to="/english/engineering-development/production" title="Production">Production</Link></li>
            <li><Link className="gestion" to="/english/engineering-development/industrial-management" title="Industrial Management">Industrial Management</Link></li>
            <li><Link className="informatica" to="/english/engineering-development/informatics" title="Informatics">Informatics</Link></li>
            <li><Link className="reparaciones" to="/english/engineering-development/repairs" title="Repairs">Repairs</Link></li>
            
        </ul>
      
        </div>
</div>
}
      <Link to="/english/engineering-development/research-and-business-plant"  className="mr-5 hover:text-gray-900">Research and Bussines Plant</Link>
      <Link  to="/english/about"  className="mr-5 hover:text-gray-900">About us</Link>
      <Link to="/english/contact"  className="mr-5 hover:text-gray-900">Contact us</Link>
       
       
    </nav>
  </div>
{/*<div class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
    <div class="py-1" role="none">
       <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">Account settings</a>
      <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-1">Support</a>
      <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2">License</a>
      <form method="POST" action="#" role="none">
        <button type="submit" class="text-gray-700 block w-full text-left px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-3">Sign out</button>
      </form>
    </div>
  </div>*/}




 </div>
   
   
   
   
   )}


 

 

 
export default Submenu;
