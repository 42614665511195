import React, { useState} from "react";
import { Link } from "react-router-dom";
import Submenu from "../es/inc/Submenu.tpl";
import FeaturedGallery from "../../pages/es/inc/Featured.gallery.tpl";
import Footer from "../es/inc/Footer.tpl";
import Head from "../../pages/en/inc/Head.tpl";
import Header from "../../pages/en/inc/Header.tpl";
import SinopticoGestion from "../en/static/images/photos/informatica/sinoptico-gestion-es.png"
import GestionOne from "../en/static/images/photos/gestion/1.jpg"
 


function GestionIndustrial () {
    const [ModalImage, setModalImage] = useState(false)
    const toggleModal = (e) => {
        e.preventDefault()
        e.stopPropagation() 
        if ( ModalImage) {
             setModalImage(false);
            } else {
                setModalImage(true);
            }}

    return (

<div className="grid place-content-center w-screen">

<Head/>
            <Header/>
             <Submenu/>
            <FeaturedGallery/>

<div id="content">
    <div id="breadcrumbs">
         <Link to="<?=REMOTE_PATH_DIV?>productos-y-servicios.html">Productos y Servicios</Link> &raquo; <strong>Servicio de Gesti&oacute;n Industrial (GI) </strong>
    </div>
    <h1>Servicio de Gesti&oacute;n Industrial (GI)</h1>
    <div id="leftbar">
        <h4><Link className="text-xs" to="/spanish/engineering-development/products-and-services">Productos y Servicios</Link></h4>
        <ul className="flex flex-col">
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to= "/spanish/engineering-development/research-and-development">Dise&ntilde;o y Desarrollo</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/production">Producci&oacute;n</Link></li>
            <li><Link className="bg-lime-600 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/industrial-management">Gesti&oacute;n Industrial</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/informatics">Informatica</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/repairs">Reparaciones</Link></li>
        </ul>
    </div>
    <div id="wrapper">
  
        <p className="st1">
            <img src={SinopticoGestion} alt="Cuadro Sinoptico" />
        </p>


        <div to="<?=IMG_PATH?>photos/gestion/1_big.jpg" className="photoo" rel="prettyPhoto[gestion]" title="Descripcion de la foto 1">
            <img className="shadow-[0_35px_60px_-15px_rgba(0,0,0,0.8)]" src={GestionOne} alt="" />

            <p className="">
            TECXIO<sup>&reg;</sup>, a trav&eacute;s de su divisi&oacute;n GI, ofrece el servicio de tercerizaci&oacute;n de tareas cr&iacute;ticas a 
            nivel gerencial y de mandos medios altamente calificados.
            <strong> El objetivo es mejorar integralmente el funcionamiento de la empresa cliente, de modo que pueda mantener los negocios existentes y 
            generar nuevas oportunidades de negocio y crecimiento.</strong>

            <ul className="">
                <li>la calidad de los productos/ servicios ofrecidos por el Cliente</li>
                <li>el modo de relación con los clientes y proveedores (negociación, comunicación)</li>
            </ul>
            </p>
        </div>
        <p>
        TECXIO® busca de este modo, reducir los costos en términos absolutos y concretos, por la eliminación de ineficiencias y la detección y aprovechamiento de oportunidades de mejora.
        </p>

        <p>
            <strong className="bold">Servicio ofrecido:</strong> <br />
            Tercerización de tareas específicas, de alto valor agregado:<ul>
                <li>Tareas inherentes a los procesos productivos: planificación de la producción, ingeniería de procesos, ingeniería de producto, entre otros. </li>
                <li>Tareas asociadas con los procesos productivos: compras, logística interna y/o externa, gestión de inventarios, gestión de la calidad, entre otros.</li>
                <li>Tareas asociadas con la gestación o creación del producto: investigación, diseño, desarrollo, fabricación de prototipos, entre otros. </li>
            </ul>
        </p>

        <p>
        El objetivo es complementar la labor del plantel existente de la empresa cliente, en la ejecución de las tareas críticas (alto valor agregado) con el aporte de horas mensuales de trabajo de especialistas externos, sin la necesidad de incrementar la nómina de empleados (evitando la contratación fija de personal altamente calificado y costoso).
        </p>

        <p>
        La idea es abordar, con un enfoque profesional, el trabajo de la empresa tal como se está realizando, e ir optimizándolo de acuerdo con un plan de acción bien definido y consensuado con la Dirección. Es decir, gestionar desde el primer día (“apagando incendios”, si fuera necesario), eliminando en forma gradual las ineficiencias que se puedan detectar. Se busca capacitar al personal existente, brindándole los atributos necesarios para ejecutar tareas más complejas que las que realiza habitualmente.
        </p>
        
        <p className="bold">
        Con las herramientas necesarias (diseñadas a medida por un especialista), se podrá lograr que personas con formación media, realicen en forma fragmentada o distribuida, las tareas de alto valor agregado tercerizadas inicialmente para su optimización.
        </p>
       
        </div>
    </div>
</div>



       
    )
}

export default GestionIndustrial