import React from "react";
import Gestion from "../static/images/photos/diseno/1.jpg"

function Modal({img, toggleModal}){

    return(
        <div onClick={(e) => toggleModal(e)} className="h-full w-full grid place-content-center fixed top-0 left-0 bg-gray-200/50 z-10  ">
            <div className="bg-white rounded-xl p-4 w-">
                <p onClick={(e) => toggleModal(e)} className="text-end">X</p>
                <img  className="" src={img} alt="" />
            </div>
        </div>
    )
    
        
}
export default Modal;
