    import './App.css';
    
    import React from 'react';
    import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
 
import Index from './pages/en/Index.tpl';
import English from './pages/en/English';
import AboutUs from './pages/en/About-us.tpl';
import ContactUs from './pages/en/Contact-us.tpl';
import Footer from './pages/en/inc/Footer.tpl'
import Espanol from './pages/es/Espanol';
import IndexEngineeringDevelopment from './pages/en/Index-engineering-development.tpl';
import ResearchAndDevelopment from './pages/en/Research-and-development.tpl';
import Production from './pages/en/Production.tpl';
import IndustrialManagement from './pages/en/Industrial-management.tpl';
import Informatics from './pages/en/Informatics.tpl';
import Repairs from './pages/en/Repairs.tpl';
import ResearchAndBusiness from './pages/en/Research-and-business-plant.tpl';
import UnderConstruction from './pages/en/Under-construction.tpl';

import ProductsAndServices from './pages/en/Products-and-services.tpl';
import DevicesMaquineryEquipmentPieces from './pages/en/products-and-services/research-and-development/Devices-machinery-equipment-pieces.tpl';
import Submenu from './pages/en/inc/Submenu.tpl';
import FabricationAssemblyProcesses from './pages/en/products-and-services/research-and-development/Fabrication-assembly-processes.tpl';
import CalculationStructures from './pages/en/products-and-services/research-and-development/Calculation-structures.tpl'
import Reengineering from './pages/en/products-and-services/research-and-development/Re-engineering-manufacturing-assembly-processes.tpl';
import ProductionCells from './pages/en/products-and-services/research-and-development/Production-cells.tpl';
import TestingMaterialsEquipment from './pages/en/products-and-services/research-and-development/Testing-materials-equipment.tpl';
import DocumentationProductsProcesses from './pages/en/products-and-services/research-and-development/Documentation-products-processes.tpl';
 // import FeaturedEngineeringDevelopment from './pages/en/inc/Featured-engineering-development.tpl';
// import Featured from './pages/en/inc/Featured.tpl';| | 
import Modal  from "../src/pages/en/inc/Modal";
// import FeaturedEngineeringDevelopment from './pages/en/inc/Featured-engineering-development.tpl';
// import Featured from './pages/en/inc/Featured.tpl';
// import HeadIncludes from './pages/en/inc/Head.includes.tpl';
// import CalculationStructures from './pages/en/products-and-services/research-and-development/Calculation-structures.tpl';
// import FeaturedGallery from './pages/en/inc/Featured.gallery.tpl';
 
 //imports spanish

 import Contactenos from "./pages/es/Contactenos"
 import Diseñoydesarrollo from './pages/es/Diseño&Desarrollo';
 import IndexEngineeringDevelopmentEspanol from './pages/es/IndexEngineeringDevelopmentEspanol';
 import Informatica from './pages/es/Informatica';
 import InvestigacionYUsinaDeEmpresas from './pages/es/InvestigacionYUsinaDeEmpresas';
 import Produccion from './pages/es/Produccion';
 import ProyectosDeInvestigaciones from './pages/es/Proyectos-de-investigaciones';
 import QuienesSomos from './pages/es/Quienes-somos';
import Reparaciones from'./pages/es/Reparaciones'
import CalculoEstructuras from './pages/es/productos-y-servicios/diseno-y-desarrollo/Calculo-estructuras';
import CeldasProductivas from './pages/es/productos-y-servicios/diseno-y-desarrollo/Celdas-productivas';
import DispositivosMaquinariasEquiposPiezas from './pages/es/productos-y-servicios/diseno-y-desarrollo/Dispositivos-maquinarias-equipos-piezas';
import DocumentacionProductosProcesos from './pages/es/productos-y-servicios/diseno-y-desarrollo/Documentacion-productos-procesos';
import EnsayoMaterialesEquipos from './pages/es/productos-y-servicios/diseno-y-desarrollo/Ensayo-materiales-equipos';
import ProcesosFabricacionMontaje from './pages/es/productos-y-servicios/diseno-y-desarrollo/Procesos-fabricacion-montaje';
import ReingenieriaProcesosFabricacionMontaje from './pages/es/productos-y-servicios/diseno-y-desarrollo/Reingenieria-procesos-fabricacion-montaje';
import EnFormacion from './pages/es/EnFormacion';
import GestionIndustrial from './pages/es/Gestionindustrial';
import DiseñoyDesarrollo from './pages/es/Diseño&Desarrollo';


// import FeaturedEngineeringDevelopmentFr from './pages/fr/inc/Featured-engineering-development.tpl';
import Frances from './pages/fr/Frances';
import AProposDeNous from './pages/fr/A-propos-de-nous.tpl';
import ConceptionEtDeveloppement from './pages/fr/Conception-et-developpement.tpl';
import EnConstruction from './pages/fr/En-construction.tpl';
import GestionIndustrielle from './pages/fr/Gestion-industrielle.tpl';
import IndexEngineeringDevelopmentFr from './pages/fr/Index-engineering-developmentFr.tpl';
import Informatique from './pages/fr/Informatique.tpl';
import ProductionFr from './pages/fr/ProductionFr.tpl';
import ProduitsEtService from './pages/fr/Produits-et-services.tpl';
import RechercheEtusineDEntreprises from './pages/fr/Recherche-et-usine-d-entreprises.tpl';
import Reparations from './pages/fr/Reparations.tpl';
import CalculDesStructures from './pages/fr/produits-et-services/conception-et-developpement/Calcul-des-structures.tpl';
import Dispositifs from './pages/fr/produits-et-services/conception-et-developpement/Dispositifs-machines-equipement-pieces.tpl'
import DocumentationDeProduitsEtProcessus from './pages/fr/produits-et-services/conception-et-developpement/Documentation-de produits-et-processus.tpl';
import EssaisEquipementsEtDeMateriaux from './pages/fr/produits-et-services/conception-et-developpement/Essais-equipements-et-de-materiaux.tpl';
import ProcessusDeFabricationEtMontage from './pages/fr/produits-et-services/conception-et-developpement/Processus-de-fabrication-et-montage.tpl';
import ReingenierieDesProcessusDeFabricationEtMontage from './pages/fr/produits-et-services/conception-et-developpement/Reingenierie-des-processus-de-fabrication-et-montage.tpl';
import CellulesDeProduction from './pages/fr/produits-et-services/conception-et-developpement/Cellules-de-production.tpl';
import ContactezNous from './pages/fr/Contactez-nous.tpl';
import WhatsAppButton from './pages/WhatsappButton';


function App() {

  return (

    <Router>

    <Routes>
        {/* index redirecciona a english */}
        <Route path='/' element={<Index />} />

        {/* English */}
        <Route path='/english' element={<English />} />

 
 
        {/* test */}  <Route path='/english/test' element={<ReingenierieDesProcessusDeFabricationEtMontage/>} /> {/* test */}
 

        <Route path='/english/about' element={<AboutUs/>} />
        <Route path='/english/contact' element={<ContactUs/>} />

        <Route path='/english/medical-technology' element={<UnderConstruction/>} />
        
        <Route path='/english/engineering-development' element={<IndexEngineeringDevelopment/>} />
        <Route path='/english/engineering-development/research-and-development' element={<ResearchAndDevelopment/>} />
        <Route path='/english/engineering-development/production' element={<Production/>} />
        <Route path='/english/engineering-development/industrial-management' element={<IndustrialManagement/>} />
        <Route path='/english/engineering-development/informatics' element={<Informatics/>} />
        <Route path='/english/engineering-development/repairs' element={<Repairs/>} />
        <Route path='/english/engineering-development/research-and-business-plant' element={<ResearchAndBusiness/>} />
        <Route path='/english/engineering-development/products-and-services' element={<ProductsAndServices />} />
        <Route path='/english/engineering-development/research-and-development/Devices-machinery-equipment-pieces.tpl' element={<DevicesMaquineryEquipmentPieces/>} />
        <Route path='/english/engineering-development/research-and-development/Calculation-structures.tpl' element={<CalculationStructures/>} />
        <Route path='/english/engineering-development/research-and-development/Fabrication-assembly-processes.tpl' element={<FabricationAssemblyProcesses/>} />
        <Route path='/english/engineering-development/research-and-development/Re-engineering-manufacturing-assembly-processes.tpl' element={<Reengineering/>} />
        <Route path='/english/engineering-development/research-and-development/Production-cells.tpl' element={<ProductionCells/>} />
        <Route path='/english/engineering-development/research-and-development/Testing-materials-equipment.tpl' element={<TestingMaterialsEquipment/>} />
        <Route path='/english/engineering-development/research-and-development/Documentation-products-processes.tpl' element={<DocumentationProductsProcesses/>} />
        <Route path='/english/engineering-development/research-and-development/Devices-machinery-equipment-pieces.tpl' element={<DevicesMaquineryEquipmentPieces/>} />
  






        <Route path='/spanish' element={<Espanol/>} />


        <Route path='/spanish/about' element={<QuienesSomos/>} />
        <Route path='/spanish/contact' element={<Contactenos/>} />

        <Route path='/spanish/medical-technology' element={<  EnFormacion/>} />
        
        <Route path='/spanish/engineering-development' element={<IndexEngineeringDevelopmentEspanol/>} />
        <Route path='/spanish/engineering-development/research-and-development' element={<DiseñoyDesarrollo/>} />
        <Route path='/spanish/engineering-development/production' element={<Produccion/>} />
        <Route path='/spanish/engineering-development/industrial-management' element={<GestionIndustrial/>} />
        <Route path='/spanish/engineering-development/informatics' element={<Informatica/>} />
        <Route path='/spanish/engineering-development/repairs' element={<Reparaciones/>} />
        <Route path='/spanish/engineering-development/research-and-business-plant' element={<InvestigacionYUsinaDeEmpresas/>} />
         <Route path='/spanish/engineering-development/research-and-development/Devices-machinery-equipment-pieces.tpl' element={<DispositivosMaquinariasEquiposPiezas/>} />
        <Route path='/spanish/engineering-development/research-and-development/Calculation-structures.tpl' element={<CalculoEstructuras/>} />
        <Route path='/spanish/engineering-development/research-and-development/Fabrication-assembly-processes.tpl' element={<ProcesosFabricacionMontaje/>} />
        <Route path='/spanish/engineering-development/research-and-development/Re-engineering-manufacturing-assembly-processes.tpl' element={<ReingenieriaProcesosFabricacionMontaje/>} />
        <Route path='/spanish/engineering-development/research-and-development/Production-cells.tpl' element={<CeldasProductivas/>} />
        <Route path='/spanish/engineering-development/research-and-development/Testing-materials-equipment.tpl' element={<EnsayoMaterialesEquipos/>} />
        <Route path='/spanish/engineering-development/research-and-development/Documentation-products-processes.tpl' element={<DocumentacionProductosProcesos/>} />
        <Route path='/spanish/engineering-development/research-and-development/Devices-machinery-equipment-pieces.tpl' element={<DispositivosMaquinariasEquiposPiezas/>} />
  


              



        {/*  Francais */}



        <Route path='/Frances' element={<Frances/>} />


<Route path='/Frances/about' element={<AProposDeNous/>} />
<Route path='/Frances/contact' element={<ContactezNous/>} />

<Route path='/Frances/medical-technology' element={<  EnConstruction/>} />

<Route path='/Frances/engineering-development' element={<IndexEngineeringDevelopmentFr/>} />
<Route path='/Frances/engineering-development/research-and-development' element={<ConceptionEtDeveloppement/>} />
<Route path='/Frances/engineering-development/production' element={<ProductionFr/>} />
<Route path='/Frances/engineering-development/industrial-management' element={<GestionIndustrielle/>} />
<Route path='/Frances/engineering-development/informatics' element={<Informatique/>} />
<Route path='/Frances/engineering-development/repairs' element={<Reparations/>} />
<Route path='/Frances/engineering-development/research-and-business-plant' element={<RechercheEtusineDEntreprises/>} />
 <Route path='/Frances/engineering-development/research-and-development/Devices-machinery-equipment-pieces.tpl' element={<Dispositifs/>} />
<Route path='/Frances/engineering-development/research-and-development/Calculation-structures.tpl' element={<CalculDesStructures/>} />
<Route path='/Frances/engineering-development/research-and-development/Fabrication-assembly-processes.tpl' element={<ProcessusDeFabricationEtMontage/>} />
<Route path='/Frances/engineering-development/research-and-development/Re-engineering-manufacturing-assembly-processes.tpl' element={<ReingenierieDesProcessusDeFabricationEtMontage/>} />
<Route path='/Frances/engineering-development/research-and-development/Production-cells.tpl' element={<CellulesDeProduction/>} />
<Route path='/Frances/engineering-development/research-and-development/Testing-materials-equipment.tpl' element={<EssaisEquipementsEtDeMateriaux/>} />
<Route path='/Frances/engineering-development/research-and-development/Documentation-products-processes.tpl' element={<DocumentationDeProduitsEtProcessus/>} />


        {/*           <Route path='/:lang' element={<Espanol />} />
 */}

        <Route path='/:lang' element={<Espanol />} />

    </Routes>

    <Footer/>
    <WhatsAppButton/>
        

    </Router>
  
 
    
  );
}

export default App;

//formulario de contacto
//menu idiomas
