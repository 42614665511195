/*<? 
$title = 'C&aacute;lculo de Estructuras';
include_once(PAGES_PATH . 'inc/head.tpl.php'); 
include_once(PAGES_PATH . 'inc/header.tpl.php'); 
include_once(PAGES_PATH . 'inc/menu.tpl.php'); 
include_once(PAGES_PATH . 'inc/submenu.tpl.php'); 
include_once(PAGES_PATH . 'inc/featured.gallery.tpl.php'); 
?>*/

import React,{useState} from "react"
import { Link } from "react-router-dom";
import Submenu from "../../../es/inc/Submenu.tpl";
import FeaturedGallery from "../../inc/Featured.gallery.tpl";
import Footer from "../../inc/Footer.tpl";
import Head from "../../inc/Head.tpl";
import Header from "../../inc/Header.tpl";
import Menu from "../../inc/Menu.tpl";
import  DisenoNine from '../../../en/static/images/photos/diseno/9.jpg'
import Modal from "../../../en/inc/Modal";

function CalculoEstructuras () {

    const [ModalImage, setModalImage] = useState(false)
    const toggleModal = (e) => {
        e.preventDefault()
        e.stopPropagation() 
        if ( ModalImage) {
             setModalImage(false);
            } else {
                setModalImage(true);
            }
    }
    return(

 <div className="grid place-content-center w-screen" >
 <Head/>
 <Header/>
  <Submenu/>
 <FeaturedGallery/>

<div id="content">
    <div id="breadcrumbs">
       {/*} <?= printInitBreadcrums() ?>*/}
        <Link to="<?=REMOTE_PATH_DIV?>diseno-y-desarrollo.html">Servicio de Dise&ntilde;o y Desarrollo/ Ingenier&iacute;a (D+D/I)</Link> &raquo; 
        <strong>Calculo de estructuras </strong>
    </div>
    <h1>Calculo de estructuras</h1>
    <div id="leftbar">
        <h4><Link className="text-xs" to="<?=REMOTE_PATH_DIV?>productos-y-servicios.html">Productos y Servicios</Link></h4>
        <ul className="flex flex-col">
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to= "/spanish/engineering-development/research-and-development">Dise&ntilde;o y Desarrollo</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/production">Producci&oacute;n</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/industrial-management">Gesti&oacute;n Industrial</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/informatics">Informatica</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/repairs">Reparaciones</Link></li>
        </ul>
    </div>
     
    <div id="wrapper" className="flex">
        <Link className="photo left " title="Descripcion de la foto 9" rel="prettyPhoto[diseno]" to="<?=IMG_PATH?>/photos/diseno/9_big.jpg">
            <img onClick={(e) => toggleModal(e) }  alt="" src={DisenoNine}/>
            {ModalImage && <Modal img={DisenoNine} toggleModal={toggleModal} />}
        </Link>
        <p className="withphototwo  ">
        Se dise&ntilde;an y calculan estructuras est&aacute;ticas y din&aacute;micas: recipientes de presi&oacute;n, carrocer&iacute;as y fuselajes, 
            gabinetes y carenados entre otros.        </p>
    </div>

</div>

</div>
)
}

export default CalculoEstructuras ;

//<? include_once(PAGES_PATH . 'inc/footer.tpl.php'); ?>
