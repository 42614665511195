/*<? 
$title = 'Calcul des structures';
include_once(PAGES_PATH . 'inc/head.tpl.php'); 
include_once(PAGES_PATH . 'inc/header.tpl.php'); 
include_once(PAGES_PATH . 'inc/menu.tpl.php'); 
include_once(PAGES_PATH . 'inc/submenu.tpl.php'); 
include_once(PAGES_PATH . 'inc/featured.gallery.tpl.php'); 
?>*/

import React, { useState } from "react"
import { Link } from "react-router-dom";
import FeaturedGallery from "../../inc/Featured.gallery.tpl";
import Footer from "../../inc/Footer.tpl";
import Head from "../../inc/Head.tpl";
import Header from "../../inc/Header.tpl";
import Menu from "../../inc/Menu.tpl";
import Submenu from "../../inc/Submenu.tpl";
import  DisenoNine from '../../../en/static/images/photos/diseno/9.jpg'
import Modal from "../../../en/inc/Modal";


function CalculDesStructures (){

    const [ModalImage, setModalImage] = useState(false)
    const toggleModal = (e) => {
        e.preventDefault()
        e.stopPropagation() 
        if ( ModalImage) {
             setModalImage(false);
            } else {
                setModalImage(true);
            }
    }
    return(
 
   <div className="grid place-content-center w-screen">
    
    {/* <Head/> */}
    <Header/>
     <Submenu/>
    <FeaturedGallery/> 
<div id="content">
    <div id="breadcrumbs">
     {/* <?= printInitBreadcrums() ?>*/}
        <Link to="/english/engineering-development/products-and-services">Produits et Services</Link> &raquo; 
        <Link to="/english/engineering-development/research-and-development">RServices de conception et développement / ingénierie (I+D)</Link> &raquo; 
        <strong>Calcul des structures</strong>
    </div>
    <h1>Calcul des structures</h1>
    <div id="leftbar">
    <h4><Link className="text-xs" to="/english/engineering-development/products-and-services">Produits et Services</Link></h4>
    <ul className="flex flex-col">
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/research-and-development">Conception et développement</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/production">Production sur demande</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/industrial-management">Gestion Industrielle</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/informatics">Informatique</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/repairs">Réparations</Link></li>
        </ul>
    </div>
    <div id="wrapper" className="flex">
        <Link className="ml-4 drop-shadow-[0_35px_35px_rgba(0,0,0,0.5)] " title="Descripcion de la foto 9" rel="prettyPhoto[diseno]" to="<?=IMG_PATH?>/photos/diseno/9_big.jpg">
            <img onClick={(e) => toggleModal(e) }  alt="" src={DisenoNine}/>
            {ModalImage && <Modal img={DisenoNine} toggleModal={toggleModal} />}
        </Link>
        <p className="withphototwo ">
        On développe et on calcule des structures statiques et dynamiques: appareils à pression, corps et fuselages, armoires et carénages.        
        </p>
    </div>

</div>

{/* <Footer/> */}

</div>
)
}

export default CalculDesStructures;

//<? include_once(PAGES_PATH . 'inc/footer.tpl.php'); ?>



 