/*<? 
include_once(PAGES_PATH . 'inc/head.tpl.php'); 
include_once(PAGES_PATH . 'inc/header.tpl.php'); 
include_once(PAGES_PATH . 'inc/menu.tpl.php'); 
include_once(PAGES_PATH . 'inc/submenu.tpl.php'); 
include_once(PAGES_PATH . 'inc/featured-engineering-development.tpl.php'); 
?>*/


import React from "react"

import { Link } from "react-router-dom";
import Head from "../fr/inc/Head.includes.tpl";
import Header from "./inc/Header.tpl";
import Menu from "../fr/inc/Menu.tpl";
import Submenu from "../fr/inc/Submenu.tpl";
 import Footer from "../fr/inc/Footer.tpl";
import FeaturedGallery from "../fr/inc/Featured.gallery.tpl";
import FeaturedEngineeringDevelopmentFr from "./inc/Featured-engineering-development.tpl";

function IndexEngineeringDevelopmentFr(){

    return(

        <div className="grid place-content-center">
        {/* <Head/> */}
        <Header/>
        <Submenu/>
         <FeaturedEngineeringDevelopmentFr/>

<div  id="content" className="container pt-3 mx-auto w-64">
    <p >
        TECXIO<sup>&reg;</sup> a été créé par un groupe de professionnels spécialisés dans des différentes branches de l'industrie 
        (mécanique, logiciels, électronique et aéronautique) avec la vision de fusionner des connaissances techniques 
        précises grâce à une approche généraliste, débouchant sur un processus de synérgie qui permet la création de produits 
        et services à haute valeur ajoutée pour nos clients.
    </p>
</div>
 </div>
)
}

export default IndexEngineeringDevelopmentFr ;