// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBqajg7Dpd_-kXsJBN-07cBxiKr8OoLv4Y",
  authDomain: "tecxio.firebaseapp.com",
  projectId: "tecxio",
  storageBucket: "tecxio.appspot.com",
  messagingSenderId: "149386978248",
  appId: "1:149386978248:web:d83deb69b2a8100bdd56fd"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;