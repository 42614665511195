import React, { useRef, useState } from "react";
import Header from "./inc/Header.tpl";
import Menu from "./inc/Menu.tpl";
import ReCAPTCHA from "react-google-recaptcha";
 
import FeaturedGallery from "./inc/Featured.gallery.tpl";
import { collection, addDoc } from 'firebase/firestore'
import db from '../../firebase'
import { useNavigate, Link } from 'react-router-dom'
import Submenu from "./inc/Submenu.tpl";
import { useEffect } from "react";
 
export default function ContactUs(){
    const [usuarioValido, cambiarUsuarioValido] = useState(false);
    const [ datetime, setDatetime ] = useState('');
    const captcha = useRef(null);
  
  
    const onChange = () => {
      if (captcha.current.getValue()){
        cambiarUsuarioValido(true)
      } 
    }

    const navigate = useNavigate();

    const onFormSubmit = async (e) => {
        e.preventDefault();

        const name = e.target[0].value;
        const email = e.target[1].value;
        const company = e.target[2].value;
        const subject = e.target[3].value;
        const msg = e.target[5].value;
        const payload = {name, email, company, subject, msg }

        const docRef = collection(db, 'consultas')
        await addDoc(docRef, payload)
        await addDoc(docRef, payload)
        .then(res => console.log(res))
        .catch(err => console.log(err))
        alert('message successfully sent')

        navigate('/english')
    }


    useEffect(() => {
        let today = new Date()
        let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        setDatetime(`${date} | ${time}`)
    }, [])

    return(

<div className="grid place-content-center w-screen">
<Header/>
<Submenu/>
 <FeaturedGallery/>
 

  <div id="content">
    <div id="breadcrumbs">
         <Link to="/english">TECXIO</Link><strong> {`>`} Contact us</strong>
    </div>
    <h1 className="text-center pb-2">Contact Us</h1>
    
        <form  onSubmit={(e) => onFormSubmit(e)} >
            <table className="mx-auto">
                <tr>
                    <td>
                        <table>
                            <tr>
                                <td>
                                    <p className="">
                                    	<strong className="color:#666">Name</strong>: <br />
                                    	<input type="text" name="names" className="w-[185px]" />
                                    </p>
                                </td>
                                <td>
                                    <p className="">
                                    	<strong className="color:#666">E-mail</strong>: <br /> 
                                    	<input type="text" name="email" className="w-[185px]"/>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className="">
                                    	<strong className="color:#666">Company</strong>: <br /> 
                                    	<input type="text" name="company" className="w-[185px]"/>
                                    </p>
                                </td>
                                <td>
                                    <div>
                                        <p id="subject" className="">
                                            <strong className="color:#666 ">Subject</strong>: <br />
                                        <select name="subject" className="w-[185px] h-[42.4px]">
                                                <option value="">[Select]</option>
                                                <option value="Pedido de cotizaci&oacute;n"   >Request a quote</option>
                                                <option value="Consulta t&eacute;cnica"  >Technical consultation</option>
                                                <option value="Consulta administrativa"  >Administrative consultation</option>
                                                <option value="Participaci&oacute;n en foros"  >Forum participation</option>
                                                <option value="Propuestas de negocio"  >Business proposal</option>
                                                <option value="Otros"  >Others</option>
                                            </select>
                                        </p>
                                        <div>
                                            <p id="subject2" className="hidden">
                                                <strong className="color:#666">Subject</strong><br />
                                                <input type="text" name="subject2" className="w-[185px]" /><br />
                                            </p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="h-6 pt-1">
                                    <strong className="text-sm text-gray-700 ml-3">Date and time (GTM-3): </strong><p className="pl-1">{datetime}</p>
                                </td>
                            </tr>
                        </table>

                        <div className="clear:left; float:left">
                            <p className="<?php echo addErrorClassOn($errorOn, 'message'); ?>">
                            	<strong className="color:#666">Message</strong>: <br /> 
                            	<textarea name="message" rows="6" cols="52" className="w-[390px]" > </textarea>
                            </p>
                            
                  
                        </div>
                    </td>
                </tr>
            </table>
            <div className="w-full grid place-content-center">


            <div className='self-center pb-2'>

               <ReCAPTCHA 
                        ref={captcha} 
                        sitekey="6LdQdBAiAAAAALi4gI8UH1X1NQ-GIUjI97e1i0zS"
                        onChange={onChange} 
                         
                          />,

          {!usuarioValido ? <div className='text-center text-gray-600'>Completa el recaptcha para continuar</div> : <button type="submit"  className="h-10 bg-blue-200 px-4 py-2 rounded-sm mb-6 shadow-lg text-sm"><strong>Send</strong></button>
            }

            </div>



            
            </div>
        </form>
    
</div> 


</div>
)
}