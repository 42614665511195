import React from "react"
import { Link } from "react-router-dom"
import Footer from "./inc/Footer.tpl"
import Head from "./inc/Head.tpl";
import Header from "./inc/Header.tpl";
import Menu from "../en/inc/Menu.tpl";
import FeaturedGallery from "./inc/Featured.gallery.tpl";
import Submenu from "./inc/Submenu.tpl";

function ProductsAndServices (){
    return(
            <div className="grid place-content-center w-screen">
            <Head/>
            <Header/>
            <Submenu/>
            <FeaturedGallery/>

            <div id="content">
                <div id="breadcrumbs">
                    <Link to={'/english'}>TECXIO  </Link>{` > `}<Link to={'/english/engineering-development'}>Engineering development   </Link>{` > `}<strong>Products and services</strong>
                </div>
                <h1>Products and Services</h1>
                <div id="leftbar">
                    <h4><Link className="text-xs" to="/english/engineering-development/products-and-services">Products and Services</Link></h4>
                    <ul className="flex flex-col">
                        <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/research-and-development">Research and Development</Link></li>
                        <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/production">Production</Link></li>
                        <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/industrial-management">Industrial Management</Link></li>
                        <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/informatics">Informatics</Link></li>
                        <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/repairs">Repairs</Link></li>
                    </ul>
                </div>
                <div id="wrapper">
                    <p>
                    TECXIO<sup>®</sup> has been created by a group of professionals specialized in different branches of industry (metal-mechanics, software, electronics and aeronautics) with the vision of combining together very specific technical knowledge through a generalist approach, resulting in a synergetic process that allows the creation of products and services with high added value for our customers.
                    </p>
                </div>    
            </div>
            
            </div>
    )
} 

export default ProductsAndServices