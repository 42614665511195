import React from "react"
import { Link } from 'react-router-dom';
import DesarrolloIngenieria from "../../en/static/images/thumbs/desarrollo-ingenieria.jpg"
import MedicalTechnology from "../../en/static/images/thumbs/tec-medica.jpg"

function Featured (){

    return(




<div className="featured big divisiones">
        <div className="diseno box">
            <Link to="/frances/engineering-development">
                <img src={DesarrolloIngenieria} alt="Engineering Development" />
                <h2>Développement de l'ingénierie</h2>
            </Link>
        </div>
        <div className="produccion box">
            <Link to="/frances/medical-technology">
                <img src={MedicalTechnology} alt="Medical Technology" />
                <h2>Technologie Médicale</h2>
            </Link>
        </div>

        <link rel="stylesheet" href="#" />
</div>

    )}

    export default Featured;