import React from "react"
import { Link } from "react-router-dom"
import FeaturedGallery from "../../inc/Featured.gallery.tpl";
import Footer from "../../inc/Footer.tpl";
import Header from "../../inc/Header.tpl";
import Menu from "../../inc/Menu.tpl";
import TabsRender from "../../../en/products-and-services/DinamicTabNav";
import DinTab from "./DinTab";
import Submenu from "../../inc/Menu.tpl";

function Dispositifs(){
    return(
        <div className="grid place-content-center w-screen">
        <Header/>
        <Submenu/>
        <FeaturedGallery/>

  

<div id="content">
    <div id="breadcrumbs">
        <Link to="/english/engineering-development/products-and-services">Produits et Services</Link> &raquo; 
        <Link to="/english/engineering-development/research-and-development">Research and Development</Link> &raquo; 
        <strong>Conception de dispositifs, machines, équipement et pièces</strong>
    </div>
    <h1>Conception de dispositifs, machines, équipement et pièces </h1>
    <div id="leftbar">
        <h4><Link className="text-xs" to="/english/engineering-development/products-and-services">Produits et Services</Link></h4>
        <ul className="flex flex-col">
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/research-and-development">Conception et développement</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/production">Production sur demande</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/industrial-management">Gestion Industrielle</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/informatics">Informatique</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/repairs">Réparations</Link></li>
        </ul>
    </div>
    <div id="wrapper">

        <DinTab/> 
        
    </div>
</div>

{/* <Footer/> */}

</div>
)
}

export default Dispositifs;



 