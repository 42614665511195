import React , { useEffect, useState } from "react"
import { Link } from "react-router-dom";

function Submenu (){

  const [isOpen, setisOpen] = useState(true);
  const toggleSubmenu = (e) => {
       console.log("submenu")
      if (isOpen) {
          setisOpen(false);
      } else if (!isOpen) {
          setisOpen(true);
      }
  } 


  const [isOpenRight, setisOpenRight] = useState(true);
  const toggleSubmenuRight = (e) => {
       console.log("submenu")
      if (isOpenRight) {
          setisOpenRight(false);
      } else if (!isOpenRight) {
          setisOpenRight(true);
      }
  } 
    return (



<div className="grid place-content-center">

 
  <div className="menubg   flex  flex-start  flex-col md:flex-row items-start">
    <nav className="nav-menu font-sm     flex-wrap items-start text-sm ">
      <Link   to="/frances" className="mr-5     ">Home</Link>
      <Link  to=""  className="dropdownName"  onClick={(e) => toggleSubmenu()} >Produits et Services</Link>
      {!isOpen && 
       
      <div id="submenu" className=" " > 
    <div className="productos submenu  ">
        <ul className="left">
            <li>
                <p clasName="p-name"title="Research and Development" onClick={(e) => toggleSubmenuRight(e)}  > <span  to="/Frances/engineering-development/research-and-development" >Conception et développement</span></p>
             <div className="dropdowntwo">
             {!isOpenRight && 
        <ul className='bg-slate-200 absolute text-start -top-[175px] -mt-0.5 -left-4'>
            <li><Link to="/Frances/engineering-development/research-and-development/Devices-machinery-equipment-pieces.tpl" >Conception de dispositifs, machines, équipement et pièces</Link></li>
            <li><Link to="/Frances/engineering-development/research-and-development/Calculation-structures.tpl">Calcul des structures</Link></li>
            <li><Link to="/Frances/engineering-development/research-and-development/Fabrication-assembly-processes.tpl">Processus de Fabrication et Montage </Link></li>
            <li><Link to="/Frances/engineering-development/research-and-development/Re-engineering-manufacturing-assembly-processes.tpl">Reingénierie des Processus de Fabrication et Montage</Link></li>
            <li><Link to="/Frances/engineering-development/research-and-development/Production-cells.tpl">Cellules de production "clé en main"</Link></li>
            <li><Link to="/Frances/engineering-development/research-and-development/Testing-materials-equipment.tpl">Essais d´équipements et de matériaux</Link></li>
            <li><Link to="/Frances/engineering-development/research-and-development/Documentation-products-processes.tpl">Documentation de Produits et Processus</Link></li>
        </ul>
        
        }
                 </div>   
            </li>
         
            <li><Link className="produccion" to="/Frances/engineering-development/Production" title="Production">Production</Link></li>
            <li><Link className="gestion" to="/Frances/engineering-development/Industrial-management" title="Industrial Management">Industrial Management</Link></li>
            <li><Link className="informatica" to="/Frances/engineering-development/Informatics" title="Informatics">Informatics</Link></li>
            <li><Link className="reparaciones" to="/Frances/engineering-development/Repairs" title="Repairs">Repairs</Link></li>
            
  R    </ul>
  R  
        </div>
</div>
}
      <Link to="/Frances/engineering-development/research-and-business-plant"  className="mr-5 hover:text-gray-900">Recherche - Usine d’ Entreprises</Link>
      <Link  to="/Frances/about"  className="mr-5 hover:text-gray-900">A propos de nous</Link>
      <Link to="/Frances/contact"  className="mr-5 hover:text-gray-900">Contactez-nous</Link>
       
       
    </nav>
  </div>
{/*<div class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
    <div class="py-1" role="none">
       <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">Account settings</a>
      <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-1">Support</a>
      <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2">License</a>
      <form method="POST" action="#" role="none">
        <button type="submit" class="text-gray-700 block w-full text-left px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-3">Sign out</button>
      </form>
    </div>
  </div>*/}




 </div>
   
   
   
   
   )}


 

 

 
export default Submenu;
