import React, { useState } from "react";
 import { Link } from 'react-router-dom'
 import Img from "../../../en/static/images/photos/diseno/2.jpg"
 import Imgtwo from "../../../en/static/images/photos/diseno/7.jpg"
import Imgthree from "../../../en/static/images/photos/diseno/5.jpg"



const Tabs = () => {
  const [openTab, setOpenTab] = useState(1);
  const color = 'blue'
  return (
    <>
      <div className="flex flex-wrap list-none bg-[#F1F1F1]">
        <div className="w-full list-none bg-[#F1F1F1]">
          <div
            className="flex mb-0 list-none flex-wrap flex-row "
            style={{borderBottom: "2px solid black"}}
            role="tablist"
          >
            <p className="text-lg list-none"
            style={{margin: "0"}}
            >
            
              <a
              style={{border: "1px solid black", padding: '6px', borderRadius: '6px 6px 0 0', marginLeft: '2px'}}
                className={
                  "list-none" +
                  (openTab === 1
                    ? "text-white bg-" + color + "-200"
                    : "text-" + color + "-600 bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                <i className="fas fa-space-shuttle text-base mr-1 list-none"></i>Sistemas Mecanicos
              </a>
            </p>
            <p className="" style={{margin: "0"}}>
              <a
              style={{border: "1px solid black", padding: '6px', borderRadius: '6px 6px 0 0', marginLeft: '2px'}}
                className={
                  "" +
                  (openTab === 2
                    ? "text-white bg-" + color + "-200"
                    : "text-" + color + "-600 bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                <i className="fas fa-cog text-base mr-1"></i>  Sistemas neumáticos e hidráulicos
              </a>
            </p>
            <p className="" style={{margin: "0"}}>
              <a
              style={{border: "1px solid black", padding: '6px', borderRadius: '6px 6px 0 0', marginLeft: '2px'}}
                className={
                  "" +
                  (openTab === 3
                    ? "text-white bg-" + color + "-200"
                    : "text-" + color + "-600 bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(3);
                }}
                data-toggle="tab"
                href="#link3"
                role="tablist"
              >
                <i className="fas fa-briefcase text-base mr-1"></i> Sistemas electrónicos
              </a>
            </p>
          </div>
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-[#F1F1F1]">
            <div className="px-4 py-5 flex-auto">
              <div className="tab-content tab-space">
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                <div id="mecanicos">
                <h2>Sistemas mecánicos</h2>
                <div className="photo left" title="Descripcion de la foto 2" rel="prettyPhoto[diseno]" to="<?=IMG_PATH?>/photos/diseno/2_big.jpg">
                    <img alt="" src={Img} className="mr-2 -ml-3" />
                </div>
                <p className="withphotoTwo ">
                Los principales diseños incluyen mecanismos de accionamiento, control y transmisión; utillajes y calibres; embalajes especiales; conjuntos y piezas a pedido, como válvulas, equipos de laboratorio (micromanipuladores, calorímetros de masa); roscas a medida (internas y externas); piezas de acero o aluminio con soldadura TIG calificada y certificada.                </p>
                <br className="clear" />
                <p>
                Para el diseño se utilizan: metales comunes como el acero, cobre, bronce, aluminio, metales especiales como el acero inoxidable de distinta composición, titanio grado 5, tungsteno, Monel k500 y otras aleaciones complejas ferrosas y no ferrosas, no metales como Nylon, Teflon, Grilon, resina acetalica, materiales compuestos con matriz de polímeros como resinas de base epoxi, poliéster o poliuretánica, con fibra de vidrio, fibra de carbono o poliamidas especiales como el Kevlar, materiales compuestos con matriz metálica y compuestos cerámicos.                </p>
                <p>
                Se trabaja con perfiles, barras, chapas, rodamientos, rótulas, bujes, poleas, cadenas, resortes, realizando diseños a medida o adaptaciones cuando es necesario.

</p>
                <br className="clear" />
            </div>
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                <div id="neumaticos">
                <h2 className="">Sistemas neumáticos e hidráulicos</h2>
                    <img alt="" className="left ml-3 mr-8" src={Imgtwo}/>
                {/* <div className="photo left" title="Descripcion de la foto 7" rel="prettyPhoto[diseno]" to="<?=IMG_PATH?>/photos/diseno/7_big.jpg">
                </div> */}
                <p className="ml">
                Los diseños utilizan gases tales como argón, nitrógeno, helio, oxígeno, aire, entre otros y fluídos como aceites minerales, vegetales y sintéticos, agua, productos químicos diversos, combustibles, elementos altamente corrosivos entre otros.Se diseñan sistemas de control y transmisión / amplificación/ reducción, de potencia para máquinas y motores.               </p>

                {/* <p className="withphotoTwo">
                Control and transmission / amplification / reduction of power systems for machines and engines are designed.
                </p> */}
                <br className="clear" />
            </div>
                </div>
                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                <div id="">
               <h2 className="relative left-5">Sistemas electronicos</h2>
                
                    <img alt="" className=" ml-8 left mr-8" src={Imgthree}/>
                <p className="withphotoTwo">
                Diseño y programación de equipos y dispositivos de control dedicados: pequeñas computadoras, sensores, tableros de mando y control, sistemas de comunicación/control inalámbrico (GPRS/3G, WiFi, Bluetooth) o bien a través de cableados especiales (CAN BUS), desarrollos en J2ME para celulares. Monitoreo de alarmas, supervisión remota, seguimiento vehicular, seguimiento de personas, sistemas de supervisión para procesos de producción: SCADAS, domótica: casas inteligentes supervisadas por Internet.                </p>
                <ul>
                    <li>Adaptación y programación de interfaces de usuario: touch screen, interfaz USB (para microcontroladores), dispositivos de realidad virtual como cascos, guantes, dispositivos especiales, teclados programables, lectores.</li>
                    <li>Programación de sistemas embebidos: desarrollo de file system, utilización de Compact flash y SD.</li>
                </ul>
            </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default function TabsRender() {
  return (
    <>
      <Tabs color="pink" />
    </>
  );
}













// import React, { useState } from "react";
// import { Link } from 'react-router-dom'
// import Img from "../../../en/static/images/photos/diseno/2.jpg"
// import Imgtwo from "../../../en/static/images/photos/diseno/7.jpg"
// import Imgthree from "../../../en/static/images/photos/diseno/5.jpg"


// const Tabs = () => {
//   const [openTab, setOpenTab] = useState(1);
//   const color = 'blue'
//   return (
//     <>
//       <div className="flex flex-wrap list-none bg-[#F1F1F1]">
//         <div className="w-full list-none bg-[#F1F1F1]">
//           <div
//             className="flex mb-0 list-none flex-wrap flex-row "
//             style={{borderBottom: "2px solid black"}}
//             role="tablist"
//           >
//             <p className="text-lg list-none"
//             style={{margin: "0"}}
//             >
            
//               <a
//               style={{border: "1px solid black", padding: '6px', borderRadius: '6px 6px 0 0', marginLeft: '2px'}}
//                 className={
//                   "list-none" +
//                   (openTab === 1
//                     ? "text-white bg-" + color + "-200"
//                     : "text-" + color + "-600 bg-white")
//                 }
//                 onClick={e => {
//                   e.preventDefault();
//                   setOpenTab(1);
//                 }}
//                 data-toggle="tab"
//                 href="#link1"
//                 role="tablist"
//               >
//                 <i className="fas fa-space-shuttle text-base mr-1 list-none"></i> Sistemas mecánicos

//               </a>
//             </p>
//             <p className="" style={{margin: "0"}}>
//               <a
//               style={{border: "1px solid black", padding: '6px', borderRadius: '6px 6px 0 0', marginLeft: '2px'}}
//                 className={
//                   "" +
//                   (openTab === 2
//                     ? "text-white bg-" + color + "-200"
//                     : "text-" + color + "-600 bg-white")
//                 }
//                 onClick={e => {
//                   e.preventDefault();
//                   setOpenTab(2);
//                 }}
//                 data-toggle="tab"
//                 href="#link2"
//                 role="tablist"
//               >
//                 <i className="fas fa-cog text-base mr-1"></i>  Sistemas neumáticos e hidráulicos

//               </a>
//             </p>
//             <p className="" style={{margin: "0"}}>
//               <a
//               style={{border: "1px solid black", padding: '6px', borderRadius: '6px 6px 0 0', marginLeft: '2px'}}
//                 className={
//                   "" +
//                   (openTab === 3
//                     ? "text-white bg-" + color + "-200"
//                     : "text-" + color + "-600 bg-white")
//                 }
//                 onClick={e => {
//                   e.preventDefault();
//                   setOpenTab(3);
//                 }}
//                 data-toggle="tab"
//                 href="#link3"
//                 role="tablist"
//               >
//                 <i className="fas fa-briefcase text-base mr-1"></i> Sistemas electrónicos

//               </a>
//             </p>
//           </div>
//           <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-[#F1F1F1]">
//             <div className="px-4 py-5 flex-auto">
//               <div className="tab-content tab-space">
//                 <div className={openTab === 1 ? "block" : "hidden"} id="link1">
//                 <div id="mecanicos">
//                 <h2>Sistemas mecanicos</h2>
//                  <div className="photo left" title="Descripcion de la foto 2" rel="prettyPhoto[diseno]" to="<?=IMG_PATH?>/photos/diseno/2_big.jpg">
//                 </div> 
//                     <img alt=""   src={Img} className="mr-2"/>
//                 <p className="withphotoTwo">
//                 Los principales diseños incluyen mecanismos de accionamiento, control y transmisión; utillajes y calibres; embalajes especiales; conjuntos y piezas a pedido, como válvulas, equipos de laboratorio (micromanipuladores, calorímetros de masa); roscas a medida (internas y externas); piezas de acero o aluminio con soldadura TIG calificada y certificada.                </p>
//                 <br className="clear" />
//                 <p>
//                 Para el diseño se utilizan: metales comunes como el acero, cobre, bronce, aluminio, metales especiales como el acero inoxidable de distinta composición, titanio grado 5, tungsteno, Monel k500 y otras aleaciones complejas ferrosas y no ferrosas, no metales como Nylon, Teflon, Grilon, resina acetalica, materiales compuestos con matriz de polímeros como resinas de base epoxi, poliéster o poliuretánica, con fibra de vidrio, fibra de carbono o poliamidas especiales como el Kevlar, materiales compuestos con matriz metálica y compuestos cerámicos.                </p>
//                 <p>
//                 Se trabaja con perfiles, barras, chapas, rodamientos, rótulas, bujes, poleas, cadenas, resortes, realizando diseños a medida o adaptaciones cuando es necesario.                </p>
//                 <br className="clear" />
//             </div>
//                 </div>
//                 <div className={openTab === 2 ? "block" : "hidden"} id="link2">
//                 <div id="neumaticos">
//                 <h2>Sistemas neumáticos e hidráulicos</h2>
//                 {/* <Link className="photo left" title="Descripcion de la foto 7" rel="prettyPhoto[diseno]" to="<?=IMG_PATH?>/photos/diseno/7_big.jpg">
//                 </Link> */}
//                     <img alt="" src={Imgtwo}/>
//                 <p className="withphoto">
//                 Los diseños utilizan gases tales como argón, nitrógeno, helio, oxígeno, aire, entre otros y fluídos como aceites minerales, vegetales y sintéticos, agua, productos químicos diversos, combustibles, elementos altamente corrosivos entre otros.               </p>

//                 <p className="withphoto">
//                 Se diseñan sistemas de control y transmisión / amplificación/ reducción, de potencia para máquinas y motores.                </p>
//                 <br className="clear" />
//             </div>
//                 </div>
//                 <div className={openTab === 3 ? "block" : "hidden"} id="link3">
//                 <div id="electronicos">
//                <h2>Sistemas electrónicos</h2>
//                 {/* <Link className="photo left" title="Descripcion de la foto 5" rel="prettyPhoto[diseno]" to="<?=IMG_PATH?>/photos/diseno/5_big.jpg">
//                 </Link> */}
//                     <img alt="" src={Imgthree}/>
//                 <p className="withphoto">
//                 Diseño y programación de equipos y dispositivos de control dedicados: pequeñas computadoras, sensores, tableros de mando y control, sistemas de comunicación/control inalámbrico (GPRS/3G, WiFi, Bluetooth) o bien a través de cableados especiales (CAN BUS), desarrollos en J2ME para celulares. Monitoreo de alarmas, supervisión remota, seguimiento vehicular, seguimiento de personas, sistemas de supervisión para procesos de producción: SCADAS, domótica: casas inteligentes supervisadas por Internet.                </p>
//                 <ul>
//                     <li>Adaptación y programación de interfaces de usuario: touch screen, interfaz USB (para microcontroladores), dispositivos de realidad virtual como cascos, guantes, dispositivos especiales, teclados programables, lectores.</li>
//                     <li>Programación de sistemas embebidos: desarrollo de file system, utilización de Compact flash y SD.</li>
//                 </ul>
//             </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default function TabsRender() {
//   return (
//     <>
//       <Tabs color="pink" />
//     </>
//   );
// }
