


/*<? 
$title = 'About Us';
include_once(PAGES_PATH . 'inc/head.tpl.php'); 
include_once(PAGES_PATH . 'inc/header.tpl.php'); 
include_once(PAGES_PATH . 'inc/menu.tpl.php'); 
include_once(PAGES_PATH . 'inc/submenu.tpl.php'); 
include_once(PAGES_PATH . 'inc/featured.gallery.tpl.php'); 
?>
*/

import React from "react"
import { Link } from "react-router-dom";
import Head from "../fr/inc/Head.includes.tpl";
import Header from "../fr/inc/Header.tpl";
import Menu from "../../pages/fr/inc/Menu.tpl";
import Submenu from "../fr/inc/Submenu.tpl";
 import FeaturedGallery from "../../pages/fr/inc/Featured.gallery.tpl";
import Footer from "./inc/Footer.tpl";

function AProposDeNous (){
    return(

        
        <div id="header" className="header mx-auto max-w-screen ">
        <Head/>
        <Header/>
        <Menu/>
        <FeaturedGallery/>
  
<div id="content">
    <div id="breadcrumbs">
         {/* <?= printInitBreadcrums() ?>*/}<strong>{/*<?=$title?>*/}</strong>
    </div>
    <h1 className="font-bold m-4 mt-4">A propos de nous</h1>
    <p>
    TECXIO<sup>®</sup> a été créé par un groupe de professionnels spécialisés dans les différentes branches de l'industrie (mécanique, logiciels, électronique, aéronautique, des dispositifs médicaux et in vitro) avec la vision de fusionner des connaissances techniques très précises grâce à une approche généraliste, débouchant sur un processus de synérgie qui permet la création de produits et de services à haute valeur ajoutée pour nos clients.
    </p>
    <p>
    Les partenaires et les administrateurs TECXIO<sup>®</sup> ont travaillé dans des entreprises grandes, moyennes et petites, des sociétés holdings, associations et des organismes gouvernementaux, menant des projets importants et occupant des postes de direction dans les domaines de la conception et le développement, l'ingénierie de produits, de processus d'ingénierie, production, Qualité, Maintenance, Achats, Logistique, Commerce Extérieur et Technologie Médicale. Cela permet d'aborder le travail avec une approche qui couvre tous les facteurs impliqués dans la conception et la fabrication de produits/ services à succès: un produit/ service doit répondre aux exigences de fonctionnalité et d'esthétique, mais elle doit aussi pouvoir être «fabriqué»/ créé du point de vue technique (procédés de fabrication, méthode scientifique et de gestion de la qualité) et économiques (leurs composants et matériaux doivent pouvoir être achetés à des prix raisonnables sur le marché, de sorte que le coût des produits finis, soient compatibles avec le prix du marché, ou avec la possibilité de réaliser des projets).
     </p>
     <p>
    TECXIO<sup>®</sup> peut être définie comme une entreprise de la "Troisième Vague" en utilisant le terme inventé par Alvin Toffler <sup>(1)</sup> (conseilleur auprès des présidents, des premiers ministres et des dirigeants du monde <sup>(2)</sup>), puisque sa structure est très faible en termes de l'escouade employé directement, mais a un grand nombre de professionnels associés qui sont convoqués pour des projets spécifiques. Les équipes de travail ainsi conformés, peuvent opérer à distance, en se  servant de différents outils informatiques. Ce mode de travail permet de réunir les meilleurs experts (choisis spécialement pour chaque projet), indépendamment de leur emplacement géographique. Ainsi, il est possible de minimiser les coûts en attribuant à chaque projet, seules les heures de dévouement, sans aucun frais indirect associé à l'entretien de la structure de l'entreprise (puisque ces coûts, sont réduits).
    </p>
    <p>  
    TECXIO<sup>®</sup>  Développement de l'ingénierie est une Division qui est centrée sur la conception et le développement des produits de haute valeur ajoutée et des services dans le domaine de l'ingénierie, ayant à la fois, des moyens et des capacités de production très complexes pour fabriquer des pièces uniques, des prototypes et des petites séries de production ou de gérer la production de grandes séries dans des usines productives associées.
    </p>
    <p>
    TECXIO<sup>®</sup> Engineering Development is a Division that is focused on the design and development of high added value products and services in the engineering field, yet has the resources and productive capacity to produce highly complex unique pieces, prototypes and small series or manage the production of big series in associated productive plants.
    </p>
    <p>
    <small><sup>(1)</sup>Toffler Alvin. Third Wave. 2nd Edition.Plaza &amp; Jan&eacute;s, 1984.<br />
    <sup>(2)</sup>Biographie et informations spécifiques sur <a href="https://www.alvintoffler.net/?fa=bios" rel="nofollow">https://www.alvintoffler.net/?fa=bios</a>
    </small>
    </p>
</div>
{/* <Footer/> */}
 </div>
)
}

export default AProposDeNous;

//<? include_once(PAGES_PATH . 'inc/footer.tpl.php'); ?>

