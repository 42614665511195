/*<? 
$title = 'Design of fabrication and assembly processes';
include_once(PAGES_PATH . 'inc/head.tpl.php'); 
include_once(PAGES_PATH . 'inc/header.tpl.php'); 
include_once(PAGES_PATH . 'inc/menu.tpl.php'); 
include_once(PAGES_PATH . 'inc/submenu.tpl.php'); 
include_once(PAGES_PATH . 'inc/featured.gallery.tpl.php'); 
?>
*/

import React from "react"
import { Link } from "react-router-dom"
import FeaturedGallery from "../../inc/Featured.gallery.tpl";
import Footer from "../../inc/Footer.tpl";
import Head from "../../inc/Head.tpl";
import Header from "../../inc/Header.tpl";
import Menu from "../../inc/Menu.tpl";
import Submenu from "../../inc/Submenu.tpl";

function ProcessusDeFabricationEtMontage (){
    return (


        <div className="grid place-content-center w-screen">
        {/* <Head/> */}
        <Header/>
        <Submenu/>
        <FeaturedGallery/>


<div id="content">
    <div id="breadcrumbs">
        {/*<?= printInitBreadcrums() ?>*/}
 
        <Link to="/english/engineering-development/products-and-services">Produits et Services</Link> &raquo; 
        <Link to="/english/engineering-development/research-and-development">Services de conception et développement / ingénierie (I+D)</Link> &raquo; 
         <strong>Processus de Fabrication et Montage</strong>
    </div>
    <h1>Processus de Fabrication et Montage</h1>
    <div id="leftbar">
    <h4><Link className="text-xs" to="/english/engineering-development/products-and-services">Produits et Services</Link></h4> 
    <ul className="flex flex-col">
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/research-and-development">Conception et développement</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/production">Production sur demande</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/industrial-management">Gestion Industrielle</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/informatics">Informatique</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/repairs">Réparations</Link></li>
        </ul>
    </div>
    <div id="wrapper">
        <p>
        On développe des processus continus, non continues et flexibles lies à la mécanisation des pièces d'usinage simples et complexes, à l'équipement manuel, semi-automatiques et CNC (y compris la fabrication de fils spéciaux), au soudage TIG qualifiés et certifiés, à la fabrication de matériaux composites, aux lignes d'assemblage, aux systèmes "poka yoke" (ou l'erreur d'essai) parmi d´autres.
        </p>
    </div>
</div>

{/* <Footer/> */}

</div>
)
}

export default ProcessusDeFabricationEtMontage


//<? include_once(PAGES_PATH . 'inc/footer.tpl.php'); ?>
 
 