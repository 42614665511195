/*<? 
$title = 'About Us';
include_once(PAGES_PATH . 'inc/head.tpl.php'); 
include_once(PAGES_PATH . 'inc/header.tpl.php'); 
include_once(PAGES_PATH . 'inc/menu.tpl.php'); 
include_once(PAGES_PATH . 'inc/submenu.tpl.php'); 
include_once(PAGES_PATH . 'inc/featured.gallery.tpl.php'); 
?>
*/

import React from "react"
import Head from "./inc/Head.tpl"
import Header from "./inc/Header.tpl";
import Menu from "./inc/Menu.tpl";
import Submenu from "./inc/Submenu.tpl";
import FeaturedGallery from "./inc/Featured.gallery.tpl";
import Footer from "./inc/Footer.tpl";

function AboutUs (){
    return(
 
        <div className="flex flex-col justify-center items-center w-screen">
 

            <Header/>
            <Submenu/>
            <FeaturedGallery/>
  
            <div id="content">
                <div id="breadcrumbs">
                    <a href="/english">TECXIO</a><strong> {`>`} About us</strong>
                </div>
                <h1>About Us</h1>
                <p>
                TECXIO<sup>®</sup> has been created by a group of professionals specialized in different branches of industry (metal-mechanics, software, electronics, aeronautics, medical devices and in vitro diagnostics) with the vision of combining together very specific technical knowledge through a generalist approach, resulting in a synergetic process that allows the creation of products and services with high added value for our customers.
                </p>
                <p>
                The partners and directors of TECXIO<sup>®</sup> have worked in large, medium and small business companies, holding companies, associations and government agencies, leading major projects and managing areas of Design and Development, Product Engineering, Process Engineering, Production, Quality, Maintenance, Purchasing, Logistics, Foreign Trade and Medical Technology. This allows a comprehensive approach covering all the factors involved in the design and manufacture/ creation of successful products and services: a product/ service must meet the requirements of functionality and aesthetics but it also must be able to be "manufactured"/ created from the technical (manufacturing processes/ scientific methodology and quality management) and economic (their components and materials must be able to be purchased at reasonable prices in the market, so that the cost of final products/ services is compatible with final marketing prices or make feasible the realization of projects) point of view.
                </p>
                <p>
                TECXIO<sup>®</sup> can be defined as a business company of the "Third Wave" using the term coined by Alvin Toffler <sup>(1)</sup> (advisor to presidents, prime ministers and world leaders <sup>(2)</sup>), since its structure is very small in terms of squad employee directly, but has a large number of associated professionals that are convened for specific projects. The work teams formed in this manner operate at a distance, communicated by different informatics tools. This working mode allows the best experts to be convened (selected specially for each project), regardless of their geographical location. Thus, it is possible to minimize costs by allocating to each project only the hours that it requires, with no overhead associated with maintaining the structure of the company (being those very small).
                </p>
                <p>  
                TECXIO<sup>®</sup> only works with renowned professionals, i.e., who have already worked with partners or directors of the company or who have a strong  academic or work technical background and have participated in research programs, development and creation of new businesses promoted by the company.
                </p>
                <p>
                TECXIO<sup>®</sup> Engineering Development is a Division that is focused on the design and development of high added value products and services in the engineering field, yet has the resources and productive capacity to produce highly complex unique pieces, prototypes and small series or manage the production of big series in associated productive plants.
                </p>
                <p>
                <small><sup>(1)</sup> Toffler Alvin. Third Wave. 2nd Edition.Plaza &amp; Jan&eacute;s, 1984.<br />
                <sup>(2)</sup> Biography and specific information available on <a href="https://www.alvintoffler.net/?fa=bios" rel="nofollow">https://www.alvintoffler.net/?fa=bios</a>
                </small>
                </p>
            </div>
</div>

)
}

export default AboutUs;
