


/*<? 
$title = 'Industrial Management Service (GI)';
include_once('inc/head.tpl.php'); 
include_once('inc/header.tpl.php'); 
include_once('inc/menu.tpl.php'); 
include_once('inc/submenu.tpl.php'); 
include_once('inc/featured.gallery.tpl.php'); 
?>*/

import React from "react";
import { Link } from "react-router-dom";
import Head from "../fr/inc/Head.includes.tpl";
import Header from "./inc/Header.tpl";
import Menu from "../fr/inc/Menu.tpl";
import Submenu from "../fr/inc/Submenu.tpl";
 import Footer from "../fr/inc/Footer.tpl";
import FeaturedGallery from "../fr/inc/Featured.gallery.tpl";
import SinopticoGestion from "../en/static/images/photos/informatica/sinoptico-gestion-fr.png"
import Img5 from "../en/static/images/photos/gestion/1.jpg"

 function GestionIndustrielle (){
    return(

        <div id="header" className="header mx-auto max-w-screen">
        <Head/>
        <Header/>
        <Submenu/>
        <FeaturedGallery/>


<div id="content">
    <div id="breadcrumbs">
          {/* <?= printInitBreadcrums() ?>*/}<Link to="<?=REMOTE_PATH_DIV?>products-and-services.html">Produits et Services</Link> &raquo; <strong>Service de Gestion Industrielle (GI)</strong>
    </div>
    <h1>Service de Gestion Industrielle (GI)</h1>
    <div id="leftbar">
        <h4><Link to="<?=REMOTE_PATH_DIV?>products-and-services.html">Produits et services</Link></h4>
        <ul className="flex flex-col">
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/research-and-development">Conception et développement</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/production">Production sur demande</Link></li>
            <li><Link className="bg-lime-600 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/industrial-management">Gestion Industrielle</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/informatics">Informatique</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/repairs">Réparations</Link></li>
        </ul>
    </div>
    <div id="wrapper">
        <p>
        L'objectif est d'améliorer globalement le fonctionnement de l'entreprise cliente, pour qu´elle puisse donc conserver les affaires existantes et générer de nouvelles opportunités 
            commerciales et de croissance.        </p>
        <p className="text-align:center">
            <img src={SinopticoGestion} alt="Cuadro Sinoptico" />
        </p>
        <div to="<?=IMG_PATH?>photos/gestion/1_big.jpg" className="photoo" rel="prettyPhoto[gestion]" title="Descripcion de la foto 1">
            <img className="shadow-[0_35px_60px_-15px_rgba(0,0,0,0.8)]" src={Img5} alt="" />

            <p className="">
            TECXIO®, grâce à sa division de GI, offre le service d'externalisation de tâches essentielles au niveau de la gestion et du personnel de hiérarchie intermédiaire qualifié.Le but est d'améliorer globalement le fonctionnement de l'entreprise afin de conserver les affaires existantes et de générer de nouveaux affaires, à travers le perfectionnement de:Le but est d'améliorer globalement le fonctionnement de l'entreprise afin de conserver les affaires existantes et de générer de nouveaux affaires, à travers le perfectionnement de:            <ul className="">
                <li> La qualité des produits/services offerts par le Client</li>
                <li>Les relations entre les clients et les fournisseurs (négociation, communication)</li>
            </ul>
            </p>
        </div>
        <p>
        TECXIO® cherche ainsi à réduire les coûts en termes absolus, à travers l’élimination des inéfficacités et identifiant et profitant les opportunités d'amélioration.

            </p>
        <p>
            <strong className="bold">Service offert:</strong> <br />
            L'externalisation des tâches spécifiques, à haute valeur ajoutée:<ul>
                <li>Des tâches liées au processus de production: planification de la production, ingéniérie des processus, ingénierie des produits, parmi d´autres.</li>
                <li>Des tâches associées aux processus de production: achat, logistique interne/externe, gestion des stocks, gestion de la qualité, parmi d´autres.</li>
                <li>Des Tâches liées au développement ou création du produit: recherche, conception, développement, prototypage, parmi d´autres.</li>
            </ul>
        </p>

        <p>
        Le but est de suplémenter les travaux existants à l'entreprise cliente, dans l'exécution de tâches essentielles (haute valeur ajoutée) avec la contribution d´heures de travail mensuel de la part d'experts extérieurs, sans qu'il soit nécessaire d'augmenter la masse salariale (en évitant le recrutement d'employés hautement qualifiés et coûteux)  </p>
        <p>
        L'idée est d'aborder, avec une approche professionnelle, le travail de l'entreprise tel comme il est fait, et l´optimiser selon un plan d'action bien défini et convenu avec la Direction. Autrement dit, gérer dès le premier le jour, en éliminant progressivement les inefficiences qui peuvent être détectées. On vise à former le personnel existant, en fournissant les attributs nécessaires pour effectuer des tâches plus complexes que celles réalisées habituellement.
</p>
        
        <p className="bold">
        Avec les outils nécessaires (conçus par un spécialiste), on pourra atteindre à que les personnes avec une formation moyenne, réalisent de façon sporadique ou distribuée, les tâches à haute valeur ajoutée externalisées initialement pour l´optimisation des ressources.


</p>
    </div>
</div>

</div>
)
}

export default GestionIndustrielle;


//<? include_once('inc/footer.tpl.php'); ?>
