import React from "react"
import Header from "./inc/Header.tpl";
import Menu from "../en/inc/Menu.tpl";
import Footer from "./inc/Footer.tpl";
import Featured from "./inc/Featured.tpl";

function English (){
	return(

<div className="grid place-content-center w-screen">
{/* <Head/> */}
		<Header/>
		<Menu/>
		<Featured/>


		<div id="content" className="pt-3">
			<p >
				TECXIO&reg; is a company that promotes access to innovation, focused on industrial engineering and medical technology, providing value to its clients and patients.
			</p>
			<p>
				The Engineering Development Division offers a one-stop shop from R&D to hands-on production, after sales service and maintenance, in the field of metal-mechanics, software, electronics and aeronautics. It also provides in situ innovation services (open innovation) and change management.
			</p>
			<p>
				The Medical Technology Division supports health care systems and medical technology companies to deliver value based healthcare. This includes integrating care around patient medical conditions supported by innovative medical devices and in vitro diagnostics, new payment schemes and risk sharing agreements, patient outcomes and costs measurement, and evidence generation and assessment of the added value of medical technology.    
			</p>
		</div>
		{/* <Footer/> */}

</div>

)
}

export default English;
