/*<? 
$title = 'Design of fabrication and assembly processes';
include_once(PAGES_PATH . 'inc/head.tpl.php'); 
include_once(PAGES_PATH . 'inc/header.tpl.php'); 
include_once(PAGES_PATH . 'inc/menu.tpl.php'); 
include_once(PAGES_PATH . 'inc/submenu.tpl.php'); 
include_once(PAGES_PATH . 'inc/featured.gallery.tpl.php'); 
?>
*/

import React from "react"
import { Link } from "react-router-dom"
import FeaturedGallery from "../../inc/Featured.gallery.tpl";
import Footer from "../../inc/Footer.tpl";
import Head from "../../inc/Head.tpl";
import Header from "../../inc/Header.tpl";
import Menu from "../../inc/Menu.tpl";
import Submenu from "../../inc/Submenu.tpl";

function ProcesosFabricacionMontaje (){
    return (


        <div className="grid place-content-center w-screen">
        {/* <Head/> */}
        <Header/>
        <Submenu/>
        <FeaturedGallery/>


<div id="content">
    <div id="breadcrumbs">
        {/*<?= printInitBreadcrums() ?>*/}
        <Link to="<?=REMOTE_PATH_DIV?>productos-y-servicios.html">Productos y Servicios</Link> &raquo; 
        <Link to="<?=REMOTE_PATH_DIV?>diseno-y-desarrollo.html">Servicio de Dise&ntilde;o y Desarrollo/ Ingenier&iacute;a (D+D/I)</Link> &raquo; 
         <strong>Procesos de Fabricación y Montaje</strong>
    </div>
    <h1>Procesos de Fabricación y Montaje</h1>
    <div id="leftbar">
    <h4><Link className="text-xs" to="/english/engineering-development/products-and-services">Productos y Servicios</Link></h4> 
    <ul className="flex flex-col">
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to= "/spanish/engineering-development/research-and-development">Dise&ntilde;o y Desarrollo</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/production">Producci&oacute;n</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/industrial-management">Gesti&oacute;n Industrial</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/informatics">Informatica</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/repairs">Reparaciones</Link></li>
        </ul>
    </div>
    
    <div id="wrapper">
    <p>
         Se dise&ntilde;an procesos cont&iacute;nuos, discont&iacute;nuos y flexibles relacionados con el mecanizado de piezas simples y complejas, 
            con equipos manuales, semiautom&aacute;ticos y CNC (incluyendo la fabricaci&oacute;n de roscas especiales), soldaduras TIG calificadas 
            y certificadas, fabricaci&oacute;n de materiales compuestos, l&iacute;neas de montaje, sistemas “poka yoke” (o a prueba de errores) entre otros. 
        </p>
    </div>
</div>

</div>
 
)
}

export default ProcesosFabricacionMontaje

 