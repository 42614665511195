import React from "react"
import { Link } from 'react-router-dom';
import DesarrolloIngenieria from "../static/images/thumbs/desarrollo-ingenieria.jpg"
import MedicalTechnology from "../static/images/thumbs/tec-medica.jpg"


function Featured (){

    return(


 

<div className="featured big divisiones">
        <div className="diseno box ">
            <Link to="/english/engineering-development">
                <img src={DesarrolloIngenieria} alt="Engineering Development" />
                <h2>Engineering Development</h2>
            </Link>
        </div>
        <div className="produccion box">
            <Link to="/english/medical-technology">
                <img  src={MedicalTechnology}  alt="Medical Technology" />
                <h2>Medical Technology</h2>
            </Link>
        </div>

        <link rel="stylesheet" href="" />
</div>
 

    )}

    export default Featured;