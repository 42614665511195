/*<? 
$title = 'Documentation of products and processes';
include_once(PAGES_PATH . 'inc/head.tpl.php'); 
include_once(PAGES_PATH . 'inc/header.tpl.php'); 
include_once(PAGES_PATH . 'inc/menu.tpl.php'); 
include_once(PAGES_PATH . 'inc/submenu.tpl.php'); 
include_once(PAGES_PATH . 'inc/featured.gallery.tpl.php'); 
?>*/

import React from "react"
import { Link } from "react-router-dom"
import FeaturedGallery from "../../inc/Featured.gallery.tpl";
import Footer from "../../inc/Footer.tpl";
import Head from "../../inc/Head.tpl";
import Header from "../../inc/Header.tpl";
import Menu from "../../inc/Menu.tpl";
import Submenu from "../../inc/Submenu.tpl";

function DocumentationProductsProcesses () {
    return(
        <div className="grid place-content-center w-screen">
        {/* <Head/> */}
        <Header/>
        <Submenu/>
        <FeaturedGallery/>



<div id="content">
    <div id="breadcrumbs">
       {/* <?= printInitBreadcrums() ?> */}
        <Link to="/english/engineering-development/products-and-services">Products and Services</Link> &raquo; 
        <Link to="/english/engineering-development/research-and-development">Research and Development</Link> &raquo; 
        <strong>Documentation of products and processes</strong>
    </div>
    <h1>Documentation of products and processes</h1>
    <div id="leftbar">
    <h4><Link className="text-xs" to="/english/engineering-development/products-and-services">Products and Services</Link></h4>        <ul className="flex flex-col">
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/research-and-development">Research and Development</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/production">Production</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/industrial-management">Industrial Management</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/informatics">Informatics</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/repairs">Repairs</Link></li>
        </ul>
    </div>
    <div id="wrapper">
        <p>
            Preparation of product / supplies / suppliers matrix, roadmaps and process diagrams, 3D graphics and drawings of manufacturing and assembly.Coding of products, processes and machinery.
        </p>
    </div>
</div>

{/* <Footer/> */}

</div>
)
}
export default DocumentationProductsProcesses

//<? include_once(PAGES_PATH . 'inc/footer.tpl.php'); ?>
