/*<? 
$title = 'Research and Business Plant (USINA)';
include_once(PAGES_PATH . 'inc/head.tpl.php'); 
include_once(PAGES_PATH . 'inc/header.tpl.php'); 
include_once(PAGES_PATH . 'inc/menu.tpl.php'); 
include_once(PAGES_PATH . 'inc/submenu.tpl.php'); 
include_once(PAGES_PATH . 'inc/featured.gallery.tpl.php'); 
?>*/

import React from "react"
import {Link} from "react-router-dom"

import SinopticoUsina from "../en/static/images/photos/informatica/sinoptico-investigacion-fr.png"
import Footer from "./inc/Footer.tpl"
import Head from "./inc/Head.tpl";
import Header from "./inc/Header.tpl";
import Menu from "../en/inc/Menu.tpl";
import FeaturedGallery from "./inc/Featured.gallery.tpl";
import Submenu from "./inc/Submenu.tpl";
import FeaturedEngineeringDevelopment from "./inc/Featured-engineering-development.tpl";
 import Featured from "./inc/Featured.tpl";


function RechercheEtusineDEntreprises (){
    return(

<div className="grid place-content-center w-screen">

{/* <Head/> */}
<Header/>
<Submenu/>
<FeaturedGallery/>


<div id="content">
    <div id="breadcrumbs">
         {/* <?= printInitBreadcrums() ?>*/}<strong>Research and Business Plant (USINA)</strong>
    </div>
    <h1>Research and Business Plant (USINA)</h1>
    <p className="text-align:center">
        <img className="mx-auto" src={SinopticoUsina} alt="Cuadro Sinoptico" />
        </p>
    <p>
    Le but est de créer des groupes pour la recherche et le développement des produits et des services conçus pour améliorer la qualité de vie des personnes et pour aider à préserver l'environnement.
    </p>
    <p>
    Les domaines de recherche principaux sont: les énergies non-conventionnelles (éolienne, solaire, hydrogène), les biocarburants, les nouveaux systèmes de propulsion et les transports, l'intelligence artificielle et les systèmes experts.
    </p>
    <p>
    Soutenabilité: TECXIO<sup>®</sup> fournit aux forums de recherche formés, la structure et l'expérience nécessaires pour la mise en œuvre des projets rentables, et la possibilité que ses membres forment ensembles avec TECXIO<sup>®</sup>, une société commerciale qui exploite les connaissances générées.
    </p>
    <p>
    TECXIO<sup>®</sup>, à son tour, rend la recherche des opportunités d'emploi parmi les membres des forums de recherche qui se distinguent par leurs compétences professionnelles, leurs qualités humaines, leur dévouement et leur créativité.
    </p>
    <p>
    TECXIO<sup>®</sup>, grâce à sa division USINA favorise:
    </p>
    <ul>
    <li>
            Le développement et la mise en œuvre de nouveaux projets dans le domaine de l'ingénierie. <Link to="javascript:;" className="showmore" rel="1" attr="Lire plus|Lire moins">Lire plus</Link>
            <div className="more1 hidden">
                <br /><br />TECXIO <sup>®</sup> favorise la formation de forums de recherche* pluridisciplinaire, avec des spécialistes compétents dans les différents domaines de l'ingénierie et de diverses branches de la science. <br /><br />
                Nous essayons que les forums aient les caractéristiques suivantes:
                <ol>
                    <li>
                        Conformés en termes d'un domaine d'intérêt, par exemple, "les éoliennes pour la production d'électricité", peintures photovoltaïque "," sécurité pour les systèmes de stockage de l'hydrogène "," systèmes experts pour contrôler les équipements complexes.<br />
                        TECXIO<sup>®</sup>, délivré par son blog: <Link to="https://www.tecxio.com/french/en-formacion.html">tecxioblog.com</Link>, delivrera une liste de sujets de recherche pour être traités dans les forums et à leur tour, encourager les utilisateurs à choisir, ou de proposer de nouveaux thèmes ou à restreindre les thèmes proposés (abordant des questions plus spécifiques relationnées) . 
                    </li>
                    <li>Composées d'un petit groupe de membres: si possible pas plus de 5 personnes.</li>
                    <li>C’est prévu que les membres du forum soient des professionnels dans les thèmes de recherche choisis. </li>
                    <li>Chaque conseil doit nommer un coordinateur et un responsable de la documentation.</li>
                    <li>
                        Après la formation du forum autour d'un thème spécifique et après avoir désigné un coordinateur et un responsable de la documentation, l'activité sera ordonnée par étapes. <Link to="javascript:;" className="showmore" rel="2" attr="Lire plus|Lire moins">Lire plus</Link>
                        <div className="more2 hidden">
                            <ol>
                                <li>Échange d'idées (brain storming)</li>
                                <li>Choisir des objectifs:
                                    <ol>
                                        <li>Définir avec précision l’objet d’étude, et les résultats concrets que nous espérons obtenir.</li>
                                        <li>Si on cherche des résultats de la connaissance pure (la science) ou appliquée (Technologies).</li>
                                        <li>On prévoit de former une société autour des résultats de l'enquête ou la simple production des matières académiques. </li>
                                    </ol>
                                </li>
                                <li>
                                    La recherche:<br />
                                    Définition, exécution et suivi des tâches de recherches et documentation des résultats obtenus.</li>
                                <li>Rapport final</li>
                                <li>
                                    Publication des résultats (interne: elle ne pourra être consultée que par les membres du forum ou par qui ils autorisent, ou externe: elle pourra être vue par le reste des utilisateurs ou par le public en général à la discrétion des membres du forum). 
                                </li>
                            </ol>
                        </div>
                    </li>
                    <li>
                        TECXIO<sup>®</sup> choisira un membre de la division USINA (USINA Advisor) pour la surveillance de l'activité de divers forums, prenant soin de fournir les ressources nécessaires à leur fonctionnement et d'analyser l'affinité des thèmes développés par les différents forums, en vue de promouvoir l’échange entre forums. 
                    </li>
                    <li>
                        L'activité de chaque forum, la recherche et la documentation produite à la suite de cette activité sera connue par les membres du forum et des conseillers nommés par USINA TECXIO<sup>®</sup>. 
                    </li>
                    <li>
                        L'échange des informations entre les forums de recherche doit être approuvé par les membres du forum concernés. 
                    </li>
                    <li>
                        Plusieurs forums peuvent être formés sur le thème de la recherche et agir en coordination avec d'autres groupes ou isolément, selon le souhait des membres du forum. 
                    </li>
                    <li>
                        TECXIO<sup>®</sup> publiera les sujets de recherche dans tous les forums, mais seulement publiera les résultats de recherche ou de développement de celle-ci, si les membres de chaque forum l’ autorisent. 
                    </li>
                </ol>
            </div>
        </li>
        <li>
            Le partenariat stratégique entre les entreprises, pour mettre en œuvre de nouveaux projets dans le domaine de l'ingénierie. <Link to="javascript:;" className="showmore" rel="3" attr="Lire plus|Lire moins">Lire plus</Link>
            <div className="more3 hidden">
                TECXIO<sup>®</sup> vise à atteindre une compréhension approfondie de leurs clients, tout en effectuant une analyse permanente de nouvelles occasions d'affaires dans le marché, ou résultant de leur forums de recherche. D'après les informations recueillies, TECXIO<sup>®</sup> agit comme un pont entre les entreprises et la promotion de partenariats stratégiques (pour l'ouverture de nouveaux marchés, le comportement d'achat en commun, le partage des circuits de commercialisation), l'intégration verticale ou horizontale.
            </div>
        </li>
    </ul>
</div>

{/* <Footer/> */}

</div>
)
} 
export default RechercheEtusineDEntreprises

//<? include_once(PAGES_PATH . 'inc/footer.tpl.php'); ?>






 