 
import React, {useState} from "react"
 
 import { Link } from "react-router-dom";
import Diseno from "../static/images/thumbs/diseno.jpg"
import Produccion from "../static/images/thumbs/produccion.jpg";
import Gestion from "../static/images/thumbs/gestion.jpg"
import Informatica from "../static/images/thumbs/informatica.jpg"
import Reparaciones from "../static/images/thumbs/reparaciones.jpg";
import Submenu from "./Submenu.tpl"; 
import DisSeven from "../static/images/photos/diseno/7.jpg"
import ProduccionTwo from "../../../pages/en/static/images/photos/produccion/2.jpg"
import OneDiseno from "../../../pages/en/static/images/photos/diseno/2.jpg"
import GestionTwo from "../../../pages/en/static/images/photos/diseno/6.jpg"
import InformatiqueTwo from "../../../pages/en/static/images/photos/informatica/1_big.jpg"


function FeaturedEngineeringDevelopment () {

    const [ showServiceSnippet, setShowServiceSnippet ] = useState(false);
    const [ showProductionSnippet, setShowProductionSnippet ] = useState(false);
    const [ showIndustrialManagementSnippet, setShowIndustrialManagementSnippet ] = useState(false);
    const [ showInformaticsSnippet, setShowInformaticsSnippet ] = useState(false);
    const [ showRepairsSnippet, setShowRepairsSnippet ] = useState(false);



    return (



 
<div className="grid place-content-center container mx-auto px-4">
<Submenu/>
<div id="featured" className="big">

 
        <div className="diseno box">
            <div onClick={() => setShowServiceSnippet(true)}>
                <img src= {Diseno} alt="R&amp;D Service" />
                <h2>R&amp;D Service</h2>
            </div>
        </div>
        <div className="produccion box">
            <div onClick={() => setShowProductionSnippet(true)}>
                <img src={Produccion} alt="Production" />
                <h2>Production</h2>
            </div>
        </div>
        <div className="gestion box">
            <div onClick={() => setShowIndustrialManagementSnippet(true)}>
                <img src={Gestion} alt="Industrial Management" />
                <h2>Industrial Management</h2>
            </div>
        </div>
        <div className="informatica box">
            <div onClick={() => setShowInformaticsSnippet(true)}>
                <img src={Informatica} alt="Informatics" />
                <h2>Informatics</h2>
            </div>
        </div>
        <div className="reparaciones box">
            <div onClick={() => setShowRepairsSnippet(true)}>
                <img src={Reparaciones} alt="Repairs" />
                <h2>Repairs</h2>
            </div>
        </div>
       
        <div style={{marginLeft: '0px'}} 
        className={`flex justify-between diseno description absolute bg-contain top-[103px] bg-[#151515] min-w-[960px] max-w-[960px] h-[224px] ${!showServiceSnippet && 'hidden'} `}>
            <div className="w-7/12  h-10/12 m-2 ">
                <img src={Diseno} alt="" className="borderr w-full " />  
            </div>
            <div className="mx-4 mt-6">
                <h4>R&amp;D Service</h4>
                <p>
                TECXIO<sup>®</sup>, through its R  D division, offers the service of designing and developing high added value products. We can offer our customers the supply of products in various stages of development, from conception of an idea to manufacturing and packaging of finished product. <br />
                <div className="linkContainer"><p className="inline text-blue-400" onClick={() => setShowServiceSnippet(false)}>&laquo; Back</p> | <Link className="rm" to="/english/engineering-development/research-and-development">Read more &raquo;</Link></div>
                </p>
            </div>
            <div className="w-4/12  h-28 mr-2 my-auto">
                <img src={DisSeven} alt="" className="w-full borderr" />
            </div>
        </div>

        <div style={{marginLeft: '0px'}}
         className={`flex justify-between produccion description absolute top-[103px] bg-[#151515] min-w-[960px] h-[224px]  ${!showProductionSnippet && 'hidden'} `}>
            <div className="w-5/12 bg-white h-4 mt-2 ml-2">
                <img src={Produccion} alt="" className="w-full borderr" />  
            </div>
            <div className="mx-4 mt-6">
                <h4>Production</h4>
                <p>
                TECXIO<sup>®</sup> has resources and productive capacity to produce highly complex unique pieces, prototypes and small series, or manage the production of large series in associated productive plants.<br /> 
                <div className="linkContainer"><p className="inline text-blue-400" onClick={() => setShowProductionSnippet(false)}>&laquo; Back</p> | <Link className="rm" to="/english/engineering-development/production">Read more &raquo;</Link></div>
                </p>
            </div>
            <div className="w-72  mt-8 h-32 mr-2 my-auto">
                <img src={ProduccionTwo} alt="" className="w-full borderr" />
            </div>
        </div>

        <div style={{marginLeft: '0px'}} 
        className={`flex justify-between gestion description absolute top-[103px] bg-[#151515] min-w-[960px] h-[224px]  ${!showIndustrialManagementSnippet && 'hidden'}`}>
            <div className="w-4/12  h-52 mt-2 ml-2">
                <img src={Gestion}  alt="" className="h-full w-full borderr" />  
            </div>
            <div className="mx-4 mt-6">
                <h4>Industrial Management</h4>
                <p>
                TECXIO<sup>®</sup>, through its GI division, offers the service of outsourcing critical tasks at management and middle highly qualified levels. <br /> The goal is to comprehensively improve the functioning of the client company, so as to sustain existing businesses and generate new business opportunities and growth. <br />
                <div className="linkContainer"><p className="inline text-blue-400" onClick={() => setShowIndustrialManagementSnippet(false)}>&laquo; Back</p> | <Link className="rm" to="/english/engineering-development/industrial-management">Read more &raquo;</Link></div>
                </p>
            </div>
            {/* <div className="w-72 bg-white h-32 mr-2 my-auto">
                <img src={GestionTwo} alt="" className="w-full h-full" />
            </div> */}
        </div>
        
        <div style={{marginLeft: '0px'}}
         className={`flex justify-between informatica description absolute top-[103px] bg-[#151515] min-w-[960px] h-[224px] ${!showInformaticsSnippet && 'hidden'}`}>
            <div className="w-5/12 bg-white h-52 mt-2 ml-2">
                <img src={Informatica} alt="" className="w-full h-full borderr" />  
            </div>
            <div className="mx-4 mt-6">
                <h4>Informatics</h4>
                <p>
                TECXIO<sup>®</sup>, through its IT division, offers design, development and implementation of control, calculation, management and design software in the engineering field.<br />
                <div className="linkContainer"><p className="inline text-blue-400" onClick={() => setShowInformaticsSnippet(false)}>&laquo; Back</p> | <Link className="rm" to="/english/engineering-development/informatics">Read more &raquo;</Link></div>
                </p>
            </div>
            <div className="w-72 bg-white h-32 mr-2 my-auto">
                <img src={InformatiqueTwo} alt="" className="borderr"/>
            </div>
        </div>

        <div style={{marginLeft: '0px'}}
         className={`flex justify-between reparaciones description absolute top-[103px] bg-[#151515] min-w-[960px] h-[224px] ${!showRepairsSnippet && 'hidden'}`}>
            <div className="w-7/12 bg-white h-52 mt-2 ml-2">
                <img src={Reparaciones} alt="" className="w-full h-full borderr" />  
            </div>
            <div className="mx-4 mt-6">
                <h4>Repair and upgrade equipment service </h4>
                <p>
                TECXIO<sup>®</sup>, through its RAE division, offers the service of mechanical equipment repair, including replacement of special parts that need to be surveyed and tailored made (such as no ends and crowns, axles, small or large size gears) , machining of worn molding and adaptation of bearings systems and repair of special threads.<br /> 
                <div className="linkContainer"><p className="inline text-blue-400" onClick={() => setShowRepairsSnippet(false)}>&laquo; Back</p> | <Link className="rm" to="/english/engineering-development/repairs">Read more &raquo;</Link></div>
                </p>
            </div>
            {/* <div className="w-72 bg-white h-32 mr-2 my-auto">
                <img src="" alt="" />
            </div> */}
        </div>
        
        <div className="gallery hidden"></div>
        
</div>

</div>
    )

}

export default FeaturedEngineeringDevelopment;
