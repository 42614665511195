import React , { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom";

function Submenu (){

const navigate = useNavigate()
  const [isOpen, setisOpen] = useState(true);
  const toggleSubmenu = (e) => {
       console.log("submenu")
      if (isOpen) {
          setisOpen(false);
      } else if (!isOpen) {
          setisOpen(true);
      }
  } 
 
  const [isOpenRight, setisOpenRight] = useState(true);
  const toggleSubmenuRight = (e) => {
       console.log("submenu")
      if (isOpenRight) {
          setisOpenRight(false);
      } else if (!isOpenRight) {
          setisOpenRight(true);
      }
  } 

    return (



<div className="grid place-content-center ">

 
  <div className="menubg   flex  flex-start  flex-col md:flex-row items-start">
    <nav className="nav-menu font-sm     flex-wrap items-start text-sm ">
      <Link   to="/spanish/" className="mr-5     ">Principal</Link>
      <Link  to=""  className="dropdownName"  onClick={(e) => toggleSubmenu()} >Productos and Servicios</Link>
      {!isOpen && 
       
      <div id="submenu" className=" " > 
    <div className="productos submenu">
        <ul className="left">
            <li>
                <p clasName="p-name " title="Research and Development"    onClick={(e) => toggleSubmenuRight(e)}> <span  to="/spanish/engineering-development/research-and-development"> Diseño y desarrollo</span>
                <div className="dropdowntwo">
             {!isOpenRight && 
 
        <ul className='bg-slate-200 relative text-start top-[184px] -mt-0.5 -left-6'>
            <li><Link  to= "/spanish/engineering-development/research-and-development/Devices-machinery-equipment-pieces.tpl">Diseño de dispositivos, maquinarias,equipos y piezas</Link></li>
            <li><Link to="/spanish/engineering-development/research-and-development/Calculation-structures.tpl">Diseño y calculo de estructuras </Link></li>
            <li><Link to="/spanish/engineering-development/research-and-development/fabrication-assembly-processes.tpl">Diseño de procesos de fabricacion y montaje </Link></li>
            <li><Link to="/spanish/engineering-development/research-and-development/re-engineering-manufacturing-assembly-processes.tpl">Re-ingenieria de procesos de fabricacion y montaje</Link></li>
            <li><Link to="/spanish/engineering-development/research-and-development/production-cells.tpl">Diseño y desarrollo de celdas productivas</Link></li>
            <li><Link to="/spanish/engineering-development/research-and-development/testing-materials-equipment.tpl">Ensayo de materiales y equipos</Link></li>
            <li><Link to="/spanish/engineering-development/research-and-development/documentation-products-processes.tpl">Documentacion de productos y procesos</Link></li>
         </ul>
        
        }
                 </div>  
                </p>
        
            </li>
         
            <li><Link className="produccion" to="/spanish/engineering-development/production" title="Production">Produccion</Link></li>
            <li><Link className="gestion" to="/spanish/engineering-development/industrial-management" title="Industrial Management">Gestion Industrial</Link></li>
            <li><Link className="informatica" to="/spanish/engineering-development/informatics" title="Informatics">Informatica</Link></li>
            <li><Link className="reparaciones" to="/spanish/engineering-development/repairs" title="Repairs">Reparaciones</Link></li>
            
        </ul>
      
        </div>
</div>
}
      <Link to="/spanish/engineering-development/research-and-business-plant"  className="mr-5 hover:text-gray-900">Investigación y Usina de empresas</Link>
      <Link  to="/spanish/about"  className="mr-5 hover:text-gray-900">Quienes somos</Link>
      <Link to="/spanish/contact"  className="mr-5 hover:text-gray-900">Contactenos</Link>
       
       
    </nav>
  </div>
{/*<div class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
    <div class="py-1" role="none">
       <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">Account settings</a>
      <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-1">Support</a>
      <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2">License</a>
      <form method="POST" action="#" role="none">
        <button type="submit" class="text-gray-700 block w-full text-left px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-3">Sign out</button>
      </form>
    </div>
  </div>*/}




 </div>
   
   
   
   
   )}


 

 

 
export default Submenu;
