import React from "react";
import { Link } from "react-router-dom";
import Submenu from "../es/inc/Submenu.tpl";
import FeaturedGallery from "../es/inc/Featured.gallery.tpl";
import Footer from "../es/inc/Footer.tpl";
import Head from "../es/inc/Head.tpl";
import Header from "../es/inc/Header.tpl";
import Menu from "../es/inc/Menu.tpl";
import SinopticoInformatica from "../en/static/images/photos/informatica/sinoptico-informatica-es.png"
import OneBig from "../en/static/images/photos/informatica/1_big.jpg"
import SecondBig from "../en/static/images/photos/informatica/2.jpg"
import ThirdBig from "../en/static/images/photos/informatica/3.jpg"
import ForthBig from "../en/static/images/photos/informatica/4.jpg"



function Informatica () {
    return (

        <div className="grid place-content-center w-screen">

<Head/>
            <Header/>
             <Submenu/>
            <FeaturedGallery/>

<div id="content">
    <div id="breadcrumbs">
        <Link to="<?=REMOTE_PATH_DIV?>productos-y-servicios.html">Productos y Servicios</Link> &raquo; <strong>Productos y Servicios </strong>
    </div>
    <h1>Inform&aacute;tica (IT)</h1>
    <div id="leftbar">
        <h4><Link className="text-xs" to="<?=REMOTE_PATH_DIV?>productos-y-servicios.html">Productos y Servicios</Link></h4>
        <ul className="flex flex-col">
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to= "/spanish/engineering-development/research-and-development">Dise&ntilde;o y Desarrollo</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/production">Producci&oacute;n</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/industrial-management">Gesti&oacute;n Industrial</Link></li>
            <li><Link className="bg-lime-600 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/informatics">Informatica</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/repairs">Reparaciones</Link></li>
        </ul>
    </div>
    <div id="wrapper">
        <p>
        TECXIO<sup>&reg;</sup>, a trav&eacute;s de su divisi&oacute;n de IT, ofrece el dise&ntilde;o, desarrollo e implementaci&oacute;n de software de control, c&aacute;lculo, gesti&oacute;n 
        y dise&ntilde;o, en el campo de la ingenier&iacute;a. 
        </p>
        
        <p className="text-align:center">
            <img src={SinopticoInformatica} alt="Cuadro Sinoptico" />
        </p>
        
        <p>
        TECXIO<sup>&reg;</sup> puede dise&ntilde;ar, por ejemplo, un software que permita el c&aacute;lculo de resortes con f&oacute;rmulas emp&iacute;ricas, dise&ntilde;ar y fabricar una 
        m&aacute;quina capaz de producir determinado tipo de resortes, dise&ntilde;ando incluso, la celda productiva completa (a trav&eacute;s de su divisi&oacute;n "Servicios I+D") 
        y a su vez, dise&ntilde;ar un software de control para esa m&aacute;quina.
        </p>
        <div className="minigallery" id="margin:10px 0 10px 110px">
            <div className="photo left" to="<?=IMG_PATH?>photos/informatica/1_big.jpg" rel="prettyPhoto[informatica]" title="Descripcion de la foto 1">
                <img src={OneBig} alt="" />
            </div>
            <div className="photo left" to="<?=IMG_PATH?>photos/informatica/2_big.jpg" rel="prettyPhoto[informatica]" title="Descripcion de la foto 2">
                <img src={SecondBig} alt="" />
            </div>
            <div className="photo left" to="<?=IMG_PATH?>photos/informatica/3_big.jpg" rel="prettyPhoto[informatica]" title="Descripcion de la foto 3">
                <img src={ThirdBig}  alt="" />
            </div>
            <div className="photo left" to="<?=IMG_PATH?>photos/informatica/4_big.jpg" rel="prettyPhoto[informatica]" title="Descripcion de la foto 4">
                <img src={ForthBig} alt="" />
            </div>
        </div>
    </div>
</div>
        </div>
    )
}

export default Informatica;