/*<? 
$title = 'Documentation of products and processes';
include_once(PAGES_PATH . 'inc/head.tpl.php'); 
include_once(PAGES_PATH . 'inc/header.tpl.php'); 
include_once(PAGES_PATH . 'inc/menu.tpl.php'); 
include_once(PAGES_PATH . 'inc/submenu.tpl.php'); 
include_once(PAGES_PATH . 'inc/featured.gallery.tpl.php'); 
?>*/

import React from "react"
import { Link } from "react-router-dom"
import FeaturedGallery from "../../inc/Featured.gallery.tpl";
import Footer from "../../inc/Footer.tpl";
import Head from "../../inc/Head.tpl";
import Header from "../../inc/Header.tpl";
import Menu from "../../inc/Menu.tpl";
import Submenu from "../../inc/Submenu.tpl";

function DocumentationDeProduitsEtProcessus () {
    return(
        <div className="grid place-content-center w-screen">
        {/* <Head/> */}
        <Header/>
        <Submenu/>
        <FeaturedGallery/>



<div id="content">
    <div id="breadcrumbs">
       {/* <?= printInitBreadcrums() ?> */}
        <Link to="/english/engineering-development/products-and-services">Produits et Services</Link> &raquo; 
        <Link to="/english/engineering-development/research-and-development">Services de conception et développement / ingénierie (I+D)</Link> &raquo; 
        <strong>Documentation de Produits et Processus</strong>
    </div>
    <h1>Documentation de Produits et Processus</h1>
    <div id="leftbar">
    <h4><Link className="text-xs" to="/english/engineering-development/products-and-services">Produits et Services</Link></h4>       
    <ul className="flex flex-col">
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/research-and-development">Conception et développement</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/production">Production sur demande</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/industrial-management">Gestion Industrielle</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/informatics">Informatique</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/repairs">Réparations</Link></li>
        </ul>
    </div>
    <div id="wrapper">
        <p>
        Préparation de la matrice de produit/d´entrée/de fournisseurs, des échéanciers et des diagrammes de processus, des graphiques 3D et de dessins de fabrication et montage. La codification des produits, des procédés et des machines.
        </p>
    </div>
</div>

</div>
)
}
export default DocumentationDeProduitsEtProcessus

//<? include_once(PAGES_PATH . 'inc/footer.tpl.php'); ?>



    