import React from "react";
import { Link } from "react-router-dom";
import Submenu from "../es/inc/Submenu.tpl";
import FeaturedGallery from "../es/inc/Featured.gallery.tpl";
import Footer from "../es/inc/Footer.tpl";
import Head from "../es/inc/Head.tpl";
import Header from "../es/inc/Header.tpl";
import Menu from "../es/inc/Menu.tpl";
import SinopticoRepairs from "../en/static/images/photos/informatica/sinoptico-reparaciones-es.png"
import ReparacionOne from "../en/static/images/photos/reparacion/1.jpg"
import ReparacionTwo from "../en/static/images/photos/reparacion/2.jpg"


function Reparaciones () {
    return (

     

        <div  className="grid place-content-center w-screen" >

<Head/>
            <Header/>
             <Submenu/>
            <FeaturedGallery/>

<div id="content">
    <div id="breadcrumbs">
 <Link to="<?=REMOTE_PATH_DIV?>productos-y-servicios.html">Productos y Servicios</Link> &raquo; <strong> Servicio de Reparación y actualización de equipamiento (RAE)</strong>
    </div>
    <h1>Servicio de reparacion y actualizacion de equipamiento (RAE) </h1>
    <div id="leftbar">
        <h4><Link className="text-xs" to="<?=REMOTE_PATH_DIV?>productos-y-servicios.html">Productos y Servicios</Link></h4>
        <ul className="flex flex-col">
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to= "/spanish/engineering-development/research-and-development">Dise&ntilde;o y Desarrollo</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/production">Producci&oacute;n</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/industrial-management">Gesti&oacute;n Industrial</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/informatics">Informatica</Link></li>
            <li><Link className="bg-lime-600 border-solid border border-yellow-200 rounded-lg text-xs" to="/spanish/engineering-development/repairs">Reparaciones</Link></li>
        </ul>
    </div>
    <div id="wrapper">
         
        
        <p className="text-align:center">
            <img src={SinopticoRepairs} alt="Cuadro Sinoptico" />
        </p>
   
        <div id="wrapper">
       
        <div class="photo left" to="<?=IMG_PATH?>photos/reparacion/1_big.jpg" rel="prettyPhoto[reparacion]" title="Descripcion de la foto 1">
            <img src={ReparacionOne} alt="" />
        </div>
        <p class="withphototwo">
        TECXIO®, a través de su división RAE, ofrece el servicio de reparación de equipamiento mecánico, incluyendo el reemplazo de piezas especiales que deban ser relevadas y fabricadas a medida (como sin fines y coronas, ejes, engranajes, de pequeño o gran porte), el mecanizado de piezas de fundición desgastadas y adaptación de sistemas de rodamientos y reparación de roscas especiales.        </p>
        <p className="pe"></p>
        <div class="photo left" to="<?=IMG_PATH?>photos/reparacion/2_big.jpg" rel="prettyPhoto[reparacion]" title="Descripcion de la foto 2">
            <img src={ReparacionTwo} alt="" />
        </div>
        <p class="withphototwo">
        TECXIO®, ofrece a sus clientes, la posibilidad de re-diseñar o modificar el equipamiento dañado, para reducir la frecuencia de reparaciones e incluso eliminar determinados tipos de fallo a través de soluciones creativas.        </p>
      
    </div>
     
    </div>
</div>

         </div>
    )
}

export default Reparaciones;