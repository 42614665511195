
import React from "react"
import Head from "./inc/Head.tpl";
import Header from "./inc/Header.tpl";
import Menu from "../en/inc/Menu.tpl";
import Submenu from "./inc/Submenu.tpl";
import FeaturedEngineeringDevelopment from "./inc/Featured-engineering-development.tpl";
import Footer from "./inc/Footer.tpl";
 
function IndexEngineeringDevelopment(){
    return (

<div className="grid place-content-center">
    {/* <Head/> */}
    <Header/>
    <FeaturedEngineeringDevelopment/>


    <div id="content" className="container pt-3 mx-auto w-64">
        <p className=""  >
        TECXIO has been created by a group of professionals specialized in different branches of industry (metal-mechanics, software, electronics and aeronautics) with the vision of combining together very specific technical knowledge through a generalist approach, resulting in a synergetic process that allows the creation of products and services with high added value for our customers.
        </p>
    </div>

</div>

)
}

export default IndexEngineeringDevelopment;

