/*<? 
$title = 'Informatics (IT)';
include_once(PAGES_PATH . 'inc/head.tpl.php'); 
include_once(PAGES_PATH . 'inc/header.tpl.php'); 
include_once(PAGES_PATH . 'inc/menu.tpl.php'); 
include_once(PAGES_PATH . 'inc/submenu.tpl.php'); 
include_once(PAGES_PATH . 'inc/featured.gallery.tpl.php'); 
?>
*/

import React from "react"
import { Link } from "react-router-dom"
import Head from "./inc/Head.tpl";
import Header from "./inc/Header.tpl";
import Menu from "../en/inc/Menu.tpl";
import Submenu from "./inc/Submenu.tpl";
import FeaturedEngineeringDevelopment from "./inc/Featured-engineering-development.tpl";
import Footer from "./inc/Footer.tpl";
import Featured from "./inc/Featured.tpl";
import FeaturedGallery from "./inc/Featured.gallery.tpl";
import SinopticoInformatica from "./static/images/photos/informatica/sinoptico-informatica-en.png"
import OneBig from "./static/images/photos/informatica/1_big.jpg"
import SecondBig from "./static/images/photos/informatica/2.jpg"
import ThirdBig from "./static/images/photos/informatica/3.jpg"
import ForthBig from "./static/images/photos/informatica/4.jpg"



function Informatics (){
    return(
<div className="grid place-content-center w-screen">

{/* <Head/> */}
<Header/>
<Submenu/>
<FeaturedGallery/>

<div id="content" className="pb-12">
    <div id="breadcrumbs">
        <Link to={'/english'}>TECXIO  </Link>{` > `}
        <Link to={'/english/engineering-development'}>Engineering development   </Link>{` > `}
        <Link to={'/english/engineering-development/products-and-services'}>Products and services</Link>{` > `}
        <strong>Informatics (IT)</strong>
    </div>
    <h1>Informatics (IT)</h1>
    <div id="leftbar">
    <h4><Link className="text-xs" to="/english/engineering-development/products-and-services">Products and Services</Link></h4>
        <ul className="flex flex-col">
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/research-and-development">Research and Development</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/production">Production</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/industrial-management">Industrial Management</Link></li>
            <li><Link className=" bg-lime-600   border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/informatics">Informatics</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/repairs">Repairs</Link></li>
        </ul>
    </div>
    <div id="wrapper">
        <p>
        TECXIO<sup>®</sup>, through its IT division, offers design, development and implementation of control, calculation, management and design software in the engineering field.
        </p>
        
        <p >
            <img src={SinopticoInformatica} alt="Cuadro Sinoptico" />
        </p>
        
        <p>
        TECXIO<sup>®</sup> is able to design, for instance, a software that allows the calculation of springs through empirical formulas, design and manufacture a machine capable of producing certain types of springs, including designing the entire production cell (through its division, "R & D Services ") and even design a control software for that machine.
        </p>
        <div className="minigallery">
            <div className="photo left" to={OneBig} rel="prettyPhoto[informatica]" title="Descripcion de la foto 1">
                <img src={OneBig} alt="" />
            </div>
            <div className="photo left" to="<?=IMG_PATH?>photos/informatica/2_big.jpg" rel="prettyPhoto[informatica]" title="Descripcion de la foto 2">
                <img src={SecondBig} alt="" />
            </div>
            <div className="photo left" to="<?=IMG_PATH?>photos/informatica/3_big.jpg" rel="prettyPhoto[informatica]" title="Descripcion de la foto 3">
                <img src={ThirdBig} alt="" />
            </div>
            <div className="photo left" to="<?=IMG_PATH?>photos/informatica/4_big.jpg" rel="prettyPhoto[informatica]" title="Descripcion de la foto 4">
                <img src={ForthBig} alt="" />
            </div>
        </div>
    </div>
</div>
</div>
)
}

export default Informatics;


