



import React from "react"
import Header from "./inc/Header.tpl";
import Menu from "../es/inc/Menu.tpl";
import Footer from "./inc/Footer.tpl";
import Featured from "./inc/Featured.tpl";

function Espanol (){
	return(

<div className="grid place-content-center w-screen">
{/* <Head/> */}
		<Header/>
		<Menu/>
		<Featured/>


		<div id="content" className="pt-3">
			<p >
            TECXIO&reg; es una empresa que promueve el acceso a la innovaci&oacute;n, en el campo de la ingenier&iacute;a industrial y la tecnolog&iacute;a m&eacute;dica, aportando valor tanto a sus clientes directos como a los pacientes.
	</p>
    <p>
		La divisi&oacute;n de Desarrollo en Ingenier&iacute;a ofrece multiples servicios, desde I + D hasta la producci&oacute;n, servicio postventa y mantenimiento, en las &aacute;reas: metalmec&aacute;nica, software, electr&oacute;nica y  aeron&aacute;utica. Tambi&eacute;n provee servicios de innovaci&oacute;n in-situ (open innovation) y gesti&oacute;n de cambios.
	</p>
    <p>
		La divisi&oacute;n de Tecnolog&iacute;a M&eacute;dica apoya a los sistemas de salud y a las compa&ntilde;&iacute;as de tecnolog&iacute;a m&eacute;dica, para lograr una asistencia sanitaria basada en el valor. Esto incluye integrar la atenci&oacute;n en torno a las condiciones m&eacute;dicas de los pacientes, con el apoyo de innovadores dispositivos m&eacute;dicos y de diagn&oacute;stico in vitro, nuevos esquemas de pago y acuerdos de distribuci&oacute;n de riesgo, medici&oacute;n de resultados de los pacientes y de costos, junto a la generaci&oacute;n y evaluaci&oacute;n de evidencia del valor agregado de las tecnolog&iacute;as m&eacute;dicas.
	</p>
		</div>
		{/* <Footer/> */}

</div>

)
}

export default Espanol;
