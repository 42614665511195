import React from "react"
import { Link } from "react-router-dom"
import FeaturedGallery from "../../inc/Featured.gallery.tpl";
import Footer from "../../inc/Footer.tpl";
import Header from "../../inc/Header.tpl";
import Menu from "../../inc/Menu.tpl";
import TabsRender from "../DinamicTabNav";

function DevicesMaquineryEquipmentPieces(){
    return(
        <div className="grid place-content-center w-screen">
        <Header/>
        <Menu/>
        <FeaturedGallery/>

  

<div id="content">
    <div id="breadcrumbs">
        <Link to="/english/engineering-development/products-and-services">Products and Services</Link> &raquo; 
        <Link to="/english/engineering-development/research-and-development">Research and Development</Link> &raquo; 
        <strong></strong>
    </div>
    
    <div id="leftbar">
        <h4><Link className="text-xs" to="/english/engineering-development/products-and-services">Products and Services</Link></h4>
        <ul className="flex flex-col">
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/research-and-development">Research and Development</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/production">Production</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/industrial-management">Industrial Management</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/informatics">Informatics</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/repairs">Repairs</Link></li>
        </ul>
    </div>
    <div id="wrapper">

        <TabsRender/> 
        
    </div>
</div>

</div>
)
}

export default DevicesMaquineryEquipmentPieces