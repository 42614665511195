import React from "react"
import { Link, useParams } from "react-router-dom";



function Menu ({ languaje }){

    return(

  
<>
 

 <div className=" ">

 
  <div className="menubg text-white flex  flex-start  flex-col md:flex-row items-start">
    <nav className="nav-menu font-semibold flex lg:w-2/5 flex-wrap items-start text-sm text-white">
      <Link to={`/spanish/`} className="mr-5 text-white ">Principal</Link>
      <Link  to={`/spanish/about`}  className="mr-5 text-white">Quienes somos</Link>
<Link to={`/spanish/contact`}  className="mr-5 text-white">Contáctenos</Link>
       
    </nav>
  </div>
 

















{/*<ul id="menu">  
        <li className="first"></li>
        <li className="actual" >
            <Link to="<?=REMOTE_PATH?>" class="principal">Home</Link>
         </li>
     <li className="actual">
                <Link href="<?=REMOTE_PATH_DIV?>products-and-services.html" class="productos">Products and Services</Link>
                </li>
            <li className="actual" >
                <a href="<?=REMOTE_PATH_DIV?>research-and-business-plant.html" class="investigacion">Research and Business Plant</Link>
            </li>
       
        <li >
            <a href="<?=REMOTE_PATH_DIV?>about-us.html" class="quienes">About Us</a>
        </li>
        <li >
            <a href="<?=REMOTE_PATH_DIV?>contact-us.html" class="contactenos">Contact Us</a>
        </li><li class="last"></li> {/*<!-- 
        <li style="float:right">
            <a href="/blog" class="blog">Blog</a>
    </li>-->
    
</ul>*/}

 </div>
</>

)
}

export default Menu ;

