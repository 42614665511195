import React from "react";
import Paisaje1 from "../../en/static/images/photos/paisajes/1.jpg"
import Paisaje2 from "../../en/static/images/photos/paisajes/2.jpg"
import Paisaje3 from "../../en/static/images/photos/paisajes/3.jpg"
import Paisaje4 from "../../en/static/images/photos/paisajes/4.jpg"
import Paisaje5 from "../../en/static/images/photos/paisajes/5.jpg"
import Paisaje6 from "../../en/static/images/photos/paisajes/6.jpg"
import Paisaje7 from "../../en/static/images/photos/paisajes/7.jpg"

function FeaturedGallery (){
	return(

		<div>


<div id="featured">
<div id="bigGallery" className="gallery ">	

		    <img src={Paisaje1} alt="s" />
			<img src={Paisaje2} alt="" /*{ if(PAGE == 'informatica') && class="active"*/ />
			 <img src={Paisaje3} alt=""/*<? if(PAGE == 'investigacion-y-usina-de-empresas'): ?> class="active"<? endif ?> *//>
			 <img src={Paisaje4} alt=""/*<? if(PAGE == 'produccion'): ?> class="active"<? endif ?>*/ />
			 <img src={Paisaje5} alt=""/* <? if(PAGE == 'reparaciones'): ?> class="active"<? endif ?> *//>
			 <img src={Paisaje6} alt="" /*<? if(PAGE == 'gestion-industrial'): ?> class="active"<? endif ?> */ />
			 <img src={	Paisaje7} alt="" /*< ? if(PAGE != 'informatica' && PAGE != 'investigacion-y-usina-de-empresas' && PAGE != 'produccion' && PAGE != 'reparaciones' && PAGE != 'gestion-industrial'): ?> class="active"<? endif ?> */ />
			
		   
		
        </div>



</div> 	
</div>

)

}

export default FeaturedGallery;
