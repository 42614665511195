import React from "react";
import { Link } from "react-router-dom";
import Submenu from "../es/inc/Submenu.tpl";
import FeaturedGallery from "../es/inc/Featured.gallery.tpl";
import Footer from "../es/inc/Footer.tpl";
import Head from "../es/inc/Head.tpl";
import Header from "../es/inc/Header.tpl";
import Menu from "../es/inc/Menu.tpl";
import SinopticoUsina from "../en/static/images/photos/informatica/sinoptico-usina.png"



function InvestigacionYUsinaDeEmpresas () {
    return (

        <div className="grid place-content-center w-screen">
  <Head/>
            <Header/>
             <Submenu/>
            <FeaturedGallery/>

<div id="content">
    <div id="breadcrumbs">
        
        <strong>Investigacion y usina de empresas </strong>
    </div>
    <h1>Investigación y Usina de empresas (USINA)</h1>
    <p className="text-align:center">
        <img className="mx-auto" src={SinopticoUsina} alt="Cuadro Sinoptico" />
    </p>
    <p>
    La idea es generar grupos que investiguen y desarrollen productos y servicios orientados a mejorar la calidad de vida de las personas 
    y ayudar a preservar el medioambiente.
    </p>
    <p>
    Las principales &aacute;reas de investigaci&oacute;n son las siguientes: energ&iacute;as no convencionales (e&oacute;lica, solar, hidr&oacute;geno), biocombustibles, 
    nuevos sistemas de propulsi&oacute;n y transporte, inteligencia artificial y sistemas expertos.
    </p>
    <p>
    Sustentabilidad: TECXIO<sup>&reg;</sup> ofrece a los foros de investigaci&oacute;n que se van formando, la experiencia y estructura necesarias 
    para la ejecuci&oacute;n de proyectos rentables y la posibilidad de que sus miembros constituyan junto con TECXIO<sup>&reg;</sup>, una sociedad comercial 
    que explote los conocimientos generados.  
    </p>
    <p>
    TECXIO<sup>&reg;</sup>, a su vez, realiza sus b&uacute;squedas laborales entre los miembros de los foros de investigaci&oacute;n  que se destaquen por sus 
    habilidades profesionales, humanas, dedicaci&oacute;n y creatividad.
    </p>
    <p>
    TECXIO<sup>&reg;</sup>, a trav&eacute;s de su divisi&oacute;n USINA promueve:
    </p>
    <ul>
        <li>
            El desarrollo y ejecuci&oacute;n de nuevos proyectos en el campo de la ingenier&iacute;a. <Link to="javascript:;" className="showmore" rel="1" attr="Leer m&aacute;s|Leer menos">Leer m&aacute;s</Link>
            <div className="more1 hidden">
                <br /><br />TECXIO<sup>&reg;</sup> promueve la formaci&oacute;n de foros de investigaci&oacute;n multidisciplinarios, con especialistas o id&oacute;neos en distintas &aacute;reas de la 
                ingenier&iacute;a y diversas ramas de las ciencia. <Link to="javascript:;" className="showmore" rel="4" attr="Leer m&aacute;s|Leer menos">Leer m&aacute;s</Link><br /><br />
                
                <div className="more4 hidden">
                    Se busca que los foros de investigaci&oacute;n posean las siguientes caracter&iacute;sticas:
                    <ol>
                        <li>
                            Se conformen en funci&oacute;n de un tema de inter&eacute;s, como por ejemplo: “turbinas de generaci&oacute;n e&oacute;lica de electricidad”, 
                            “pinturas fotovoltaicas”, “sistemas seguros para el almacenamiento de hidr&oacute;geno”, “sistemas expertos para control 
                            de equipos complejos”. <br /> 
                            
                            TECXIO<sup>&reg;</sup>, publicar&aacute; a trav&eacute;s de su blog: <Link to="https://www.tecxio.com/spanish/en-formacion.html">tecxioblog.com</Link>, un listado de temas de investigaci&oacute;n que podr&aacute;n ser tratados en 
                            los foros y a su vez, promover&aacute; que los usuarios elijan o propongan nuevos temas o acoten los temas sugeridos 
                            (abordando cuestiones m&aacute;s espec&iacute;ficas relacionadas).
                        </li>
                        <li>Est&eacute;n formados por un grupo reducido de miembros: en lo posible no m&aacute;s de 5 personas.</li>
                        <li>Se busca que los miembros de los foros sean profesionales o id&oacute;neos en los temas de investigaci&oacute;n elegidos.</li>
                        <li>Cada foro deber&aacute; designar un coordinador y un encargado de documentaci&oacute;n.</li>
                        <li>
                            Una vez formado el foro en torno de un tema espec&iacute;fico y habi&eacute;ndose designado un coordinador y un encargado de 
                            documentaci&oacute;n, la actividad se ordenar&aacute; en etapas. <Link to="javascript:;" className="showmore" rel="2" attr="Leer m&aacute;s|Leer menos">Leer m&aacute;s</Link>
                            <div className="more2 hidden">
                                <ol>
                                    <li>Intercambio de ideas (brain storming)</li>
                                    <li>Fijaci&oacute;n de objetivos:
                                        <ol>
                                            <li>Definir sobre qu&eacute; espec&iacute;ficamente  se va a investigar y qu&eacute; resultados concretos se espera alcanzar.</li>
                                            <li>Si se busca que el resultado de la investigaci&oacute;n genere conocimientos puros (cient&iacute;ficos) o aplicados (tecnolog&iacute;as).</li>
                                            <li>Si se espera formar una empresa en torno a los resultados de la investigaci&oacute;n o simplemente producir material acad&eacute;mico.</li>
                                        </ol>
                                    </li>
                                    <li>
                                        Desarrollo de la investigaci&oacute;n:<br />
                                        Definici&oacute;n, ejecuci&oacute;n y seguimiento, de las tareas de investigaci&oacute;n y documentaci&oacute;n de los resultados que se vayan obteniendo.</li>
                                    <li>Informe final</li>
                                    <li>
                                        Publicaci&oacute;n de los resultados (interna: para ser visualizada s&oacute;lo por los miembros del foro o por quienes &eacute;stos 
                                        autoricen o externa: para ser visualizada por el resto de los usuarios o el p&uacute;blico en general, a criterio de los miembros 
                                        del foro).
                                    </li>
                                </ol>
                            </div>
                        </li>
                        <li>
                            TECXIO<sup>&reg;</sup> designar&aacute; un miembro de la divisi&oacute;n USINA (Asesor de USINA) que supervisar&aacute; la actividad de los distintos foros, 
                            encarg&aacute;ndose de facilitar los recursos necesarios para su funcionamiento y analizar la afinidad de los temas desarrollados 
                            por los distintos foros, con vistas a fomentar el intercambio entre foros.
                        </li>
                        <li>
                            La actividad de cada foro de investigaci&oacute;n y la documentaci&oacute;n que se genere como consecuencia de esa actividad, ser&aacute; conocida 
                            por los miembros del foro y por los Asesores de USINA designados por TECXIO<sup>&reg;</sup>.
                        </li>
                        <li>
                            El intercambio de informaci&oacute;n entre foros de investigaci&oacute;n promovido por los Asesores de USINA, deber&aacute; ser aprobado por los miembros 
                            de los foros involucrados.
                        </li>
                        <li>
                            Se podr&aacute;n formar varios foros con un mismo tema de investigaci&oacute;n y los mismos podr&aacute;n actuar en forma coordinada con los otros grupos o 
                            bien en forma aislada, de acuerdo con el deseo de los miembros del foro.
                        </li>
                        <li>
                            TECXIO<sup>&reg;</sup> publicar&aacute; los temas de investigaci&oacute;n de todos los foros, pero s&oacute;lo publicar&aacute; los resultados de las investigaciones o la evoluci&oacute;n 
                            de las mismas, si los miembros de cada foro lo autorizan.
                        </li>
                    </ol>
                </div>
            </div>
        </li>
        <li>
            La asociaci&oacute;n estrat&eacute;gica de empresas, para la ejecuci&oacute;n de nuevos proyectos, en el campo de la ingenier&iacute;a. <Link to="javascript:;" className="showmore" rel="3" attr="Leer m&aacute;s|Leer menos">Leer m&aacute;s</Link>
            <div className="more3 hidden">
                TECXIO<sup>&reg;</sup> busca lograr un profundo conocimiento de sus clientes a la vez que realiza un permanente an&aacute;lisis de las nuevas oportunidades 
                de negocio que detecta en el mercado o que surgen de sus foros de investigaci&oacute;n.
                A partir de la informaci&oacute;n recopilada, TECXIO<sup>&reg;</sup> actúa como puente entre empresas, promoviendo asociaciones estrat&eacute;gicas (para ingresar en 
                nuevos mercados, realizar compras conjuntas, compartir canales de comercializaci&oacute;n), integraciones verticales u horizontales.
            </div>
        </li>
    </ul>
</div>

        </div>
    )
}

export default InvestigacionYUsinaDeEmpresas;