 
import React, {useState} from "react"
 
import { Link } from "react-router-dom";
import Diseno from "../../en/static/images/thumbs/diseno.jpg"
import Produccion from "../../en/static/images/thumbs/produccion.jpg";
import Gestion from "../../en/static/images/thumbs/gestion.jpg"
import Informatica from "../../en/static/images/thumbs/informatica.jpg"
import Reparaciones from "../../en/static/images/thumbs/reparaciones.jpg";
import Submenu from "./Submenu.tpl"; 
import ProduccionTwo from "../../../pages/en/static/images/photos/produccion/2.jpg"
import OneDiseno from "../../../pages/en/static/images/photos/diseno/2.jpg"
import GestionTwo from "../../../pages/en/static/images/photos/diseno/6.jpg"
import InformatiqueTwo from "../../../pages/en/static/images/photos/informatica/1_big.jpg"



function FeaturedEngineeringDevelopment () {

   const [ showServiceSnippet, setShowServiceSnippet ] = useState(false);
   const [ showProductionSnippet, setShowProductionSnippet ] = useState(false);
   const [ showIndustrialManagementSnippet, setShowIndustrialManagementSnippet ] = useState(false);
   const [ showInformaticsSnippet, setShowInformaticsSnippet ] = useState(false);
   const [ showRepairsSnippet, setShowRepairsSnippet ] = useState(false);



   return (




<div className="grid place-content-center container mx-auto px-4">
<Submenu/>
<div id="featured" className="big">


       <div className="diseno box">
           <div onClick={() => setShowServiceSnippet(true)}>
               <img src= {Diseno} alt="R&amp;D Service" />
               <h2>Servicio de I+D</h2>
           </div>
       </div>
       <div className="produccion box">
           <div onClick={() => setShowProductionSnippet(true)}>
               <img src={Produccion} alt="Production" />
               <h2>Producci&oacute;n</h2>
           </div>
       </div>
       <div className="gestion box">
           <div onClick={() => setShowIndustrialManagementSnippet(true)}>
               <img src={Gestion} alt="Industrial Management" />
               <h2>Gesti&oacute;n Industrial</h2>
           </div>
       </div>
       <div className="informatica box">
           <div onClick={() => setShowInformaticsSnippet(true)}>
               <img src={Informatica} alt="Informatics" />
               <h2>Inform&aacute;tica</h2>
           </div>
       </div>
       <div className="reparaciones box">
           <div onClick={() => setShowRepairsSnippet(true)}>
               <img src={Reparaciones} alt="Repairs" />
               <h2>Reparaciones</h2>
           </div>
       </div>
      
       <div style={{marginLeft: '0px'}} 
       className={`flex justify-between diseno description absolute top-[103px] bg-[#151515] min-w-[960px] max-w-[960px] h-[224px] ${!showServiceSnippet && 'hidden'} `}>
           <div className="w-7/12  h-10/12 m-2">
               <img src={Diseno} alt="" className="w-full h-full borderr" />  
           </div>
           <div className="mx-4 mt-6">
               <h4>Servicio de Dise&ntilde;o y Desarrollo</h4>
               <p>
               TECXIO<sup>&reg;</sup>, a trav&eacute;s de su divisi&oacute;n I+D, ofrece el servicio de dise&ntilde;o y desarrollo de productos de alto valor agregado. 
            TECXIO<sup>&reg;</sup> puede suministrar productos en las distintas etapas de su desarrollo, desde la concepción de una idea, hasta la elaboración y embalaje del producto terminado.
               <div className="linkContainer"><p className="inline text-blue-400" onClick={() => setShowServiceSnippet(false)}>&laquo; atras</p> | <Link className="rm" to="/spanish/engineering-development/research-and-development">Leer mas &raquo;</Link></div>
               </p>
           </div>
           <div className="w-5/12  h-36 mr-2 my-auto">
               <img src={OneDiseno} alt="" className="w-full h-full borderr" />
           </div>
       </div>

       <div style={{marginLeft: '0px'}}
        className={`flex justify-between produccion description absolute top-[103px] bg-[#151515] min-w-[960px] h-[224px]  ${!showProductionSnippet && 'hidden'} `}>
           <div className="w-5/12 bg-white h-52 mt-2 ml-2">
               <img src={Produccion} alt="" className="w-full h-full borderr" />  
           </div>
           <div className="mx-4 mt-6">
               <h4>Producci&oacute;n</h4>
               <p>
               TECXIO<sup>&reg;</sup> cuenta con recursos y capacidad productiva para fabricar piezas &uacute;nicas altamente complejas, prototipos y 
            pequeñas series, o bien gestionar la producci&oacute;n de grandes series en plantas productivas asociadas.<br /> 
               <div className="linkContainer"><p className="inline text-blue-400" onClick={() => setShowProductionSnippet(false)}>&laquo; atras</p> | <Link className="rm" to="/spanish/engineering-development/production">Leer mas &raquo;</Link></div>
               </p>
           </div>
           <div className="w-72 bg-white h-32 mr-2 my-auto">
               <img src={ProduccionTwo} alt="" className="w-full h-full borderr" />
           </div>
       </div>

       <div style={{marginLeft: '0px'}} 
       className={`flex justify-between gestion description absolute top-[103px] bg-[#151515] min-w-[960px] h-[224px]  ${!showIndustrialManagementSnippet && 'hidden'}`}>
           <div className="w-3/12  h-52 mt-2 ml-2">
               <img src={Gestion} alt="" className="w-full h-full borderr" />  
           </div>
           <div className="mx-4 mt-6">
               <h4>Gesti&oacute;n Industrial</h4>
               <p>
               TECXIO<sup>&reg;</sup>, a trav&eacute;s de su divisi&oacute;n GI, ofrece el servicio de tercerizaci&oacute;n de tareas cr&iacute;ticas a 
            nivel gerencial y de mandos medios altamente calificados.<br /> 
            El objetivo es mejorar integralmente el funcionamiento de la empresa con vistas a mantener los negocios existentes y generar nuevos negocios.<br />           
                <div className="linkContainer"><p className="inline text-blue-400" onClick={() => setShowIndustrialManagementSnippet(false)}>&laquo; Atras</p> | <Link className="rm" to="/spanish/engineering-development/industrial-management">Leer mas &raquo;</Link></div>
               </p>
           </div>
           {/* <div className="w-72 bg-white h-32 mr-2 my-auto">
               <img src={GestionTwo}  alt="" className="w-full h-full" />
           </div> */}
       </div>
       
       <div style={{marginLeft: '0px'}}
        className={`flex justify-between informatica description absolute top-[103px] bg-[#151515] min-w-[960px] h-[224px] ${!showInformaticsSnippet && 'hidden'}`}>
           <div className="w-5/12 bg-white h-52 mt-2 ml-2">
               <img src={Informatica} alt="" className="w-full h-full borderr" />  
           </div>
           <div className="mx-4 mt-6">
               <h4>Inform&aacute;tica</h4>
               <p>
               TECXIO<sup>&reg;</sup>, a trav&eacute;s de su divisi&oacute;n de IT, ofrece el dise&ntilde;o, desarrollo e implementaci&oacute;n de 
            software de control, c&aacute;lculo, gesti&oacute;n y dise&ntilde;o en el campo de la ingenier&iacute;a. <br />   
                        <div className="linkContainer"><p className="inline text-blue-400" onClick={() => setShowInformaticsSnippet(false)}>&laquo; atras</p> | <Link className="rm" to="/spanish/engineering-development/informatics">Leer mas &raquo;</Link></div>
               </p>
           </div>
           <div className="w-72 bg-white h-32 mr-2 my-auto">
               <img src={InformatiqueTwo}  alt="" className="borderr"/>
           </div>
       </div>

       <div style={{marginLeft: '0px'}}
        className={`flex justify-between reparaciones description absolute top-[103px] bg-[#151515] min-w-[960px] h-[224px] ${!showRepairsSnippet && 'hidden'}`}>
           <div className="w-7/12 bg-white h-52 mt-2 ml-2">
               <img src={Reparaciones} alt="" className="w-full h-full borderr" />  
           </div>
           <div className="mx-4 mt-6">
               <h4>Reparaciones </h4>
               <p>
               TECXIO<sup>&reg;</sup>, a trav&eacute;s de su divisi&oacute;n RAE, ofrece el servicio de reparaci&oacute;n de equipamiento mec&aacute;nico que 
            requiera incluso, el reemplazo de piezas especiales que deban ser relevadas y fabricadas a medida (como sin fines y coronas, ejes, 
            engranajes, de peque&ntilde;o o gran porte), el mecanizado de piezas de fundici&oacute;n desgastadas y adaptaci&oacute;n de sistemas 
            de rodamientos y reparaci&oacute;n de roscas especiales.<br /> 
                           <div className="linkContainer"><p className="inline text-blue-400" onClick={() => setShowRepairsSnippet(false)}>&laquo; Atras</p> | <Link className="rm" to="/spanish/engineering-development/repairs">Leer mas &raquo;</Link></div>
               </p>
           </div>
          
       </div>
       
       <div className="gallery hidden"></div>
       
</div>

</div>
   )

}

export default FeaturedEngineeringDevelopment;




