import React from "react";
import { Link } from "react-router-dom";
import Submenu from "../es/inc/Submenu.tpl";
import FeaturedGallery from "../es/inc/Featured.gallery.tpl";
import Footer from "../es/inc/Footer.tpl";
import Head from "../es/inc/Head.tpl";
import Header from "../es/inc/Header.tpl";
import Menu from "../es/inc/Menu.tpl";
import FeaturedEngineeringDevelopment from "./inc/Featured-engineering-development.tpl";


function IndexEngineeringDevelopmentEspanol () {
    return (


<div className="grid place-content-center">
    {/* <Head/> */}
    <Header/>
    <FeaturedEngineeringDevelopment/>


    <div id="content" className="container pt-3 mx-auto w-64">
        <p className=""  >
        TECXIO® fue creada por un grupo de profesionales especializados en distintas ramas de la industria (metalmecánica, software, electrónica y aeronáutica) con la visión de amalgamar conocimientos técnicos muy específicos mediante un abordaje generalista, dando lugar a un proceso sinergético que permita la creación de productos y servicios de alto valor agregado para nuestros clientes.        </p>
    </div>

</div>

    )}



   

export default IndexEngineeringDevelopmentEspanol;