 
/*<? 
$title = 'Production on Request (PRAP)';
include_once(PAGES_PATH . 'inc/head.tpl.php'); 
include_once(PAGES_PATH . 'inc/header.tpl.php'); 
include_once(PAGES_PATH . 'inc/menu.tpl.php'); 
include_once(PAGES_PATH . 'inc/submenu.tpl.php'); 
include_once(PAGES_PATH . 'inc/featured.gallery.tpl.php'); 
?>*/

import React, {useState} from "react"
import { Link } from "react-router-dom"
import Head from "./inc/Head.tpl";
import Header from "./inc/Header.tpl";
import Menu from "../en/inc/Menu.tpl";
import Submenu from "./inc/Submenu.tpl";
import FeaturedEngineeringDevelopment from "./inc/Featured-engineering-development.tpl";
import Footer from "./inc/Footer.tpl";
import Featured from "./inc/Featured.tpl";
import FeaturedGallery from "./inc/Featured.gallery.tpl";
import SinopticoProduccion from "../en/static/images/photos/informatica/sinoptico-produccion-fr.png"
import ProductionOne from "../en/static/images/photos/produccion/1.jpg"
import ProductionTwo from "../en/static/images/photos/produccion/2.jpg"
import ProductionThree from "../en/static/images/photos/produccion/3.jpg"
import ProductionFour from "../en/static/images/photos/produccion/4.jpg"
import ProductionFive from "../en/static/images/photos/produccion/5.jpg"
import Modal from "../en/inc/Modal";




function ProductionFr (){
    const [ModalImage, setModalImage] = useState(false)
    const toggleModal = (e) => {
        e.preventDefault()
        e.stopPropagation() 
        if ( ModalImage) {
             setModalImage(false);
            } else {
                setModalImage(true);
            }
    }

    const [ModalImageTwo, setModalImageTwo] = useState(false)
    const toggleModalTwo = (e) => {
        e.preventDefault()
        if ( ModalImageTwo) {
            setModalImageTwo(false);
            } else {
        setModalImageTwo(true);
    }} 
    const [ModalImageThree, setModalImageThree] = useState(false)
    const toggleModalThree = (e) => {
        e.preventDefault()
        if ( ModalImageThree) {
            setModalImageThree(false);
            } else {
        setModalImageThree(true);
    }} 

    const [ModalImageFour, setModalImageFour] = useState(false)
    const toggleModalFour = (e) => {
        e.preventDefault()
        if ( ModalImageFour) {
            setModalImageFour(false);
            } else {
        setModalImageFour(true);
    }}
    const [ModalImageFive, setModalImageFive] = useState(false)
    const toggleModalFive = (e) => {
        e.preventDefault()
        if ( ModalImageFive) {
            setModalImageFive(false);
            } else {
        setModalImageFive(true);
    }}

    return(
<div className="grid place-content-center w-screen">

<Head/>
<Header/>
 
<Submenu/>
<FeaturedGallery/>


<div id="content" className="mx-auto">
    <div id="breadcrumbs">
        <Link to={'/frances'}>TECXIO  </Link>{` > `}
        <Link to={'/frances/engineering-development'}>Engineering development   </Link>{` > `}
        <Link to={'/frances/engineering-development/products-and-services'}>Products and services</Link>{` > `}
        <strong>Production sur demande (PRAP)</strong>
    </div>
    <h1>Production sur demande (PRAP)</h1>
    <div id="leftbar">
    <h4><Link className="text-xs" to="/frances/engineering-development/products-and-services">Produits et Services</Link></h4>
    <ul className="flex flex-col">
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/research-and-development">Conception et développement</Link></li>
            <li><Link className="bg-lime-600 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/production">Production sur demande</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/industrial-management">Gestion Industrielle</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/informatics">Informatique</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/frances/engineering-development/repairs">Réparations</Link></li>
        </ul>
    </div>
    <div id="wrapper">
        <p>
        TECXIO<sup>&reg;</sup> a les ressources et les capacités pour produire des pièces uniques et très complexes, des prototypes et des petites séries de production, 
            ou aussi de gérer la production de grandes séries dans des usines productives associées.         </p>         
        <p className="text">
            <img src={SinopticoProduccion} alt="Cuadro Sinoptico" />
        </p>
        <p>
        TECXIO<sup>&reg;</sup> peut fabriquer des systèmes mécaniques, hydrauliques, pneumatiques, électroniques ou mixtes, conçus ou développés par le client 
            (selon le plan et les spécifications du client), ou bien conçus ou développés par TECXIO<sup>®</sup>, selon les besoins du client.        </p>         
        <div class="minigallery" className="margin:10px 0 10px 44px">
            <Link class="photo left" to="<?=IMG_PATH?>photos/produccion/1_big.jpg" rel="prettyPhoto[produccion]" title="Descripcion de la foto 1">
                <img  onClick={(e) => toggleModal(e) }src={ProductionOne} alt="" />
                {ModalImage && <Modal img={ProductionOne} toggleModal={toggleModal} />}

            </Link> 
            <Link class="photo left" to="<?=IMG_PATH?>photos/produccion/2_big.jpg" rel="prettyPhoto[produccion]" title="Descripcion de la foto 2">
                <img onClick={(e) => toggleModalTwo(e) } src={ProductionTwo} alt="" />
                {ModalImageTwo && <Modal img={ProductionTwo} toggleModal={toggleModalTwo} />}

            </Link>
            <Link class="photo left" to="<?=IMG_PATH?>photos/produccion/3_big.jpg" rel="prettyPhoto[produccion]" title="Descripcion de la foto 3">
                <img onClick={(e) => toggleModalThree(e) } src={ProductionThree} alt="" />
                {ModalImageThree && <Modal img={ProductionThree} toggleModal={toggleModalThree} />}

            </Link>
            <Link class="photo left" to="<?=IMG_PATH?>photos/produccion/4_big.jpg" rel="prettyPhoto[produccion]" title="Descripcion de la foto 4">
                <img onClick={(e) => toggleModalFour(e) } src={ProductionFour} alt="" />
                {ModalImageFour && <Modal img={ProductionFour} toggleModal={toggleModalFour} />}

            </Link>
            <Link class="photo left" to="<?=IMG_PATH?>photos/produccion/5_big.jpg" rel="prettyPhoto[produccion]" title="Descripcion de la foto 5">
                <img onClick={(e) => toggleModalFive(e) }  src={ProductionFive} alt="" />
                {ModalImageFive && <Modal img={ProductionFive} toggleModal={toggleModalFive} />}

            </Link>
           
           
        </div>
    </div>
</div>

</div>
)
} 
export default ProductionFr

//<? include_once(PAGES_PATH . 'inc/footer.tpl.php'); ?>





 