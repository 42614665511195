 
import React, {useState} from "react"
 
import { Link } from "react-router-dom";
import Diseno from "../../../pages/en/static/images/thumbs/diseno.jpg"
import Produccion from "../../../pages/en/static/images/thumbs/produccion.jpg";
import Gestion from "../../../pages/en/static/images/thumbs/gestion.jpg"
import Informatica from "../../../pages/en/static/images/thumbs/informatica.jpg"
import Reparaciones from "../../../pages/en/static/images/thumbs/reparaciones.jpg";
import ProduccionTwo from "../../../pages/en/static/images/photos/produccion/2.jpg"
import OneDiseno from "../../../pages/en/static/images/photos/diseno/2.jpg"
import GestionTwo from "../../../pages/en/static/images/photos/diseno/6.jpg"
import InformatiqueTwo from "../../../pages/en/static/images/photos/informatica/1_big.jpg"



 


function FeaturedEngineeringDevelopmentFr () {

   const [ showServiceSnippet, setShowServiceSnippet ] = useState(false);
   const [ showProductionSnippet, setShowProductionSnippet ] = useState(false);
   const [ showIndustrialManagementSnippet, setShowIndustrialManagementSnippet ] = useState(false);
   const [ showInformaticsSnippet, setShowInformaticsSnippet ] = useState(false);
   const [ showRepairsSnippet, setShowRepairsSnippet ] = useState(false);



   return (




<div className="grid place-content-center container mx-auto px-4">
{/*<Submenu/> */}
<div id="featured" className="big">


       <div className="diseno box">
           <div onClick={() => setShowServiceSnippet(true)}>
               <img src= {Diseno} alt="R&amp;D Service" />
               <h2>Conception et développement</h2>
           </div>
       </div>
       <div className="produccion box">
           <div onClick={() => setShowProductionSnippet(true)}>
               <img src={Produccion} alt="Production" />
               <h2>Production sur demande</h2>
           </div>
       </div>
       <div className="gestion box">
           <div onClick={() => setShowIndustrialManagementSnippet(true)}>
               <img src={Gestion} alt="Industrial Management" />
               <h2>Gestion Industrielle</h2>
           </div>
       </div>
       <div className="informatica box">
           <div onClick={() => setShowInformaticsSnippet(true)}>
               <img src={Informatica} alt="Informatics" />
               <h2>Informatique</h2>
           </div>
       </div>
       <div className="reparaciones box">
           <div onClick={() => setShowRepairsSnippet(true)}>
               <img src={Reparaciones} alt="Repairs" />
               <h2>Reparations</h2>
           </div>
       </div>
      
       <div style={{marginLeft: '0px'}} 
       className={`flex justify-between diseno description absolute  top-[110px] bg-[#151515] min-w-[960px] max-w-[960px] h-[224px] ${!showServiceSnippet && 'hidden'} `}>
           <div className="w-7/12 bg-white h-10/12 m-2 ">
               <img src={Diseno} alt="" className="borderr w-full h-full" />  
           </div>
           <div className="mx-4 mt-2">
               <h4>Conception et développement</h4>
               <p>
               TECXIO<sup>®</sup>, à travers de sa division I+D, offre le service de conception et de développement de produits de haute valeur ajoutée. Nous pouvons offrir à nos clients des produits à des divers stades de développement, à partir de la conception d'une idée à la fabrication et l´emballage de produits terminés.<br />
               <div className="linkContainer"><p className="inline text-blue-400" onClick={() => setShowServiceSnippet(false)}>&laquo; Retour</p> | <Link className="rm" to="/frances/engineering-development/research-and-development">Lire plus &raquo;</Link></div>
               </p>
           </div>
           <div className="w-5/12 bg-white h-32 mr-2 my-auto">
               <img src={OneDiseno} alt="" className="w-full h-full borderr" />
           </div>
       </div>

       <div style={{marginLeft: '0px'}}
        className={`flex justify-between produccion description absolute top-[110px] bg-[#151515] min-w-[960px] h-[224px]  ${!showProductionSnippet && 'hidden'} `}>
           <div className="w-5/12 bg-white h-52 mt-2 ml-2 borderr-44 borderr-black">
               <img src={Produccion} alt="" className="w-full h-full borderr" />  
           </div>
           <div className="mx-4 mt-2">
               <h4>Production sur demande</h4>
               <p>
               TECXIO<sup>®</sup> a les ressources et les capacités pour produire des pièces uniques et très complexes, des prototypes et des petites séries de production, ou aussi de gérer la production de grandes séries dans des usines productives associées.<br /> 
               <div className="linkContainer"><p className="inline text-blue-400" onClick={() => setShowProductionSnippet(false)}>&laquo; Retour</p> | <Link className="rm" to="/frances/engineering-development/production">Lire plus &raquo;</Link></div>
               </p>
           </div>
           <div className="w-72 bg-white h-32 mr-2 my-auto">
               <img src={ProduccionTwo} alt=""  className="w-full h-full borderr"/>
           </div>
       </div>

       <div style={{marginLeft: '0px'}} 
       className={`flex justify-between gestion description absolute top-[110px] bg-[#151515] min-w-[960px] h-[224px]  ${!showIndustrialManagementSnippet && 'hidden'}`}>
           <div className="w-5/12 bg-white h-52 mt-2 ml-2">
               <img src={Gestion} alt="" className="w-full h-full borderr" />  
           </div>
           <div className="mx-4 mt-2">
               <h4>Gestion Industrielle</h4>
               <p>
               TECXIO<sup>®</sup>, grâce à sa division de GI, offre le service d'externalisation de tâches essentielles au niveau de la gestion et du personnel de hiérarchie intermédiaire qualifié. <br />
            L'objectif est d'améliorer globalement le fonctionnement de l'entreprise cliente, pour qu´elle puisse donc conserver les affaires existantes et générer de nouvelles opportunités commerciales et de croissance.<br />               <div className="linkContainer"><p className="inline text-blue-400" onClick={() => setShowIndustrialManagementSnippet(false)}>&laquo; Retour</p> | <Link className="rm" to="/frances/engineering-development/industrial-management">Lire plus &raquo;</Link></div>
               </p>
           </div>
           {/* <div className="w-72 bg-white h-32 mr-2 my-auto">
               <img src={GestionTwo} alt="" className="w-full h-full" />
           </div> */}
       </div>
       
       <div style={{marginLeft: '0px'}}
        className={`flex justify-between informatica description absolute top-[110px] bg-[#151515] min-w-[960px] h-[224px] ${!showInformaticsSnippet && 'hidden'}`}>
           <div className="w-5/12 bg-white h-52 mt-2 ml-2">
               <img src={Informatica} alt="" className="w-full h-full borderr" />  
           </div>
           <div className="mx-4 mt-2">
               <h4>Informatique</h4>
               <p>
               TECXIO<sup>®</sup>, grâce à sa division de IT, offre un design, développement et implantation de logiciels de contrôle, de calcul, de gestion et de conception dans le domaine de l'ingénierie. <br /> 
               <div className="linkContainer"><p className="inline text-blue-400" onClick={() => setShowInformaticsSnippet(false)}>&laquo; Retour</p> | <Link className="rm" to="/frances/engineering-development/informatics">Lire plus &raquo;</Link></div>
               </p>
           </div>
           <div className="w-72 bg-white h-32 mr-2 my-auto">
               <img src={InformatiqueTwo} alt="" className="w-full h-full borderr" />
           </div>
       </div>

       <div style={{marginLeft: '0px'}}
        className={`flex justify-between reparaciones description absolute top-[110px] bg-[#151515] min-w-[960px] h-[224px] ${!showRepairsSnippet && 'hidden'}`}>
           <div className="w-8/12 bg-white h-52 mt-2 ml-2">
               <img src= {Reparaciones} alt="" className="w-full h-full borderr" />  
           </div>
           <div className="mx-4 mt-2">
               <h4>Reparations</h4>
               <p>
               TECXIO<sup>®</sup>, grâce à sa division de RAE, offre le service de réparation d'équipements mécaniques, y compris le remplacement de pièces spéciales qui doivent être étudiées et construites sur mesure (telles que des couronnes, des engrenages, de taille petite ou grande) , l'usinage de pièces de fonderie et l'adaptation des systèmes de roulements usés et de réparation des fils spéciaux.<br /> 
               <div className="linkContainer"><p className="inline text-blue-400" onClick={() => setShowRepairsSnippet(false)}>&laquo; Retour</p> | <Link className="rm" to="/frances/engineering-development/repairs">Lire plus &raquo;</Link></div>
               </p>
           </div>
            
       </div>
       
       <div className="gallery hidden"></div>
       
</div>

</div>
   )

}

export default FeaturedEngineeringDevelopmentFr;




 