import React from "react"
import { Link } from "react-router-dom";

function HeadIncludes () {
    return(

        <div>

<title> TECXIO</title>

<meta httpEquiv="Content-type" content="text/html;charset=UTF-8" />
<Link to= {{pathname:"/reset.css"}} />
<Link to= {{pathname:"/tecxio.css"}} />
<Link to= {{pathname:"/prettyPhoto.css"}} />
<Link to= {{pathname:"/jquery.tabs.css"}} />
<Link to= {{pathname:"/jquery.tabs-ie.css"}} />
<script type="text/javascript" src="<?=JS_PATH?>jquery.js"></script>
<script type="text/javascript"> var STATIC_PATH = ' '; </script>


        </div>

    )
}

export default HeadIncludes;






