import React from "react";
import { Link } from "react-router-dom";
import Submenu from "../es/inc/Submenu.tpl";
import FeaturedGallery from "../es/inc/Featured.gallery.tpl";
import Footer from "../es/inc/Footer.tpl";
import Head from "../es/inc/Head.tpl";
import Header from "../es/inc/Header.tpl";
import Menu from "../es/inc/Menu.tpl";


function EnFormacion () {
    return (

        <div  className="grid place-content-center w-screen">
        
<Head/>
            <Header/>
             <Submenu/>
            <FeaturedGallery/>
<div id="content">
    <div id="breadcrumbs">
        <strong> </strong>
    </div>
    <h1>Tecxio MD</h1>
    <p>
    Somos una consultora de salud enfocada en la generación y comunicación del valor de las tecnologías sanitarias (dispositivos medicos, diagnósticos, medicamentos incl vacunas).  Nuestra gran experiencia interaccionando con diversos actores clave, combinada con una fuerte mentalidad comercial y un profundo conocimiento científico en asuntos médicos, HTA, economia de la salud y Outcomes Research nos permite construir e implementar una estrategia de valor y acceso al mercado que abarca todo el ciclo de vida del producto
    </p>
</div>


            
        </div>
    )
}

export default EnFormacion;