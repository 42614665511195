import React, { useState} from "react";
import { Link } from "react-router-dom";
import Header from "./inc/Header.tpl";
import Menu from "../en/inc/Menu.tpl";
import Footer from "./inc/Footer.tpl";
import SinopticoGestion from "./static/images/photos/informatica/sinoptico-gestion-en.png"
import FeaturedGallery from "./inc/Featured.gallery.tpl";
import Img1 from "./static/images/photos/informatica/img1.jpg"
import Submenu from "./inc/Submenu.tpl";
import Modal from "./inc/Modal";
import Img5 from "../en/static/images/photos/gestion/1.jpg"


function IndustrialManagement (){

    const [ModalImage, setModalImage] = useState(false)
    const toggleModal = (e) => {
        e.preventDefault()
        e.stopPropagation() 
        if ( ModalImage) {
             setModalImage(false);
            } else {
                setModalImage(true);
            }}

    return(
<div className="grid place-content-center w-screen">
<Header/>
<Submenu/>
<FeaturedGallery/>


<div id="content">
    <div id="breadcrumbs">
        <Link to={'/english'}>TECXIO  </Link>{` > `}
        <Link to={'/english/engineering-development'}>Engineering development   </Link>{` > `}
        <Link to={'/english/engineering-development/products-and-services'}>Products and services</Link>{` > `}
        <strong>Industrial Management Service (GI)</strong>
    </div>
    <h1>Industrial Management Service (GI)</h1>
    <div id="leftbar">
    <h4><Link className="text-xs" to="/english/engineering-development/products-and-services">Products and Services</Link></h4>
        <ul className="flex flex-col">
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/research-and-development">Research and Development</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/production">Production</Link></li>
            <li><Link className=" bg-lime-600   border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/industrial-management">Industrial Management</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/informatics">Informatics</Link></li>
            <li><Link className="bg-yellow-50 border-solid border border-yellow-200 rounded-lg text-xs" to="/english/engineering-development/repairs">Repairs</Link></li>
        </ul>
    </div>
    <div id="wrapper">
        <p>
            The goal is to comprehensively improve the functioning of the client company, so as to sustain existing businesses and generate new business opportunities and growth.
        </p>
        <p className="st1" >
            <img src={SinopticoGestion} alt="Cuadro Sinoptico" />
        </p>
        
        

        <div to="<?=IMG_PATH?>photos/gestion/1_big.jpg" className="photoo" rel="prettyPhoto[gestion]" title="Descripcion de la foto 1">
            <img className="w-fit shadow-[0_35px_60px_-15px_rgba(0,0,0,0.8)]



" src={Img5} alt="" />

            <p className="">
            TECXIO®, through its GI division, offers the service of outsourcing critical tasks at management and middle highly qualified levels.The goal is to comprehensively improve the functioning of the company in order to maintain existing businesses and generate new business, through improvements in:
            <ul className="">
                <li>The quality of products / services offered by Customer</li>
                <li>The type of relationship with customers and suppliers (negotiation, communication)</li>
            </ul>
            </p>
        </div>
        <p>
        TECXIO® seeks thereby to reduce costs in absolute and concrete terms, by eliminating inefficiencies, identifying and seizing opportunities for improvement.        </p>

        <p>
            <strong className="bold">Service offered:</strong> <br />
            Outsourcing of specific tasks, high added value:<ul>
                <li>Tasks related to the production process: production planning, process engineering, product engineering, among others. </li>
                <li>Tasks associated with production processes: purchasing, internal / external logistics, inventory management, quality management, among others.</li>
                <li>Tasks associated with the gestation or the creation of product: research, design, development, prototyping, among others.</li>
            </ul>
        </p>

        <p>
        The aim is to complement the work of the existing roster of employees of the client company, in the execution of critical tasks (high added value) with the input of monthly hours of work of external experts, without the need to increase the payroll (avoiding permanent recruitment of highly qualified and expensive personnel)        </p>

        <p>
        The idea is to address, with a professional approach, the company's work exactly as is being done, optimizing it according to a well defined action plan agreed with the Management. That is to say, to manage from day one ("putting out fires" if necessary), gradually eliminating detected inefficiencies. It seeks to train existing staff, providing them with the attributes necessary to perform more complex tasks than those performed routinely.

</p>
        
        <p className="bold">
        With the necessary tools (custom designed by a specialist), people with middle training may perform in a fragmented or distributed way, high added value tasks previously outsourced for optimization.

</p>
       
         




    </div>
</div>
</div>
)
}

export default IndustrialManagement


//<? include_once('inc/footer.tpl.php'); ?>
