import React from "react"

function Head () {
    return(

        

       <div>
         

 <html xmlns="https://www.w3.org/1999/xhtml"/>

<head>



</head>



        </div>


    )
}

export default Head;


/*  <? include_once('head.includes.tpl.php'); ?> */



 